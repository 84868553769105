import { USER_LOGIN, USER_LOGOUT, ADD_SCHEDULE, EDIT_SCHEDULE, EVAL_SCHEDULE, COMPLETE_TASK, DEL_SCHEDULE, ADMIN_LOGIN, DOWNLOAD_CERT } from "../actions/types";

const initState = {
	token: localStorage.getItem("user-token"),
	isAuthenticated: false,
	user: null,
	type: null
};

const userReducer = (state = initState, action) => {
	switch (action.type) {
		case USER_LOGIN:
			localStorage.setItem("user-token", action.payload.token);
			return {
				...state,
				token: action.payload.token,
				type: action.payload.role,
				user: action.payload.user,
				isAuthenticated: true
			};
		case ADD_SCHEDULE:
			return {
				...state,
				user: {
					...state.user,
					trainings: [...state.user.trainings, action.payload]
				}
			};
		case EDIT_SCHEDULE:
		case EVAL_SCHEDULE:
			return {
				...state,
				user: {
					...state.user,
					trainings: [...state.user.trainings.filter(x => x._id !== action.payload._id), action.payload]
				}
			};
		case DEL_SCHEDULE:
			return {
				...state,
				user: {
					...state.user,
					trainings: state.user.trainings.filter(x => x._id !== action.payload._id)
				}
			};

		case COMPLETE_TASK:
			return {
				...state,
				user: {
					...state.user,
					...action.payload
				}
			};
		case USER_LOGOUT:
			localStorage.removeItem("user-token");
			return {
				...state,
				token: null,
				isAuthenticated: false,
				user: null,
				type: null
			};
		case DOWNLOAD_CERT:
			return {
				...state,
				user: {
					...state.user,
					whiteBeltDownloaded: action.payload.whiteBeltDownloaded,
					greenBeltDownloaded: action.payload.greenBeltDownloaded,
					blackBeltDownloaded: action.payload.blackBeltDownloaded
				}
			};
		case ADMIN_LOGIN:
			localStorage.setItem("user-token", action.payload.token);
			return {
				...state,
				token: action.payload.token,
				user: action.payload.admin,
				isAuthenticated: true,
				type: "admin"
			};
		default:
			return state;
	}
};

export default userReducer;
