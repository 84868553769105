import { useTranslation } from "react-i18next";

const ScheduleContent = ({
	type,
	bsObjective,
	bsRemark,
	bsScore,
	bsSummary,
	highlight,
	nextStep,
	setBsObjective,
	setBsSummary,
	setBsRemark,
	setBsScore,
	setHighlight,
	setNextStep,
	setTsObjective,
	setTsRemark,
	setTsScore,
	setTsSummary,
	tsObjective,
	tsRemark,
	tsScore,
	tsSummary,
	supervisorName,
	belt,
	tasks,
	topic,
	setTopic,
	modalMode,
	isComplete,
	sellers,
	seller,
	setSeller,
	mytype,
	downloadTarget,
	downloadP2,
	downloadP3,
	edacForm,
	setEdacForm,
	supervisors,
	supervisor,
	setSupervisor
}) => {
	const { t } = useTranslation();

	return (
		<div>
			{type !== "seller" && mytype === "edac" ? (
				<div className="mt-2 row">
					<div className="col">
						<label htmlFor="manager">{t("Sales Manager")}</label>
						<input
							type="text"
							className="form-control"
							id="manager"
							name="manager"
							placeholder=""
							value={edacForm.manager}
							onChange={e => setEdacForm({ ...edacForm, manager: e.target.value })}
							disabled={isComplete || modalMode !== "edit"}
						/>
					</div>

					{type === "admin" ? (
						<div className="col">
							<label htmlFor="supervisor">{t("Supervisor")}</label>
							<select className="form-select" id="supervisor" name="supervisor" value={supervisor} onChange={e => setSupervisor(e.target.value)} disabled={isComplete || modalMode !== "edit"}>
								<option disabled value="">
									{t("Select Supervisor")}
								</option>

								{supervisors.map(x => (
									<option key={x._id} value={x.supervisorId}>
										{x.name}
									</option>
								))}
							</select>
						</div>
					) : null}
				</div>
			) : (
				<div className="row mt-2">
					{type === "seller" ? (
						<div className="col-4 form-group">
							<label htmlFor="supervisor">{t("Supervisor")}</label>
							<input type="text" value={supervisorName} className="form-control" readOnly disabled />
						</div>
					) : (
						<div className="col-4 form-group">
							<label htmlFor="seller">{t("Seller")}</label>
							<select
								id="seller"
								name="seller"
								className="form-select"
								value={seller}
								onChange={e => {
									if (!isComplete) {
										if (type === "supervisor") {
											setSeller(e.target.value);
											setTopic("");
										} else setSeller(e.target.value);
									}
								}}
								disabled={isComplete || modalMode !== "edit"}
							>
								<option value="" disabled>
									{t("Select a Seller")}
								</option>

								{sellers.map(seller => (
									<option key={seller._id} value={seller.sellerId}>
										{seller.name}
									</option>
								))}
							</select>
						</div>
					)}

					<div className="col-2 form-group belt">
						<label htmlFor="belt">{t("Belt")}</label>
						<input type="text" name="belt" id="belt" value={t(belt)} className="form-control" readOnly disabled />
					</div>

					<div className="col form-group mb-5">
						<label htmlFor="topic">{t("Topics/Skills")}</label>
						<div className="ps-3 form-control" style={{ overflow: "auto", maxHeight: "150px" }}>
							<p>{t("Select a Topic")}</p>
							<div>
								{tasks.map(task => (
									<div className="form-check" key={task._id}>
										<input
											type="checkbox"
											className="form-check-input"
											value={task._id}
											name="topic"
											disabled={isComplete || modalMode !== "edit"}
											onChange={e =>
												!isComplete ? (topic.includes(e.target.value) ? setTopic(topic.filter(x => x !== e.target.value)) : setTopic([...topic, e.target.value])) : console.log(e.target.value)
											}
											checked={topic.includes(task._id)}
										/>
										<label className="form-check-label" htmlFor="topic">
											{task.title}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			)}

			<hr />

			{mytype === "seller" ? (
				<div id="e-form" className="mt-5">
					<div ref={downloadTarget}>
						<div className="mb-5">
							<p className="fw-bold">Date:</p>
							<p className="fw-bold">Time:</p>
							<p className="fw-bold">Seller:</p>
							<p className="fw-bold">Supervisor Name:</p>
							<p className="fw-bold">Topics &amp; Skills:</p>
						</div>

						<div style={{ marginTop: "150px" }}>
							<h5 className="fw-bold">Business Summary</h5>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Objectives of the Day - (Seller to Pre-Fill)</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">Pre-align objective of the day with Supervisor based on White/Green/Black Belt Topics</p>
										<p>
											E.g. Selling P.O. amounting to RM XXXX;
											<br />
											selling a new initiative product to buyer etc
										</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="bsObjective"
										name="bsObjective"
										value={bsObjective}
										onChange={e => (!isComplete ? setBsObjective(e.target.value) : console.log(e.target.value))}
										disabled={isComplete || modalMode === "view"}
										style={isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Summary of Results - (Supervisor to Fill)</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">Vs pre-aligned objective, Supervisor to evaluate Passed/Failed. Refer 'Remark' for key points by topics</p>
										<p> E.g. Vs initial planning amount, able to sold in how much? Able to sell in new initiative</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="bsSummary"
										name="bsSummary"
										value={bsSummary}
										onChange={e => (!isComplete ? setBsSummary(e.target.value) : console.log(e.target.value))}
										disabled={type === "seller" || isComplete || modalMode === "view"}
										style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>
						</div>

						<hr />

						<div>
							<h5 className="fw-bold">Training Summary</h5>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Objectives of the Day - (Seller to Pre-Fill)</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">Pre-align objective of the day with Supervisor based on White/Green/Black Belt Topics</p>
										<p>
											E.g. Able to demonstrate sales target planning;
											<br />
											able to perform 9 Steps to the Call fluently etc
										</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="tsObjective"
										name="tsObjective"
										value={tsObjective}
										onChange={e => (!isComplete ? setTsObjective(e.target.value) : console.log(e.target.value))}
										disabled={isComplete || modalMode === "view"}
										style={isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Summary of Results - (Supervisor to Fill)</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">Vs pre-aligned objective, Supervisor to evaluate Passed/Failed. Refer 'Remark' for key points by topics</p>
										<p> E.g. Understand and able to demonstrate clear understanding of sales target planning, 9 Step to Call etc</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="tsSummary"
										name="tsSummary"
										value={tsSummary}
										onChange={e => (!isComplete ? setTsSummary(e.target.value) : console.log(e.target.value))}
										disabled={type === "seller" || isComplete || modalMode === "view"}
										style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Highlights</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">Highlight on business results achieved vs business objective</p>
										<p>Highlight on trainings results vs key learning of the day</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="highlight"
										name="highlight"
										value={highlight}
										onChange={e => (!isComplete ? setHighlight(e.target.value) : console.log(e.target.value))}
										disabled={type === "seller" || isComplete || modalMode === "view"}
										style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>

							<div className="eform-section">
								<div className="section-label">
									<div className="label-header">
										<h6>Next Steps</h6>
									</div>
									<div className="label-body">
										<p className="mb-3">What can we do to ensure sustain learning, to be discussed jointly by trainer &amp; trainees (supervisors &amp; sellers)</p>
										<p>What would seller do differently next to ensure today's learning can be applied in the future</p>
									</div>
								</div>

								<div className="section-input">
									<textarea
										id="nextStep"
										name="nextStep"
										value={nextStep}
										onChange={e => (!isComplete ? setNextStep(e.target.value) : console.log(e.target.value))}
										disabled={type === "seller" || isComplete || modalMode === "view"}
										style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
									></textarea>
								</div>
							</div>
						</div>
					</div>
					<hr />

					<div ref={downloadP2}>
						<div id="assessment-guideline">
							<div id="guideline-header">
								<h6 className="fw-bold">Assessment Guidelines</h6>
							</div>
							<div id="guideline-body">
								<dl>
									<dt className="fw-normal mb-3">* Trainee to do 100% of the call independently during assessment, without any help</dt>
									<dt className="fw-normal mb-3">* Trainer/Supervisor/Qualifier will observe the demonstration of the skill during the call</dt>
									<dt className="fw-normal">* Assessment criteria:</dt>
									<dd className="m-0 p-0">&nbsp;a) Min Business Results: 80%</dd>
									<dd className="m-0 p-0">&nbsp;b) Min Training Results: 70%</dd>
								</dl>
							</div>
						</div>

						<div className="my-4">
							<h5 className="fw-bold mb-2">Business Summary</h5>
							<ul className="list-group">
								<li className="list-group-item">
									<div className="left">
										<p>Score</p>
									</div>
									<div className="right"></div>
								</li>
								<li className="list-group-item">
									<div className="left">
										<p>%</p>
									</div>
									<div className="right">
										<input
											type="number"
											id="bsScore"
											name="bsScore"
											className="form-control"
											value={bsScore}
											onChange={e => (!isComplete ? setBsScore(e.target.value) : console.log(e.target.value))}
											disabled={type === "seller" || isComplete || modalMode === "view"}
										/>
									</div>
								</li>
								<li className="list-group-item">
									<div className="left">
										<p>Other Remarks (If Any)</p>
									</div>
									<div className="right">
										<textarea
											id="bsRemark"
											name="bsRemark"
											value={bsRemark}
											onChange={e => (!isComplete ? setBsRemark(e.target.value) : console.log(e.target.value))}
											disabled={type === "seller" || isComplete || modalMode === "view"}
											style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
										></textarea>
									</div>
								</li>
							</ul>
						</div>

						<div>
							<h5 className="fw-bold mb-2">Training Summary</h5>
							<ul className="list-group">
								<li className="list-group-item">
									<div className="left">
										<p>Score</p>
									</div>
									<div className="right"></div>
								</li>
								<li className="list-group-item">
									<div className="left">
										<p>%</p>
									</div>
									<div className="right">
										<input
											type="number"
											id="tsScore"
											name="tsScore"
											className="form-control"
											value={tsScore}
											onChange={e => (!isComplete ? setTsScore(e.target.value) : console.log(e.target.value))}
											disabled={type === "seller" || isComplete || modalMode === "view"}
										/>
									</div>
								</li>
								<li className="list-group-item">
									<div className="left">
										<p>Other Remarks (If Any)</p>
									</div>
									<div className="right">
										<textarea
											id="tsRemark"
											name="tsRemark"
											value={tsRemark}
											onChange={e => (!isComplete ? setTsRemark(e.target.value) : console.log(e.target.value))}
											disabled={type === "seller" || isComplete || modalMode === "view"}
											style={type === "seller" || isComplete || modalMode === "view" ? { backgroundColor: "#e9ecef" } : {}}
										></textarea>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			) : (
				<div id="edac-form" className="mt-5">
					<div ref={downloadTarget}>
						<div className="mb-5">
							<p className="fw-bold m-0 p-0">Date:</p>
							<p className="fw-bold m-0 p-0">Time:</p>
							<p className="fw-bold m-0 p-0">Sales Manager:</p>
							<p className="fw-bold m-0 p-0">Supervisor Name:</p>
						</div>
						<div className="mt-5">
							<div>
								<h5 className="fw-bold">Objective:</h5>
								<p>This EDAC Form act as a checklist to ensure Trainees (hereon Supervisors) are well-equipped with the topics learnt during the Achiever's Program and is able to train Sellers</p>
							</div>

							<div>
								<h5 className="fw-bold">Step-by-Step Guidance:</h5>
								<ol>
									<li>Supervisors are required to print this material template before in-field assessment</li>
									<li>Supervisors are required to complete this training under the guidance of Sales Manager and above or equivalent (i.e Sales Manager, General Manager etc)</li>
									<li>Supervisors &amp; trainers to pre-aligned objectivesprior to visit to ensure all objectives are met.</li>
									<li>Upon completing this training, Supervisor to upload into system for tracking purposes</li>
									<li>Once done, 'Passed' or 'Failed' status will be reflected on Supervisor's &amp; Admin's dashboard.</li>
									<li>Shall the assesssment is 'Failed', SPV to reschedule another in-field assessmenet and the same as above would applicable.</li>
								</ol>
							</div>

							<div>
								<h5 className="fw-bold">Passing Criteria:</h5>
								<p>Min 3 'YES' per segment A, B, C &amp; D are required to be qualified as 'Passed' for this assessment</p>
							</div>

							<div>
								<h5 className="fw-bold">Remark:</h5>
								<p>* KAMs are highly recommended to present during the session as shadow observer and to give any inputs where appropriate</p>
								<p>* Final decision on 'Pass' or 'Fail' shall still remain in the hands of Distributor Leadership Team (i.e. Sales Manager, General Manager etc)</p>
							</div>
						</div>

						<hr />

						<div>
							<div className="input-container">
								<div className="input-label">
									<h6>Business Objectives</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="bObjective"
										name="bObjective"
										value={edacForm.bObjective}
										onChange={e => setEdacForm({ ...edacForm, bObjective: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Training Objectives</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="tObjective"
										name="tObjective"
										value={edacForm.tObjective}
										onChange={e => setEdacForm({ ...edacForm, tObjective: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Strength</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="strength"
										name="strength"
										value={edacForm.strength}
										onChange={e => setEdacForm({ ...edacForm, strength: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Next Step</h6>
								</div>
								<div className="textarea-container">
									<textarea id="nStep" name="nStep" value={edacForm.nStep} onChange={e => setEdacForm({ ...edacForm, nStep: e.target.value })} disabled={isComplete || modalMode === "view"}></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Business Results</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="bResult"
										name="bResult"
										value={edacForm.bResult}
										onChange={e => setEdacForm({ ...edacForm, bResult: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Training Results</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="tResult"
										name="tResult"
										value={edacForm.tResult}
										onChange={e => setEdacForm({ ...edacForm, tResult: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>Opportunities</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="opportunity"
										name="opportunity"
										value={edacForm.opportunity}
										onChange={e => setEdacForm({ ...edacForm, opportunity: e.target.value })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>
						</div>
					</div>

					<hr />

					<div ref={downloadP2}>
						<div>
							<div className="section-header">
								<div className="header-head">
									<h6>KNOWLEDGE</h6>
								</div>
								<div className="header-description">
									<p>Assess trainee's overall Knowledge as Trainer</p>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>PREPARATION</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="kPrep"
										name="kPrep"
										value={edacForm.knowledge.preparation}
										onChange={e => setEdacForm({ ...edacForm, knowledge: { ...edacForm.knowledge, preparation: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>ALIGNMENT</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="kAlign"
										name="kAlign"
										value={edacForm.knowledge.alignment}
										onChange={e => setEdacForm({ ...edacForm, knowledge: { ...edacForm.knowledge, alignment: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>IDENTIFYING OPPORTUNITIES</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="kOppor"
										name="kOppor"
										value={edacForm.knowledge.opportunity}
										onChange={e => setEdacForm({ ...edacForm, knowledge: { ...edacForm.knowledge, opportunity: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>TRAINING CYCLE</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="kCycle"
										name="kCycle"
										value={edacForm.knowledge.cycle}
										onChange={e => setEdacForm({ ...edacForm, knowledge: { ...edacForm.knowledge, cycle: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>
						</div>

						<hr />

						<div>
							<div className="section-header">
								<div className="header-head">
									<h6>SKILL</h6>
								</div>
								<div className="header-description">
									<p>Assess trainee's overall Skill in Training</p>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>PREPARATION</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="sPrep"
										name="sPrep"
										value={edacForm.skill.preparation}
										onChange={e => setEdacForm({ ...edacForm, skill: { ...edacForm.skill, preparation: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>ALIGNMENT</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="sAlign"
										name="sAlign"
										value={edacForm.skill.alignment}
										onChange={e => setEdacForm({ ...edacForm, skill: { ...edacForm.skill, alignment: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>IDENTIFYING OPPORTUNITIES</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="sOppor"
										name="sOppor"
										value={edacForm.skill.opportunity}
										onChange={e => setEdacForm({ ...edacForm, skill: { ...edacForm.skill, opportunity: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>TRAINING CYCLE</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="sCycle"
										name="sCycle"
										value={edacForm.skill.cycle}
										onChange={e => setEdacForm({ ...edacForm, skill: { ...edacForm.skill, cycle: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>
						</div>

						<hr />

						<div>
							<div className="section-header">
								<div className="header-head">
									<h6>ATTITUDE/BEHAVIOUR</h6>
								</div>
								<div className="header-description">
									<p>Describe demonstrated Attitude and Behaviour as Trainer</p>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>PREPARATION</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="aPrep"
										name="aPrep"
										value={edacForm.attitude.preparation}
										onChange={e => setEdacForm({ ...edacForm, attitude: { ...edacForm.attitude, preparation: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>ALIGNMENT</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="aAlign"
										name="aAlign"
										value={edacForm.attitude.alignment}
										onChange={e => setEdacForm({ ...edacForm, attitude: { ...edacForm.attitude, alignment: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>IDENTIFYING OPPORTUNITIES</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="aOppor"
										name="aOppor"
										value={edacForm.attitude.opportunity}
										onChange={e => setEdacForm({ ...edacForm, attitude: { ...edacForm.attitude, opportunity: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>

							<div className="input-container">
								<div className="input-label">
									<h6>TRAINING CYCLE</h6>
								</div>
								<div className="textarea-container">
									<textarea
										id="aCycle"
										name="aCycle"
										value={edacForm.attitude.cycle}
										onChange={e => setEdacForm({ ...edacForm, attitude: { ...edacForm.attitude, cycle: e.target.value } })}
										disabled={isComplete || modalMode === "view"}
									></textarea>
								</div>
							</div>
						</div>
					</div>

					<hr />

					<div ref={downloadP3}>
						<div>
							<h5 className="fw-bold">For Sales Manager to fill in</h5>

							<table className="edac-table table-bordered">
								<thead className="edac-tableHead">
									<tr>
										<th scope="col" className="text-start ps-3 py-2">
											A) Explanation
										</th>
										<th scope="col">Y/N</th>
									</tr>
								</thead>

								<tbody className="edac-tableBody">
									<tr>
										<th scope="row">Review results vs. business objectives (gaps)</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a1"
												name="a1"
												value={edacForm.table.a1}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a1: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Discuss major training opportunities</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a2"
												name="a2"
												value={edacForm.table.a2}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a2: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Stress benefits - What's in it for me?</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a3"
												name="a3"
												value={edacForm.table.a3}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a3: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Enumerate execution step-by-step</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a4"
												name="a4"
												value={edacForm.table.a4}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a4: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Ask to ensure understanding</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a5"
												name="a5"
												value={edacForm.table.a5}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a5: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Discuss training/business objectives before demo</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="a6"
												name="a6"
												value={edacForm.table.a6}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, a6: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>
								</tbody>

								<thead className="edac-tableHead">
									<tr>
										<th scope="col" className="text-start ps-3 py-2">
											B) Demonstration
										</th>
										<th scope="col">Y/N</th>
									</tr>
								</thead>

								<tbody className="edac-tableBody">
									<tr>
										<th scope="row">Follow closely pattern of explanation</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="b1"
												name="b1"
												value={edacForm.table.b1}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, b1: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Show complete procedure</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="b2"
												name="b2"
												value={edacForm.table.b2}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, b2: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Ensure trainee's attention on right execution</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="b3"
												name="b3"
												value={edacForm.table.b3}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, b3: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Think aloud, ask, involve trainee</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="b4"
												name="b4"
												value={edacForm.table.b4}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, b4: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Review results vs. training objectives</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="b5"
												name="b5"
												value={edacForm.table.b5}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, b5: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>
								</tbody>

								<thead className="edac-tableHead">
									<tr>
										<th scope="col" className="text-start ps-3 py-2">
											C) Application/Trail
										</th>
										<th scope="col">Y/N</th>
									</tr>
								</thead>

								<tbody className="edac-tableBody">
									<tr>
										<th scope="row">Ask trainee to specify steps he needs to do</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="c1"
												name="c1"
												value={edacForm.table.c1}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, c1: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Closely monitor execution of aggreed steps</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="c2"
												name="c2"
												value={edacForm.table.c2}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, c2: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Use Training Worksheet</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="c3"
												name="c3"
												value={edacForm.table.c3}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, c3: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Call trainee's attention when needed</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="c4"
												name="c4"
												value={edacForm.table.c4}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, c4: e.target.value } })
													// : console.log(e.target.value)
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>
								</tbody>

								<thead className="edac-tableHead">
									<tr>
										<th scope="col" className="text-start ps-3 py-2">
											D) Critique
										</th>
										<th scope="col">Y/N</th>
									</tr>
								</thead>

								<tbody className="edac-tableBody">
									<tr>
										<th scope="row">Do call review - ORLN</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="d1"
												name="d1"
												value={edacForm.table.d1}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, d1: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Commend trainee on right execution</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="d2"
												name="d2"
												value={edacForm.table.d2}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, d2: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Agree on standards for subsequent calls</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="d3"
												name="d3"
												value={edacForm.table.d3}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, d3: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>

									<tr>
										<th scope="row">Challenge, uplift, motivate &amp; encourage</th>
										<td>
											<input
												type="text"
												className="table-input"
												id="d4"
												name="d4"
												value={edacForm.table.d4}
												onChange={
													e =>
														// e.target.value === "y" || e.target.value === "Y" || e.target.value === "n" || e.target.value === "N"
														setEdacForm({ ...edacForm, table: { ...edacForm.table, d4: e.target.value } })
													// : console.log("")
												}
												disabled={isComplete || modalMode === "view"}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ScheduleContent;
