import { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../../../components/sidebar/Sidebar";
import peopleIcon from "../../../assets/icons/peopleIcon.png";
import { useTranslation } from "react-i18next";

const Sellers = ({ history, user }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	return (
		<div id="dashboard-container" className="sellers">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow} />
			</div>

			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>

				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-fill"></i>
						<span> {t("Seller")}</span>
					</h2>
				</div>

				<div id="content">
					<div id="main-content">
						<table className="table table-responsive table-borderless">
							<thead>
								<tr>
									<th scope="col">{t("Seller Name")}</th>
									<th scope="col">{t("Seller ID")}</th>
									<th scope="col">{t("Seller Status")}</th>
									<th scope="col">{t("Complete All Modules By")}</th>
									<th scope="col">{t("Completed Topics")}</th>
									<th scope="col">{t("Next Topic / Topic to Restudy")}</th>
									<th scope="col">{t("Complete Percentage Within Belt (%)")}</th>
									<th scope="col">{t("Detailed Log")}</th>
								</tr>
							</thead>

							<tbody>
								{user.allsellers.map(seller => (
									<tr key={seller._id}>
										<th scope="row">{seller.name}</th>
										<th>{seller.sellerId}</th>
										<th>{seller.status}</th>
										<th>{new Date(new Date(seller.createdAt).setMonth(new Date(seller.createdAt).getMonth() + 8)).toLocaleDateString("en-GB")}</th>
										<th>{seller.completedTopic}</th>
										<th>{seller.nextTopic}</th>
										<th>{seller.beltProgress}%</th>
										<td onClick={() => history.push(`/supervisor/sellers/log/${seller.sellerId}`)}>
											<img src={peopleIcon} alt="log" className="img-fluid" />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Sellers);
