import { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { loginUser } from "../../redux/actions/userActions";

import idIcon from "../../assets/icons/idIcon.png";
import passIcon from "../../assets/icons/passIcon.png";
import registerIcon from "../../assets/icons/registerIcon.png";

import SelectLang from "../../components/misc/SelectLang";
import ShowLoader from "../../components/loader/ShowLoader";
import ErrorAlert from "../../components/error/Error";

const Login = ({ user, login }) => {
	const [id, setId] = useState("");
	const [password, setPassword] = useState("");
	const { t } = useTranslation();

	const handleSubmit = e => {
		e.preventDefault();
		login({ id, password });
	};

	return (
		<div id="login-page" className="auth-container">
			<SelectLang />

			<div className="auth-box">
				<div>
					<div className="text-center">
						<h4 className="auth-title">{t("Login")}</h4>
					</div>
					<ErrorAlert />
					<div className="mt-4">
						<form className="auth-form" onSubmit={handleSubmit}>
							<div className="form-group">
								<div className="input-group">
									<span className="input-group-text">
										<img src={idIcon} className="img-fluid" alt="id" />
									</span>
									<input type="text" className="form-control" placeholder={t("Seller ID / Supervisor ID")} id="id" name="id" onChange={e => setId(e.target.value)} value={id} required />
								</div>
							</div>

							<div className="form-group">
								<div className="input-group">
									<span className="input-group-text">
										<img src={passIcon} className="img-fluid" alt="password" />
									</span>
									<input type="password" className="form-control" placeholder={t("Password")} id="password" name="password" onChange={e => setPassword(e.target.value)} value={password} required />
								</div>
							</div>

							<div>
								<ShowLoader component={<input type="submit" value={t("Login")} className="big-button" />} loadingColor="dark" />
							</div>
						</form>
					</div>

					<div className="mt-3"><p><Link to="/password/forgot">{t("Forgot Password? Click here to reset")}</Link></p></div>

					<div id="auth-links-container">
						<Link className="router-link" to="/register/seller">
							<div className="auth-link primary">
								<div className="link-image">
									<img src={registerIcon} className="img-fluid" alt="register" />
								</div>
								<div className="link-text">
									<p>{t("Register New Seller Account")}</p>
								</div>
							</div>
						</Link>

						<Link className="router-link" to="/register/supervisor">
							<div className="auth-link secondary">
								<div className="link-image">
									<img src={registerIcon} className="img-fluid" alt="register" />
								</div>
								<div className="link-text">
									<p>{t("Register New Supervisor Account")}</p>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		login: data => dispatch(loginUser(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
