import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, CHANGE_LANG } from "../actions/types";
import i18n from "../../i18n";

const initState = {
	isLoading: false,
	error: null,
	language: localStorage.getItem("language") || "en"
};

const pageReducer = (state = initState, action) => {
	// console.log(action);
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};
		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case CHANGE_LANG:
			localStorage.setItem("language", action.payload);
			i18n.changeLanguage(action.payload);
			return {
				...state,
				language: action.payload
			};
		default:
			return state;
	}
};

export default pageReducer;
