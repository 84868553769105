import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getQuizzes, completeTask } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../components/sidebar/Sidebar";
import SmallCalendar from "../../../components/calendar/SmallCalendar";
import TaskList from "../../../components/dashboard/TaskList";
import ShowLoader from "../../../components/loader/ShowLoader";
import Loading from "../../../components/loader/Loading";
import ErrorAlert from "../../../components/error/Error";
import QuizRenderer from "../../../components/quiz/QuizRenderer";

import mySwal from "../../../utilities/swal";
import calcScore from "../../../utilities/calcScore";

const Quiz = ({ quizzes, match, getQuizzes, sellerId, completeTask, history, type, supervisorId }) => {
	// variables
	const { quizId } = match.params;
	const { t } = useTranslation();
	// states
	const [selectedQuiz, selectQuiz] = useState(null);
	const [availableLanguage, setAvailableLanguage] = useState([]);
	const [language, setLanguage] = useState("");
	const [isDisabled, setDisable] = useState(true);
	// quiz states
	const [start, setStart] = useState(false);
	const [questions, setQuestions] = useState(null);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [show, setShow] = useState(false);

	const [duration, setDuration] = useState(0);

	// functions
	useEffect(() => {
		let timer = null;

		if (!start) {
			const thisQuiz = quizzes.filter(quiz => quiz._id === quizId)[0];
			const quizLanguages = thisQuiz.materials.map(x => x.language);
			selectQuiz(thisQuiz);
			setAvailableLanguage(quizLanguages);
			if (quizLanguages.length === 1) setLanguage(quizLanguages[0]);
		} else {
			timer = setInterval(() => setDuration(duration + 1), 1000);
		}

		return () => {
			console.log({ duration });
			clearInterval(timer);
			timer = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start, duration]);

	const startQuiz = e => {
		if (!language) {
			mySwal.fire(t("Error"), t("Please select language before proceed."), "error");
		} else {
			const sendThis = {
				topicId: quizId,
				language
			};
			if (!start && !questions) {
				getQuizzes(sendThis, data => {
					setQuestions(data);
					setStart(true);
					if (data.length === 1 && data[0].questionType === "recap") setDisable(false);
					else {
						console.log(data);
						let allTypes = data.map(question => question.questionType).filter((x, y, z) => z.indexOf(x) === y);
						if (allTypes.length === 1 && allTypes[0] === "recap") setDisable(false);
					}
				});
			}
		}
	};

	const checkAnswers = () => {
		console.log("Check answers");
		const requiredQuestions = questions.filter(x => x.questionType !== "recap");

		let newDisable = false;
		for (let i = 0; i < requiredQuestions.length; i++) {
			if (requiredQuestions[i].questionType === "multipleChoice") {
				if (!requiredQuestions[i].selectedAnswer) newDisable = true;
			} else if (requiredQuestions[i].questionType === "sequence") {
				if (!requiredQuestions[i].selectedAnswer) newDisable = true;
				else {
					const requiredLength = requiredQuestions[i].sequence.split(",").length;
					const currentLength = requiredQuestions[i].selectedAnswer.filter(x => x).length;
					if (currentLength !== requiredLength) newDisable = true;
				}
			}
		}
		console.log({ disabled: newDisable });
		setDisable(newDisable);
	};

	const handleNavigate = val => {
		const nextValue = parseInt(currentQuestion) + val;
		if (nextValue >= 0 && nextValue < questions.length) setCurrentQuestion(nextValue);
	};

	const handleDone = e => {
		if (!isDisabled) {
			mySwal
				.fire({
					title: t("CONFIRM"),
					text: t("You are about the complete this quiz, proceed to evaluate test?"),
					icon: "warning",
					showCancelButton: true,
					cancelButtonColor: "#d33",
					confirmButtonText: t("Yes, Proceed"),
					cancelButtonText: t("No, I want to double check my answers")
				})
				.then(result => {
					if (result.isConfirmed && result.value) {
						calcScore(questions, (score, correctQuestions, wrongQuestions) => {
							let sendThis = {
								mytype: "Quiz",
								scores: score,
								topicId: quizId,
								duration,
								type
							};
							if (type === "seller") sendThis.sellerId = sellerId;
							else sendThis.supervisorId = supervisorId;
							// console.log(sendThis);
							completeTask(sendThis, () => mySwal.fire({
								title: score >= 100 ? t("Passed") : t("Failed"),
								html: `
									<div>
										<p>${score >= 100 ? t("Congratulations, you have passed your test!") : t("Sorry, you have failed your test.")}</p>
										<p><strong>Score: ${score}/100</strong></p>
										<hr />
										<div class="d-flex justify-content-between">
											<div>
												<p>Correct Questions</p>
												<ul class="list-group">
													${correctQuestions.map(x => `<li class="list-group-item" key=${x}>${x}</li>`).join("")}
												</ul>
											</div>


											<div>
												<p>Wrong Questions</p>
												<ul class="list-group">
													${wrongQuestions.map(x => `<li class="list-group-item" key=${x}>${x}</li>`).join("")}
												</ul>
											</div>
										</div>
									</div>
								`,
								icon: score >= 100 ? "success" : "error"
							})
							.then(() => history.push(`/${type}/training`)));
						});
					}
				});
		}
	};

	// QUIZ HANDLING
	const handleMCQ = (value, type) => {
		if (type === "single") {
			// single answer
			questions[currentQuestion].selectedAnswer = [value];
		} else {
			// multiple answer
			if (questions[currentQuestion].selectedAnswer && questions[currentQuestion].selectedAnswer.includes(value)) {
				questions[currentQuestion].selectedAnswer = questions[currentQuestion].selectedAnswer.filter(val => val !== value);
			} else {
				if (questions[currentQuestion].selectedAnswer) {
					questions[currentQuestion].selectedAnswer = [...questions[currentQuestion].selectedAnswer, value];
				} else {
					questions[currentQuestion].selectedAnswer = [value];
				}
			}
		}

		// --------------------------------------------------------------------------- //
		checkAnswers();
	};

	const handleSequence = e => {
		const step = parseInt(e.target.name);
		const value = e.target.value;
		if (questions[currentQuestion].selectedAnswer) {
			questions[currentQuestion].selectedAnswer[step] = value;
		} else {
			let initArr = [];
			initArr[step] = value;
			questions[currentQuestion].selectedAnswer = initArr;
		}

		// --------------------------------------------------------------------------- //
		checkAnswers();
	};
	// QUIZ HANDLING

	return (
		<div id="dashboard-container" className={start ? "sellers" : ""}>
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow} />
			</div>
			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>
				{!selectedQuiz ? (
					<Loading color="primary" />
				) : (
					<>
						{!start ? (
							<div id="content-title">
								<h2 className="m-0 p-0">
									<i className="bi bi-bullseye"></i>
									<span> {t("Training Topic")}</span>
								</h2>
							</div>
						) : null}

						<ErrorAlert />
						<div id="content" className="seller-theory">
							{!start ? <h3 className="fw-bold ms-5 mt-3">{selectedQuiz.title}</h3> : null}

							<div id="theory-container" className={`quiz-container ${start ? "quiz-start" : ""}`}>
								{!start ? (
									<div id="quiz-landing">
										<h1>{selectedQuiz.title}</h1>

										<h3>
											{t("Category")}: {t(selectedQuiz.beltType + " Belt")}
										</h3>

										<div id="quiz-language">
											<p>{t("Select Your Language")}:</p>
											<div>
												{availableLanguage.length
													? availableLanguage.map(x => (
															<button key={x} onClick={() => setLanguage(x)} className={`btn ${language === x ? "selected" : ""}`}>
																{t(x)}
															</button>
													  ))
													: null}
											</div>
										</div>

										<p>{t("100% passing marks for quizzes are required before proceeding to the next topic.")}</p>
										<p>{t("No restrictions on attempts to try.")}</p>

										<ShowLoader
											component={
												<button id="start-btn" className="btn" onClick={startQuiz}>
													{t("START NOW")} <i className="bi bi-caret-right-fill"></i>
												</button>
											}
											color="success"
										/>
									</div>
								) : (
									<div>
										<QuizRenderer quiz={questions[currentQuestion]} handleMCQ={handleMCQ} handleSequence={handleSequence} />
									</div>
								)}
							</div>
							{!start ? null : (
								<div className="d-flex justify-content-between mt-3">
									<div>
										<button
											className="btn btn-primary me-2 previous-button"
											style={currentQuestion === 0 ? { width: "120px", backgroundColor: "grey" } : { width: "120px" }}
											onClick={() => handleNavigate(-1)}
											disabled={currentQuestion === 0}
										>
											{t("Prev")}
										</button>
										<button
											className="btn btn-primary ms-2 next-button"
											style={currentQuestion === questions.length - 1 ? { width: "120px", backgroundColor: "grey" } : { width: "120px" }}
											onClick={() => handleNavigate(1)}
											disabled={currentQuestion === questions.length - 1}
										>
											{t("Next")}
										</button>
									</div>
									<div>
										<p className="m-0 p-0">
											{currentQuestion + 1} / {questions.length}
										</p>
									</div>
									<ShowLoader
										component={
											<div>
												<button
													disabled={isDisabled}
													className="btn btn-success done-button"
													style={isDisabled ? { width: "150px", backgroundColor: "grey" } : { width: "150px" }}
													onClick={handleDone}
												>
													{t("Done")}
												</button>
											</div>
										}
										loadingColor="success"
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
			{!start ? (
				<div id="rightbar-container">
					<div>
						<SmallCalendar />
					</div>
					<TaskList />
				</div>
			) : null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		quizzes: state.user.user.alltasks,
		sellerId: state.user.user.sellerId,
		type: state.user.type,
		supervisorId: state.user.user.supervisorId
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getQuizzes: (data, callback) => dispatch(getQuizzes(data, callback)),
		completeTask: (data, callback) => dispatch(completeTask(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
