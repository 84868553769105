import { useState } from "react";
import { connect } from "react-redux";
import { years, months, days, dates, current } from "../../utilities/calendar";
import { useTranslation } from "react-i18next";

const SmallCalendar = ({ trainings }) => {
	const { t } = useTranslation();
	// states
	const [currentYear, setCurrentYear] = useState(current.year);
	const [currentMonth, setCurrentMonth] = useState(current.month);

	// variables
	const currentMonthIndex = months.findIndex(x => x === currentMonth);
	let previousMonth = months[currentMonthIndex - 2];
	let lastMonth = months[currentMonthIndex - 1];
	let nextMonth = months[currentMonthIndex + 1];
	let upcomingMonth = months[currentMonthIndex + 2];

	let thisMonthDates = dates.filter(x => x.month === currentMonth && x.year === currentYear);
	const thisMonthFirst = thisMonthDates[0];
	const thisMonthLast = thisMonthDates[thisMonthDates.length - 1];
	const firstIndex = dates.findIndex(x => x.year === thisMonthFirst.year && x.month === thisMonthFirst.month && x.date === thisMonthFirst.date && x.day === thisMonthFirst.day);
	const lastIndex = dates.findIndex(x => x.year === thisMonthLast.year && x.month === thisMonthLast.month && x.date === thisMonthLast.date && x.day === thisMonthLast.day);

	// functions
	let unshiftCount = 1;
	while (thisMonthDates[0].day !== days[0]) {
		let unshiftDate = dates[firstIndex - unshiftCount];
		thisMonthDates.unshift(unshiftDate);
		unshiftCount++;
	}

	let pushCount = 1;
	while (thisMonthDates[thisMonthDates.length - 1].day !== days[days.length - 1]) {
		let pushDate = dates[lastIndex + pushCount];
		thisMonthDates.push(pushDate);
		pushCount++;
	}

	if (!lastMonth) lastMonth = months[months.length - 1];
	const lastMonthIndex = months.findIndex(x => x === lastMonth);
	if (!previousMonth) {
		if (!lastMonthIndex) {
			previousMonth = months[months.length - 1];
		} else {
			previousMonth = months[lastMonthIndex - 1];
		}
	}
	if (!nextMonth) nextMonth = months[0];
	const nextMonthIndex = months.findIndex(x => x === nextMonth);
	if (!upcomingMonth) {
		if (nextMonthIndex === months.length - 1) {
			upcomingMonth = months[0];
		} else {
			upcomingMonth = months[nextMonthIndex + 1];
		}
	}

	const handleMonth = val => {
		const setTo = months[currentMonthIndex + val];
		if (!setTo) {
			if (val > 0) {
				// increment
				if (currentYear !== years[years.length - 2]) {
					setCurrentYear(currentYear + val);
					setCurrentMonth(nextMonth);
				}
			} else {
				// decrement
				if (currentYear !== years[1]) {
					setCurrentYear(currentYear + val);
					setCurrentMonth(lastMonth);
				}
			}
		} else {
			setCurrentMonth(months[currentMonthIndex + val]);
		}
	};

	return (
		<div id="small-calendar-container">
			<h5 className="fw-bold m-0 p-0">{t("Calendar")}</h5>
			<div id="small-calendar">
				<div id="years-container">
					<p className="fw-bold">{currentYear}</p>
				</div>

				<div id="months-container">
					<button onClick={() => handleMonth(-1)}>&lt;</button>
					<p>{previousMonth.length > 4 ? t(previousMonth.slice(0, 3)) : t(previousMonth)}</p>
					<p>{lastMonth.length > 4 ? t(lastMonth.slice(0, 3)) : t(lastMonth)}</p>
					<p className="current-month">{currentMonth.length > 4 ? t(currentMonth.slice(0, 3)) : t(currentMonth)}</p>
					<p>{nextMonth.length > 4 ? t(nextMonth.slice(0, 3)) : t(nextMonth)}</p>
					<p>{upcomingMonth.length > 4 ? t(upcomingMonth.slice(0, 3)) : t(upcomingMonth)}</p>
					<button onClick={() => handleMonth(1)}>&gt;</button>
				</div>

				<div id="days-container">
					{days.map(day => (
						<p key={day}>{t(day.slice(0, 3))}</p>
					))}
				</div>

				<div id="dates-container">
					{thisMonthDates.map((x, ind) => {
						return (
							<div
								className={`date ${
									trainings.filter(
										t =>
											new Date(t.trainingDate).toLocaleDateString() ===
											new Date(
												x.year,
												months.findIndex(m => m === x.month),
												x.date
											).toLocaleDateString()
									).length
										? "highlight"
										: ""
								}`}
								key={ind}
							>
								<p className={x.month !== currentMonth ? "different-month" : x.day === "Sunday" ? "sunday" : ""}>{x.date}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		trainings: state.user.user.trainings
	};
};

export default connect(mapStateToProps, null)(SmallCalendar);
