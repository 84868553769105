export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";

export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const CHANGE_LANG = "CHANGE_LANG";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const COMPLETE_TASK = "COMPLETE_TASK";
export const DOWNLOAD_CERT = "DOWNLOAD_CERT";

export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const DEL_SCHEDULE = "DEL_SCHEDULE";
export const EVAL_SCHEDULE = "EVAL_SCHEDULE";
// admin
export const ADMIN_LOGIN = "ADMIN_LOGIN";
