// should have separated seller / supervisor, its okay tho :)
export const endpoints = {
	loginUrl: "/api/auth/login",
	checkSessionUrl: "/api/auth/session",
	sellerRegisterUrl: "/api/seller/register",
	sellerFetchTheoryUrl: "/api/seller/trainingMaterial",
	sellerFetchQuizUrl: "/api/seller/quizMaterial",
	sellerCompleteTaskUrl: "/api/seller/taskCompleted",
	supervisorDoneTaskUrl: "/api/supervisor/taskCompleted",
	supervisorRegisterUrl: "/api/supervisor/register",
	addScheduleUrl: "/api/training/addSession",
	editScheduleUrl: "/api/training/editSession",
	delScheduleUrl: "/api/training/deleteSession",
	evalScheduleUrl: "/api/training/evaluate",
	downloadUrl: "/api/training/download",
	fetchSellerDetailUrl: "/api/supervisor/sellerDetail",
	forgotPasswordUrl: "/api/auth/password/forgot",
	resetPasswordUrl: "/api/auth/password/reset"
};

export const adminEndpoints = {
	loginUrl: "/api/admin/login",
	addTopicUrl: "/api/admin/addTopic",
	topicDashboardUrl: "/api/admin/topicDashboard",
	fetchEditUrl: "/api/admin/fetchTopic",
	editTopicUrl: "/api/admin/editTopic",
	deleteTopicUrl: "/api/admin/deleteTopic",
	accountDashboardUrl: "/api/admin/resetDashboard"
};
