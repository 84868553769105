import AdminSidebar from "../../components/sidebar/AdminSidebar";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loading from "../../components/loader/Loading";
import { CSVLink } from "react-csv";

const SupervisorView = ({ dist, history }) => {
	const [data, setData] = useState([]);
	const [loading, updateLoading] = useState(true);

	useEffect(() => {
		const URL = "/api/admin/supervisorDashboard";
		axios
			.post(URL, { dist })
			.then(response => {
				console.log(response.data);
				setData(response.data);
				// updateSupervisor(response.data.data);
				updateLoading(false);
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Supervisor Name</th>
								<th scope="col">Supervisor ID</th>
								<th scope="col">Distributor</th>
								<th scope="col">Number of On-The-Job-Training Schedule</th>
								<th scope="col">White Belt Sellers</th>
								<th scope="col">Green Belt Sellers</th>
								<th scope="col">Black Belt Sellers</th>
								<th scope="col">Total Number of Sellers</th>
								<th scope="col">Team Overall Completion Percentage(%)</th>
								<th scope="col">Seller Log</th>
								<th scope="col">Supervisor Training Log</th>
							</tr>
						</thead>

						<tbody>
							{data.map(spv => (
								<tr key={spv._id}>
									<th scope="row">{spv.name}</th>
									<td>{spv.supervisorId}</td>
									<td>{spv.distributorId}</td>
									<td>{spv.noTrainings}</td>
									<td>{spv.whiteBelt}</td>
									<td>{spv.greenBelt}</td>
									<td>{spv.blackBelt}</td>
									<td>{spv.totalSeller}</td>
									<td>{spv.completeRate}</td>
									<td>
										<button className="btn btn-primary" onClick={() => history.push(`/admin/supervisor/seller/${spv._id}`)}>
											View
										</button>
									</td>
									<td>
										<button className="btn btn-primary" onClick={() => history.push(`/admin/supervisor/log/${spv._id}`)}>
											View
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
	}

	const today = new Date();
	const csvDate = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;

	let csvData = {
		headers: [
			{
				key: "name",
				label: "Supervisor Name"
			},
			{
				key: "supervisorId",
				label: "Supervisor ID"
			},
			{
				key: "distributorId",
				label: "Distributor"
			},
			{
				key: "noTrainings",
				label: "Number of On-The-Job-Training Schedule"
			},
			{
				key: "whiteBelt",
				label: "White Belt Sellers"
			},
			{
				key: "greenBelt",
				label: "Green Belt Sellers"
			},
			{
				key: "blackBelt",
				label: "Black Belt Sellers"
			},
			{
				key: "totalSeller",
				label: "Total Number of Sellers"
			},
			{
				key: "completeRate",
				label: "Team Overall Completion Percentage(%)"
			}
		],
		data
	};

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>

			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Supervisor</span>
					</h2>
				</div>
				<div className="mt-3">{renderSpinner()}</div>
				<div className="admin-button-box">
					<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`supervisor_${csvDate}.csv`} target="_blank">
						Download Report
					</CSVLink>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(SupervisorView);
