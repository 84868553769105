import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import { fetchAccounts } from "../../redux/actions/adminActions";
import mySwal from "../../utilities/swal";

const ResetAccount = ({ dist }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchAccounts({ dist }, (err, users) => {
			if (err) mySwal.fire("Error", err, "error");
			else {
				setData(users);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeName = user => {
		mySwal
			.fire({
				text: `Change ${user.name}'s name?`,
				input: "text",
				inputAttributes: {
					autocapitalize: "off"
				},
				showCancelButton: true,
				cancelButtonText: "Cancel",
				confirmButtonText: "Change",
				showLoaderOnConfirm: true,
				preConfirm: val => {
					const id = user._id;
					const sendThis = {
						id,
						name: val
					};

					return axios
						.post("/api/admin/change/name", sendThis)
						.then(res => {
							const newData = data.map(user => {
								if (user._id !== res.data._id) return user;
								else
									return {
										...user,
										name: res.data.name
									};
							});

							setData(newData);
						})
						.catch(err => mySwal.showValidationMessage(err.response.data.error));
				},
				allowOutsideClick: () => !mySwal.isLoading(),
				backdrop: true
			})
			.then(result => {
				console.log(result);
			});
	};

	const resetProgress = id => {
		mySwal
			.fire({
				title: "Reset Progress?",
				text: "Confirm to reset this user's progress?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancel",
				confirmButtonText: "Confirm",
				showLoaderOnConfirm: true,
				preConfirm: () => {
					return axios
						.post("/api/admin/reset/account", { id })
						.then(res => mySwal.fire("Success", "User's progress has been reset", "success"))
						.catch(err => mySwal.showValidationMessage(err.response.data.error));
				},
				allowOutsideClick: () => !mySwal.isLoading()
			})
			.then(clicked => {
				console.log(clicked);
			});
	};

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>

			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-arrow-clockwise"></i>
						<span> Reset Account</span>
					</h2>
				</div>

				<div className="mt-5">
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Name</th>
								<th scope="col">Number</th>
								<th scope="col">Email</th>
								<th scope="col">Seller ID</th>
								<th scope="col">Supervisor ID</th>
								<th scope="col">Distributor</th>
								<th scope="col">Change Name</th>
								<th scope="col">Reset Progress</th>
							</tr>
						</thead>

						<tbody>
							{data &&
								data.length &&
								data.map(user => (
									<tr key={user._id}>
										<th scope="row">{user.name}</th>
										<td>{user.number}</td>
										<td>{user.email}</td>
										<td>{user.sellerId}</td>
										<td>{user.supervisorId}</td>
										<td>{user.distributorId}</td>
										<td>
											<button className="btn btn-warning" onClick={() => changeName(user)}>
												Change Name
											</button>
										</td>
										<td>
											<button className="btn btn-danger" onClick={() => resetProgress(user._id)}>
												Reset Progress
											</button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps)(ResetAccount);
