import { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Sidebar from "../../../components/sidebar/Sidebar";
import SmallCalendar from "../../../components/calendar/SmallCalendar";
import TaskList from "../../../components/dashboard/TaskList";
import SellerInfo from "../../../components/dashboard/SellerInfo";
import Topic from "../../../components/dashboard/Topic";
import ErrorAlert from "../../../components/error/Error";
import checkIcon from "../../../assets/icons/checkIcon.png";
import crossIcon from "../../../assets/icons/crossIcon.png";
import pendingIcon from "../../../assets/icons/pendingIcon.png";
import { useTranslation } from "react-i18next";

const Training = ({ topics, beltStatus, type, registeredDate, user }) => {
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState("tnq");
	const { t } = useTranslation();

	let belt = "White";
	if (
		user.whiteBeltDownloaded &&
		user.greenBeltDownloaded &&
		user.blackBeltDownloaded &&
		parseInt(beltStatus.white) === 100 &&
		parseInt(beltStatus.green) === 100 &&
		parseInt(beltStatus.black) === 100
	)
		belt = "Black";
	else if (user.whiteBeltDownloaded && user.greenBeltDownloaded && parseInt(beltStatus.white) === 100 && parseInt(beltStatus.green) === 100) belt = "Black";
	else if (user.whiteBeltDownloaded && parseInt(beltStatus.white) === 100) belt = "Green";
	// if (parseFloat(beltStatus.white) === 100 && parseFloat(beltStatus.green) === 100 && user.whiteBeltComplete && user.greenBeltComplete) belt = "Black";
	// else if (parseFloat(beltStatus.white) === 100) belt = "Green";
	// else belt = "White";

	let currentTopic = 0;
	const sortedTopic = topics.filter(topic => topic.beltType === belt).sort((a, b) => a.topicNo - b.topicNo);
	for (let i = 0; i < sortedTopic.length; i++) {
		currentTopic++;
		if (sortedTopic[i].completed === "notYet" || !sortedTopic[i].completed) break;
	}

	const renderExpectedDate = belt => {
		const value = belt === "White" ? 3 : belt === "Green" ? 6 : 8;
		return moment(new Date(new Date(registeredDate).setMonth(new Date(registeredDate).getMonth() + value))).format("MMMM DD, YYYY");
	};

	const tasks = [
		...topics.filter(x => x.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
		...topics.filter(x => x.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo),
		...topics.filter(x => x.beltType === "Black").sort((a, b) => a.topicNo - b.topicNo)
	];

	return (
		<div id="dashboard-container">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow} />
			</div>

			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>

				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-bullseye"></i>
						<span> {t("Training Topic")}</span>
					</h2>
				</div>

				<div id="content">
					<SellerInfo />

					<div id="topic-content">
						<ErrorAlert />
						{type === "seller" ? (
							<h5 className="fw-bold">
								{t("Training Topic")} - {t(belt + " Belt")}
							</h5>
						) : (
							<div>
								<button style={{ width: "220px" }} onClick={() => setSelected("tnq")} className={`btn btn-${selected === "tnq" ? "success" : "secondary"}`} id="TheoryBtn">
									{t("Theory")} &amp; {t("Quiz")}
								</button>
								<button style={{ width: "220px" }} onClick={() => setSelected("progress")} className={`btn btn-${selected === "progress" ? "success" : "secondary"}`} id="ProgressBtn">
									{t("Progress Summary")}
								</button>
							</div>
						)}

						{selected === "tnq" ? (
							<div id="topics-container">
								{topics
									.filter(topic => topic.beltType === belt)
									.sort((a, b) => a.topicNo - b.topicNo)
									.map(topic => (
										<Topic
											key={topic._id}
											topic={topic}
											theory={true}
											status={topic.topicNo > currentTopic ? "disabled" : topic.completed === "notYet" || !topic.completed ? "pending" : "completed"}
											type={type}
											completion={user.completions.filter(x => x.topicId === topic._id.toString())}
											gotQuiz={topic.quiz}
										/>
									))}
							</div>
						) : (
							<div>
								<div id="content-legend" className="row m-0 px-0 py-3">
									<div className="col">
										<p className="m-0 p-0">{t("Training Status")}</p>
									</div>
									<div className="col d-flex">
										<img src={checkIcon} alt="check" className="img-fluid" />
										<p>{t("Completed")}</p>
									</div>
									<div className="col d-flex">
										<img src={crossIcon} alt="cross" className="img-fluid" />
										<p>{t("Not Completed")}</p>
									</div>
									<div className="col d-flex">
										<img src={pendingIcon} alt="pending" className="img-fluid" />
										<p>{t("To Be Completed")}</p>
									</div>
								</div>

								<table className="table table-responsive table-borderless">
									<thead>
										<tr>
											<th scope="col">{t("No.")}</th>
											<th scope="col">{t("Topic Belt")}</th>
											<th scope="col">{t("Content")}</th>
											<th scope="col">{t("Expected Completion Date")}</th>
											<th scope="col">{t("Actual Completion Date")}</th>
											<th scope="col">{t("Status")}</th>
										</tr>
									</thead>

									<tbody>
										{tasks.map((topic, ind) => (
											<tr key={topic._id}>
												<th scope="row">{ind + 1}</th>
												<td>{topic.beltType} Belt</td>
												<td>{topic.title}</td>
												<td>{renderExpectedDate(topic.beltType)}</td>
												<td>{topic.completedDate}</td>
												<td>
													{topic.completed === "notYet" ? (
														<img src={pendingIcon} alt="pending" className="img-fluid" />
													) : topic.completed === false && typeof topic.completed === "boolean" ? (
														<img src={crossIcon} alt="incomplete" className="img-fluid" />
													) : topic.completed === true && typeof topic.completed === "boolean" ? (
														<img src={checkIcon} alt="completed" className="img-fluid" />
													) : (
														topic.completed
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			</div>

			<div id="rightbar-container">
				<div>
					<SmallCalendar />
				</div>
				<TaskList />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		topics: state.user.user.alltasks,
		beltStatus: state.user.user.beltStatus,
		type: state.user.type,
		registeredDate: state.user.user.createdAt,
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Training);
