import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { downloadCert } from "../../redux/actions/userActions";
import profilePicture from "../../assets/images/profilePicture.png";
import moment from "moment";
import jsPDF from "jspdf";
import logo from "../../assets/logo/logo.png";
import frame from "../../assets/images/certificateBorder.jpg";
import certificateTitle from "../../assets/images/certificateTitle.png";
import decoration from "../../assets/images/certificateDecoration.jpg";
import ktc from "../../assets/logo/ktc.png";
import lhm from "../../assets/logo/lhm.png";
import mers from "../../assets/logo/mers.png";
import { useTranslation } from "react-i18next";

const SidebarBody = ({ type, user, downloadCert }) => {
	const { t } = useTranslation();
	const generatePDF = belt => {
		if (user[`${belt}BeltComplete`]) {
			var doc = new jsPDF("landscape", "px", "a4");

			doc.addImage(frame, "jpg", 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
			doc.addImage(certificateTitle, "png", 120, 45, 390, 70);
			doc.addImage(decoration, "jpg", 220, 145, 190, 10);
			if (user.distributorId === "LHM") doc.addImage(lhm, "png", 160, 160, 70, 60);
			else if (user.distributorId === "MER-S" || user.distributorId === "MER-EC") doc.addImage(mers, "png", 160, 160, 70, 60);
			else doc.addImage(ktc, "png", 160, 160, 70, 60);
			doc.addImage(logo, "png", 400, 160, 70, 60);
			doc.setFont("Helvetica", "bold");
			doc.setFontSize(27);
			doc.text("CERTIFICATE OF COMPLETION", doc.internal.pageSize.getWidth() / 2, 87, { align: "center" });
			doc.text("CONGRATULATIONS!", doc.internal.pageSize.getWidth() / 2, 137, { align: "center" });
			doc.setFont("Times", "bold");
			doc.setFontSize(33);
			doc.text(`${user.distributorId}`, doc.internal.pageSize.getWidth() / 2, 195, { align: "center" });
			doc.setFont("Helvetica", "bold");
			doc.setFontSize(30);
			doc.text(`${user.name}`, doc.internal.pageSize.getWidth() / 2, 250, { align: "center" });
			doc.setLineWidth(3);
			doc.line(200, 270, 430, 270);
			doc.setFontSize(25);
			doc.text("FOR COMPLETING ACHIEVER'S PROGRAM", doc.internal.pageSize.getWidth() / 2, 300, { align: "center" });
			doc.text(` - ${belt.toUpperCase()} BELT`, doc.internal.pageSize.getWidth() / 2, 330, { align: "center" });
			doc.setFont("Helvetica", "normal");
			doc.text(`${moment(new Date(user[`${belt}BeltDate`])).format("DD MMMM YYYY")}`, doc.internal.pageSize.getWidth() / 2, 370, {
				align: "center"
			});

			doc.save(`${belt}BeltCertificate.pdf`);

			if (!user[`${belt}BeltDownloaded`]) downloadCert({ id: user._id, type, belt });
		}
	};

	return (
		<>
			<div id="info-container">
				<img src={profilePicture} alt="profilePicture" className="img-fluid" id="profile-picture" />
				<p className="fw-bold">{user.name}</p>

				<p>
					{type === "seller" ? t("Seller ID") : t("Supervisor ID")}:{" "}
					<span className="fw-bold">{type === "seller" ? user.sellerId : user.supervisorId}</span>
				</p>

				<p>
					{t("Distributor")}: <span className="fw-bold">{user.distributorId}</span>
				</p>

				{type === "seller" ? (
					<p>
						{t("Complete All Modules By")}:{" "}
						<span className="fw-bold">
							{moment(new Date(new Date(user.createdAt).setMonth(new Date(user.createdAt).getMonth() + 8))).format("DD/MM/YYYY")}
						</span>
					</p>
				) : null}
			</div>

			<div id="sidebar-nav-container">
				<ul className="nav flex-column">
					<li className="nav-item">
						<NavLink activeClassName="active" className="nav-link" to={`/${type}/dashboard`}>
							<i className="bi bi-house-door-fill"> </i>
							<span> {t("Home")}</span>
						</NavLink>
					</li>

					<li className="nav-item">
						<NavLink activeClassName="active" className="nav-link" to={`/${type}/calendar`}>
							<i className="bi bi-calendar-week"> </i>
							<span> {t("Calendar")}</span>
						</NavLink>
					</li>

					{type === "seller" ? (
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to={`/${type}/training`}>
								<i className="bi bi-bullseye"> </i>
								<span> {t("Training Topic")}</span>
							</NavLink>
						</li>
					) : (
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to={`/${type}/sellers`}>
								<i className="bi bi-person-fill"> </i>
								<span> {t("Sellers")}</span>
							</NavLink>
						</li>
					)}

					{type === "supervisor" ? (
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to={`/${type}/training`}>
								<i className="bi bi-bullseye"> </i>
								<span> {t("Supervisor Topic")}</span>
							</NavLink>
						</li>
					) : null}

					<li className="nav-item dropdown">
						<span className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
							<i className="bi bi-download"> </i>
							<span> {t("Download Certificate")}</span>
						</span>
						<ul className="dropdown-menu">
							<li className="dropdown-item" onClick={() => generatePDF("white")}>
								{t("White Belt")}
							</li>

							<li className="dropdown-item" onClick={() => generatePDF("green")}>
								{t("Green Belt")}
							</li>

							<li className="dropdown-item" onClick={() => generatePDF("black")}>
								{t("Black Belt")}
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		type: state.user.type,
		user: state.user.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		downloadCert: data => dispatch(downloadCert(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarBody);
