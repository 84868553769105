import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { forgotPassword } from '../../redux/actions/userActions';

import ShowLoader from "../../components/loader/ShowLoader";
import ErrorAlert from "../../components/error/Error";

import mySwal from '../../utilities/swal';

const ForgotPass = ({ forgotPassword, history }) => {
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [type, setType] = useState("email");
    const [done, setDone] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        if (type === "email") {
            forgotPassword({ email }, () => setDone(true));
        } else {
            let sendNumber = number.split("").filter(x => x && x !== " ").join("");
            const numberRegex = /^\d+$/;
            if (
                numberRegex.test(sendNumber) &&
                sendNumber.length > 9 && 
                sendNumber.length < 12 &&
                sendNumber.substr(0 , 2) === "01"
            ) {
                forgotPassword({ number: sendNumber }, () => setDone(true));
            } else {
                mySwal.fire("", "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between", "info")
            }
        }
    };

    return (
        <div id="login-page" className="auth-container">
            <div className="auth-box">
                {
                    done
                    ? (
                        <div className="text-center">
                            <div className="mb-4">
                                <h4 className="auth-title">Password Reset</h4>
                            </div>

                            <p>Instructions to reset your password have been sent to:</p>
                            <p><strong>{type === "email" ? email : number}</strong></p>
                            <p>If you don't receive it, please contact support.</p>

                            <button className="big-button btn btn-success mt-5" onClick={() => history.push('/login')}>Back to Login</button>
                        </div>
                    )
                    : (
                        <div>
                            <div className="text-center">
                                <h4 className="auth-title">Forgot Password?</h4>
                                <p>We'll send you an {type === "email" ? "email with" : null} instructions to reset it.</p>
                            </div>
                            <ErrorAlert />

                            <div>
                                <h6>Where would you like to receive the instruction?</h6>
                                <select value={type} id="type" name="type" onChange={e => setType(e.target.value)} className="form-select">
                                    <option value="email">Email</option>
                                    <option value="phone">Phone Number</option>
                                </select>
                            </div>

                            <div className="mt-4">
                                <form onSubmit={handleSubmit}>
                                    {
                                        type === "email"
                                        ? (
                                            <div className="form-group">
                                                <input type="email" className="form-control" placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} required />
                                            </div>
                                        )
                                        : (
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Phone Number" value={number} onChange={e => setNumber(e.target.value)} required />
                                            </div>
                                        )
                                    }
                                    

                                    <div className="mt-5">
                                        <ShowLoader component={<input type="submit" value="Reset Password" className="big-button" />} loadingColor="dark" />
                                    </div>
                                </form>
                            </div>

                            <div className="mt-3">
                                <p>
                                    <Link to="/login">Back</Link>
                                </p>
                            </div>
                        </div>
                    )
                }



            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: (data, callback) => dispatch(forgotPassword(data, callback))
    };
};

export default connect(null, mapDispatchToProps)(ForgotPass);