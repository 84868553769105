import axios from "axios";
import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, USER_LOGIN, USER_LOGOUT, ADD_SCHEDULE, EDIT_SCHEDULE, EVAL_SCHEDULE, COMPLETE_TASK, DEL_SCHEDULE, ADMIN_LOGIN, DOWNLOAD_CERT, CHANGE_LANG } from "./types";
import { endpoints } from "../../utilities/url";
import tokenConfig from "./helperFunction";

const {
	loginUrl,
	sellerRegisterUrl,
	supervisorRegisterUrl,
	checkSessionUrl,
	addScheduleUrl,
	editScheduleUrl,
	evalScheduleUrl,
	fetchSellerDetailUrl,
	sellerFetchQuizUrl,
	sellerFetchTheoryUrl,
	sellerCompleteTaskUrl,
	supervisorDoneTaskUrl,
	delScheduleUrl,
	downloadUrl,
	forgotPasswordUrl,
	resetPasswordUrl
} = endpoints;

export const loginUser = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post(loginUrl, data)
		.then(res => {
			dispatch({ type: USER_LOGIN, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const checkSession = () => (dispatch, getState) => {
	const { token } = getState().user;
	if (token) {
		dispatch({ type: IS_LOADING });
		axios
			.post(checkSessionUrl, { token })
			.then(res => {
				if (res.data.admin) {
					dispatch({ type: ADMIN_LOGIN, payload: res.data });
					dispatch({ type: IS_LOADED });
				} else {
					dispatch({ type: USER_LOGIN, payload: res.data });
					dispatch({ type: IS_LOADED });
				}
			})
			.catch(err => {
				dispatch({ type: USER_LOGOUT });
				dispatch({ type: IS_LOADED });
			});
	}
};

export const logoutUser = () => {
	return {
		type: USER_LOGOUT
	};
};

export const changeLang = (lang) => {
	return {
		type: CHANGE_LANG,
		payload: lang
	}
}

// SELLERS //

export const sellerRegister = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post(sellerRegisterUrl, data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const getTheory = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(sellerFetchTheoryUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback(res.data);
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const getQuizzes = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(sellerFetchQuizUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback(res.data);
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const completeTask = (data, callback) => (dispatch, getState) => {
	const url = data.type === "seller" ? sellerCompleteTaskUrl : supervisorDoneTaskUrl;
	dispatch({ type: IS_LOADING });
	axios
		.post(url, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			console.log(res.data);
			dispatch({ type: COMPLETE_TASK, payload: res.data });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

// SUPERVISOR //

export const supervisorRegister = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post(supervisorRegisterUrl, data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const addSchedule = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(addScheduleUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: ADD_SCHEDULE, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const editSchedule = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(editScheduleUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: EDIT_SCHEDULE, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const deleteSchedule = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(delScheduleUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: DEL_SCHEDULE, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const evaluateSchedule = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(evalScheduleUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: EVAL_SCHEDULE, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: SHOW_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const fetchSellerInfo = (data, callback) => (dispatch, getState) => {
	dispatch({ type: IS_LOADING });
	axios
		.post(fetchSellerDetailUrl, data, tokenConfig(getState))
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback(res.data);
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const downloadCert = data => (dispatch, getState) => {
	axios
		.post(downloadUrl, data, tokenConfig(getState))
		.then(res => dispatch({ type: DOWNLOAD_CERT, payload: res.data }))
		.catch(err => console.log(err));
};

export const forgotPassword = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios.post(forgotPasswordUrl, data)
	.then(() => {
		dispatch({ type: IS_LOADED });
		dispatch({ type: CLEAR_ERROR });
		callback();
	})
	.catch(err => {
		dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
		dispatch({ type: IS_LOADED });
	});
};

export const resetPassword = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios.post(resetPasswordUrl, data)
	.then(() => {
		dispatch({ type: IS_LOADED });
		dispatch({ type: CLEAR_ERROR });
		callback();
	})
	.catch(err => {
		dispatch({ type: IS_LOADED });
		dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
	});
};