const DotProgress = ({ total, completed }) => {
	const arr = [];
	for (let i = 0; i < total; i++) {
		arr.push(i);
	}
	return (
		<div id="dot-progress">
			{arr.map(x => (
				<div key={x} className={`dot ${completed >= x + 1 ? " completed" : ""}`}></div>
			))}
			{/* <div className={`dot ${completed >= 1 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 2 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 3 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 4 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 5 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 6 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 7 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 8 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 9 ? " completed" : ""}`}></div>
            <div className={`dot ${completed >= 10 ? " completed" : ""}`}></div> */}
		</div>
	);
};

export default DotProgress;
