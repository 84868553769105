import BigCalendar from "../../components/calendar/BigCalendar";
import AdminSidebar from "../../components/sidebar/AdminSidebar";

const AdminCalendar = () => {
	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>

			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-calendar-week"></i> Calendar
					</h2>
				</div>

				<div id="big-calendar-container" className="mt-4">
					<BigCalendar />
				</div>
			</div>
		</div>
	);
};

export default AdminCalendar;
