import { connect } from "react-redux";
import Loading from "./Loading";

const ShowLoader = ({ isLoading, component, loadingColor }) => {
	if (isLoading) return <Loading color={loadingColor} />;
	else return <>{component}</>;
};

const mapStateToProps = state => {
	return {
		isLoading: state.page.isLoading
	};
};

export default connect(mapStateToProps, null)(ShowLoader);
