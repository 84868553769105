import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Topic = ({ status, theory, topic, type, completion, gotQuiz }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const isDisabled = () => {
		let disabled = false;
		if (status === "disabled" || status === "completed" || topic.completed === true || topic.quizDone) disabled = true;
		if (gotQuiz) {
			const completedTheory = completion.filter(x => x.mytype === "Theory")[0];
			if (!completedTheory) disabled = true;
		}
		return disabled;
	};
	return (
		<div className={`topic ${status === "completed" ? "completed" : status === "pending" ? "pending" : "disabled"}`}>
			<p className="numbering">{topic.topicNo}</p>
			<div className="topic-main">
				<div>
					<p className="task">{t("Task")}</p>
					<p className="task-title">{topic.title}</p>
				</div>

				<div className="task-actions">
					{theory ? (
						<button className="task-btn theory" onClick={() => history.push(`/${type}/training/topic/${topic._id}`)} disabled={status === "disabled"}>
							{t("Theory")}
						</button>
					) : null}

					{topic.quiz ? (
						<button
							disabled={isDisabled()}
							className="task-btn quiz"
							style={isDisabled() === true ? { backgroundColor: "grey" } : {}}
							onClick={() => {
								if (isDisabled() === false) {
									history.push(`/${type}/training/quiz/${topic._id}`);
								}
							}}
						>
							{t("Quiz")}
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Topic;
