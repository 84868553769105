import axios from "axios";
import { IS_LOADING, IS_LOADED, SHOW_ERROR, ADMIN_LOGIN, CLEAR_ERROR } from "./types";
import { adminEndpoints } from "../../utilities/url";

const { addTopicUrl, topicDashboardUrl, fetchEditUrl, editTopicUrl, deleteTopicUrl, loginUrl, accountDashboardUrl } = adminEndpoints;

export const adminLogin = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post(loginUrl, data)
		.then(res => {
			dispatch({ type: ADMIN_LOGIN, payload: res.data });
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const addTopic = (data, callback) => {
	return axios
		.post(addTopicUrl, data)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error, null));
};

export const fetchTopicDashboard = callback => {
	return axios
		.get(topicDashboardUrl)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error, null));
};

export const fetchEditTopic = (data, callback) => {
	return axios
		.post(fetchEditUrl, data)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error, null));
};

export const editTopic = (data, callback) => {
	return axios
		.post(editTopicUrl, data)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error, null));
};

export const deleteTopic = (data, callback) => {
	return axios
		.post(deleteTopicUrl, data)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error, null));
};

export const fetchAccounts = (data, callback) => {
	return axios
		.post(accountDashboardUrl, data)
		.then(res => callback(null, res.data))
		.catch(err => callback(err.response.data.error));
};
