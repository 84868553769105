import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import idIcon from "../../assets/icons/idIcon.png";
import passIcon from "../../assets/icons/passIcon.png";
import phoneIcon from "../../assets/icons/phoneIcon.png";
import idCardIcon from "../../assets/icons/idCardIcon.png";
import emailIcon from "../../assets/icons/emailIcon.png";
// import dateIcon from "../../assets/icons/dateIcon.png";
import distributeIcon from "../../assets/icons/distributeIcon.png";
import { sellerRegister, supervisorRegister } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

import ShowLoader from "../loader/ShowLoader";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const RegisterForm = ({ type, sellerRegister, supervisorRegister }) => {
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [sellerId, setSellerId] = useState("");
	const [password, setPassword] = useState("");
	const [supervisorId, setSupervisorId] = useState("");
	const [email, setEmail] = useState("");
	// const [date, setDate] = useState("");
	const [distributorId, setDistribute] = useState("");

	const history = useHistory();
	const { t } = useTranslation();

	const handleChange = e => {
		switch (e.target.id) {
			case "name":
				setName(e.target.value);
				break;
			case "number":
				setNumber(e.target.value);
				break;
			case "sellerId":
				setSellerId(e.target.value);
				break;
			case "password":
				setPassword(e.target.value);
				break;
			case "supervisorId":
				setSupervisorId(e.target.value);
				break;
			case "email":
				setEmail(e.target.value);
				break;
			case "distributorId":
				setDistribute(e.target.value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		let pass = true;
		const numberRegex = /^\d+$/;
		const numRegex = /\d/gi;
		if (numberRegex.test(number) && number.length > 9 && number.length < 12 && number.substr(0, 2) === "01") {
			if (!numRegex.test(name)) {
				if (e.target.id === "sellerForm") {
					let sellerBody = {
						name,
						number,
						sellerId,
						password,
						supervisorId
					};

					Object.keys(sellerBody).forEach(key => {
						if (!sellerBody[key]) pass = false;
					});

					sellerBody.email = email
					if (pass) sellerRegister(sellerBody, () => history.push("/login"));
					else mySwal.fire("", "Please fill in all the input fields", "warning");
				} else {
					let supervisorBody = {
						name,
						number,
						supervisorId,
						password,
						distributorId
					};
					Object.keys(supervisorBody).forEach(key => {
						if (!supervisorBody[key]) pass = false;
					});

					supervisorBody.email = email;
					if (pass) supervisorRegister(supervisorBody, () => history.push("/login"));
					else mySwal.fire("", "Please fill in all the input fields", "warning");
				}
			} else mySwal.fire("", "Please enter only alphabet value in name input", "error");
		} else mySwal.fire("", "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between", "error");
	};

	if (type === "seller") {
		return (
			<form id="sellerForm" className="auth-form" onSubmit={handleSubmit}>
				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={idIcon} className="img-fluid" alt="id" />
						</span>
						<input type="text" className="form-control" placeholder={t("Full Name as Per IC")} id="name" name="name" onChange={handleChange} value={name} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={phoneIcon} className="img-fluid tall" alt="phone" />
						</span>
						<input type="text" className="form-control" placeholder={t("Phone Number")} id="number" name="number" onChange={handleChange} value={number} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={idCardIcon} className="img-fluid" alt="id" />
						</span>
						<input type="text" className="form-control" placeholder={t("Seller ID")} id="sellerId" name="sellerId" onChange={handleChange} value={sellerId} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={passIcon} className="img-fluid" alt="password" />
						</span>
						<input type="password" className="form-control" placeholder={t("Password")} id="password" name="password" onChange={handleChange} value={password} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={idCardIcon} className="img-fluid" alt="id" />
						</span>
						<input type="text" className="form-control" placeholder={t("Supervisor ID")} id="supervisorId" name="supervisorId" onChange={handleChange} value={supervisorId} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={emailIcon} className="img-fluid" alt="email" />
						</span>
						<input type="email" className="form-control" placeholder={t("Email")} id="email" name="email" onChange={handleChange} value={email} />
					</div>
				</div>

				{/* <div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={dateIcon} className="img-fluid" alt="date" />
						</span>
						<input type="text" className="form-control" placeholder="Hired Date" id="date" name="date" onFocus={e => (e.target.type = "date")} onBlur={e => (!e.target.value ? (e.target.type = "text") : console.log(e.target.value))} onChange={handleChange} value={date} required />
					</div>
				</div> */}

				<div>
					<ShowLoader component={<input type="submit" value={t("Register")} className="big-button" />} loadingColor="dark" />
				</div>
			</form>
		);
	} else if (type === "supervisor") {
		return (
			<form className="auth-form" id="supervisorForm" onSubmit={handleSubmit}>
				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={idIcon} className="img-fluid" alt="id" />
						</span>
						<input type="text" className="form-control" placeholder={t("Full Name as Per IC")} id="name" name="name" onChange={handleChange} value={name} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={phoneIcon} className="img-fluid tall" alt="phone" />
						</span>
						<input type="text" className="form-control" placeholder={t("Phone Number")} id="number" name="number" onChange={handleChange} value={number} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={idCardIcon} className="img-fluid" alt="id" />
						</span>
						<input type="text" className="form-control" placeholder={t("Supervisor ID")} id="supervisorId" name="supervisorId" onChange={handleChange} value={supervisorId} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={passIcon} className="img-fluid" alt="password" />
						</span>
						<input type="password" className="form-control" placeholder={t("Password")} id="password" name="password" onChange={handleChange} value={password} required />
					</div>
				</div>

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={emailIcon} className="img-fluid" alt="email" />
						</span>
						<input type="email" className="form-control" placeholder={t("Email")} id="email" name="email" onChange={handleChange} value={email} />
					</div>
				</div>

				{/* <div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={dateIcon} className="img-fluid" alt="date" />
						</span>
						<input type="text" className="form-control" placeholder="Hired Date" id="date" name="date" onFocus={e => (e.target.type = "date")} onBlur={e => (!e.target.value ? (e.target.type = "text") : console.log(e.target.value))} onChange={handleChange} value={date} required />
					</div>
				</div> */}

				<div className="form-group">
					<div className="input-group">
						<span className="input-group-text">
							<img src={distributeIcon} className="img-fluid" alt="distributor" />
						</span>
						<select id="distributorId" name="distributorId" className="form-select" value={distributorId} onChange={handleChange} required>
							<option value="" disabled>
								{t("Distributor")}
							</option>
							<option value="LHM">LHM</option>
							<option value="KTC">KTC</option>
							<option value="MER-S">MER-S</option>
							<option value="MER-EC">MER-EC</option>
							<option value="KTCB">KTCB</option>
						</select>
						{/* <input type="text" className="form-control" placeholder="Distributor" id="distributorId" name="distributorId" onChange={handleChange} value={distributorId} required /> */}
					</div>
				</div>

				<div>
					<ShowLoader component={<input type="submit" value={t("Register")} className="big-button" />} loadingColor="dark" />
				</div>
			</form>
		);
	} else return <></>;
};

const mapDispatchToProps = dispatch => {
	return {
		sellerRegister: (data, callback) => dispatch(sellerRegister(data, callback)),
		supervisorRegister: (data, callback) => dispatch(supervisorRegister(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(RegisterForm);
