import { useState } from "react";
import { connect } from "react-redux";
import { adminLogin } from "../../redux/actions/adminActions";
import logo from "../../assets/logo/logo.png";
import passIcon from "../../assets/icons/passIcon.png";
import idIcon from "../../assets/icons/idIcon.png";
import ShowLoader from "../../components/loader/ShowLoader";
import ErrorAlert from "../../components/error/Error";

const AdminLogin = ({ login }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = e => {
		e.preventDefault();
		login({ username, password });
		// console.log({ id, password });
	};

	return (
		<div className="dist-login">
			<div className="dist-auth-box">
				<form onSubmit={handleSubmit} className="auth-form">
					<img src={logo} alt="logo" className="img-fluid logo" />
					<h1 className="fw-bold">
						Achievers Program
						<br />
						Admin Portal
					</h1>
					<h3>Login</h3>
					<p>Sign in to your account</p>
					<ErrorAlert />
					<div className="form-group">
						<div className="input-group">
							<span className="input-group-text">
								<img src={idIcon} className="img-fluid" alt="id" />
							</span>
							<input type="text" className="form-control" placeholder="Username" id="username" name="username" onChange={e => setUsername(e.target.value)} value={username} required />
						</div>
					</div>

					<div className="form-group">
						<div className="input-group">
							<span className="input-group-text">
								<img src={passIcon} className="img-fluid" alt="password" />
							</span>
							<input type="password" className="form-control" placeholder="Password" id="password" name="password" onChange={e => setPassword(e.target.value)} value={password} required />
						</div>
					</div>

					<div>
						<ShowLoader component={<input type="submit" value="Login" className="big-button" />} loadingColor="dark" />
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		login: data => dispatch(adminLogin(data))
	};
};

export default connect(null, mapDispatchToProps)(AdminLogin);
