import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// components
import Sidebar from "../../../components/sidebar/Sidebar";
import SmallCalendar from "../../../components/calendar/SmallCalendar";
import TaskList from "../../../components/dashboard/TaskList";
import RoundProgress from "../../../components/dashboard/RoundProgress";
import ShowLoader from "../../../components/loader/ShowLoader";
import ErrorAlert from "../../../components/error/Error";
// images
import checkIcon from "../../../assets/icons/checkIcon.png";
import crossIcon from "../../../assets/icons/crossIcon.png";
import pendingIcon from "../../../assets/icons/pendingIcon.png";
import whiteBelt from "../../../assets/images/whiteBelt.png";
import greenBelt from "../../../assets/images/greenBelt.png";
import blackBelt from "../../../assets/images/blackBelt.png";

const SellerLog = ({ match, allsellers }) => {
	// variables
	const { sellerId } = match.params;
	const { t } = useTranslation();
	// states
	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [completeDate, setDate] = useState("");
	const [belt, setBelt] = useState("");
	const [withinBelt, setWithinBelt] = useState(0);
	const [overall, setOverall] = useState(0);
	const [list, setList] = useState([]);
	const [show, setShow] = useState(false);

	// functions
	useEffect(
		() => {
			const thisUser = allsellers.filter(x => x.sellerId === sellerId)[0];
			setName(thisUser.name);
			setId(thisUser.sellerId);
			setDate(new Date(new Date(thisUser.createdAt).setMonth(new Date(thisUser.createdAt).getMonth() + 8)).toLocaleDateString("en-GB"));
			setBelt(thisUser.status);
			setWithinBelt(thisUser.beltProgress);
			setOverall(thisUser.allProgress);
			setList([
				...thisUser.alltasks.filter(x => x.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
				...thisUser.alltasks.filter(x => x.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo),
				...thisUser.alltasks.filter(x => x.beltType === "Black").sort((a, b) => a.topicNo - b.topicNo)
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div id="dashboard-container">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow} />
			</div>

			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>

				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-fill"></i>
						<span> {t("Seller")}</span>
					</h2>
				</div>

				<div id="content">
					<ErrorAlert />
					<ShowLoader
						component={
							<div id="supervisor-dashboard">
								<div id="detailed-info">
									<h5 className="fw-bold">{t("Seller Details")}</h5>

									<div id="seller-log">
										<div id="log-info">
											<div>
												<p>
													{t("Seller Name")}: <span>{name}</span>
												</p>
												<p>
													{t("Seller ID")}: <span>{id}</span>
												</p>
												<p>
													{t("Completed All Modules By")}: <span>{completeDate}</span>
												</p>
											</div>

											<div className="text-center mt-2">
												<img src={belt === "Black" ? blackBelt : belt === "Green" ? greenBelt : whiteBelt} className="img-fluid" alt="belt" />
												<p>{t(belt + " Belt Status")}</p>
											</div>
										</div>
										<div className="belt-progress">
											<p>{t("Within Belt")}</p>
											<RoundProgress val={withinBelt} />
										</div>
										<div className="belt-progress">
											<p>{t("Overall")}</p>
											<RoundProgress val={overall} />
										</div>
									</div>
								</div>

								<div id="main-content">
									<div id="content-legend" className="row m-0 px-0 py-3">
										<div className="col">
											<p className="m-0 p-0">{t("Training Status")}</p>
										</div>
										<div className="col d-flex">
											<img src={checkIcon} alt="check" className="img-fluid" />
											<p>{t("Completed")}</p>
										</div>
										<div className="col d-flex">
											<img src={crossIcon} alt="cross" className="img-fluid" />
											<p>{t("Not Completed")}</p>
										</div>
										<div className="col d-flex">
											<img src={pendingIcon} alt="pending" className="img-fluid" />
											<p>{t("To Be Completed")}</p>
										</div>
									</div>

									<table className="table table-responsive table-borderless">
										<thead>
											<tr>
												<th scope="col">{t("No.")}</th>
												<th scope="col">{t("Content")}</th>
												<th scope="col">{t("Attempts Made")}</th>
												<th scope="col">{t("Status")}</th>
												<th scope="col">{t("Completion Date")}</th>
											</tr>
										</thead>

										<tbody>
											{list.length
												? list.map((val, ind) => (
														<tr key={val._id}>
															<th scope="row">{ind + 1}</th>
															<td>{val.title}</td>
															<td>{val.attempt || 0}</td>
															<td>
																<img src={val.completed === "notYet" ? pendingIcon : val.completed === false ? crossIcon : checkIcon} className="img-fluid" alt="status" />
															</td>
															<td>{val.completedDate}</td>
														</tr>
												  ))
												: null}
										</tbody>
									</table>
								</div>
							</div>
						}
						loadingColor="primary"
					/>
				</div>
			</div>

			<div id="rightbar-container">
				<SmallCalendar />
				<TaskList />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		allsellers: state.user.user.allsellers
	};
};

export default connect(mapStateToProps, null)(SellerLog);
