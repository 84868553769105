const calcScore = (questions, callback) => {
	const totalQuestions = questions.length;
	let correct = 0;
	let correctQuestions = [];
	let wrongQuestions = [];
	for (let i = 0; i < totalQuestions; i++) {
		const { questionType, correctAnswer, selectedAnswer, sequence, questionNo } = questions[i];

		if (questionType === "multipleChoice") {
			if (correctAnswer.length === 1) {
				console.log("MCQ");
				console.log("Single Answer");
				if (selectedAnswer[0] === correctAnswer[0]) {
					console.log("CORRECT");
					console.log({ correct: correctAnswer[0], answered: selectedAnswer[0] });
					correct++;
					correctQuestions.push(questionNo);
				} else {
					console.log("WRONG");
					console.log({ correct: correctAnswer[0], answered: selectedAnswer[0] });
					wrongQuestions.push(questionNo);
				}
			} else {
				console.log("MCQ");
				console.log("Multiple Answers");
				if (selectedAnswer.length === correctAnswer.length) {
					console.log("Same length");
					console.log(correctAnswer);
					console.log(selectedAnswer);
					let allCorrect = true;
					for (let x of selectedAnswer) {
						if (!correctAnswer.includes(x)) {
							console.log("Answer doesn't belong");
							console.log(x);
							allCorrect = false;
							break;
						}
					}

					if (allCorrect) {
						console.log("All answers are correct");
						console.log({ correctAnswer, selectedAnswer });
						correct++;
						correctQuestions.push(questionNo);
					} else {
						console.log("Some answers are wrong");
						console.log({ correctAnswer, selectedAnswer });
						wrongQuestions.push(questionNo);
					}
				} else {
					console.log("WRONG");
					console.log({ totalAnswers: correctAnswer.length, answered: selectedAnswer.length });
					wrongQuestions.push(questionNo);
				}
			}
		} else if (questionType === "sequence") {
			console.log("Sequence");
			if (selectedAnswer.filter(x => x).join() === sequence) {
				console.log("Correct sequence");
				console.log({ actualSequence: sequence, answeredSequence: selectedAnswer.filter(x => x).join() });
				correct++;
				correctQuestions.push(questionNo);
			} else {
				console.log("Wrong sequence");
				console.log({ actualSequence: sequence, answeredSequence: selectedAnswer.filter(x => x).join() });
				wrongQuestions.push(questionNo);
			}
		} else {
			console.log("Is Recap");
			correct++;
			correctQuestions.push(questionNo);
		}
	}
	const score = (correct / totalQuestions) * 100;
	console.log({ correct, totalQuestions, score, correctQuestions, wrongQuestions });
	callback(score, correctQuestions, wrongQuestions);
};

export default calcScore;
