import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEn = {
    "Select Site Language": "Select Site Language",
    "Login": "Login",
    "Seller ID / Supervisor ID": "Seller ID / Supervisor ID",
    "Password": "Password",
    "Forgot Password? Click here to reset": "Forgot Password? Click here to reset",
    "Register New Seller Account": "Register New Seller Account",
    "Register New Supervisor Account": "Register New Supervisor Account",
    "Register": "Register",
    "Key in your details": "Key in your details",
    "Already Registered?": "Already Registered?",
    "Click here to login": "Click here to login",
    "Full Name as Per IC": "Full Name as Per IC",
    "Phone Number": "Phone Number",
    "Seller ID": "Seller ID",
    "Supervisor ID": "Supervisor ID",
    "Email": "Email",
    "Distributor": "Distributor",
    "SIGN OUT": "SIGN OUT",
    "Complete All Modules By": "Complete All Modules By",
    "Home": "Home",
    "Calendar": "Calendar",
    "Training Topic": "Training Topic",
    "Sellers": "Sellers",
    "Supervisor Topic": "Supervisor Topic",
    "Download Certificate": "Download Certificate",
    "White Belt": "White Belt",
    "Green Belt": "Green Belt",
    "Black Belt": "Black Belt",
    "Dashboard": "Dashboard",
    "Training Status": "Training Status",
    "Completed": "Completed",
    "Not Completed": "Not Completed",
    "To Be Completed": "To Be Completed",
    "No.": "No.",
    "Topic Belt": "Topic Belt",
    "Content": "Content",
    "Expected Completion Date": "Expected Completion Date",
    "Actual Completion Date": "Actual Completion Date",
    "Status": "Status",
    "Detailed Infomation": "Detailed Infomation",
    "Remaining Task": "Remaining Task",
    "Topics": "Topics",
    "Quizzes": "Quizzes",
    "Completion Status": "Completion Status",
    "White Belt Status": "White Belt Status",
    "Green Belt Status": "Green Belt Status",
    "Black Belt Status": "Black Belt Status",
    "Initiate": "Initiate",
    "Passed": "Passed",
    "Not Achieved": "Not Achieved",
    "Pending": "Pending",
    "Upcoming": "Upcoming",
    "Theory": "Theory",
    "Quiz": "Quiz",
    "Progress Summary": "Progress Summary",
    "Task": "Task",
    "Category": "Category",
    "Select Your Language": "Select Your Language",
    "Malay": "Malay",
    "English": "English",
    "Chinese": "Chinese",
    "100% passing marks for quizzes are required before proceeding to the next topic.": "100% passing marks for quizzes are required before proceeding to the next topic.",
    "No restrictions on attempts to try.": "No restrictions on attempts to try.",
    "START NOW": "START NOW",
    "Prev": "Prev",
    "Next": "Next",
    "Done": "Done",
    "Select All That Applies": "Select All That Applies",
    "Select The Right Arrangement": "Select The Right Arrangement",
    "Previous": "Previous",
    "Initiate Training": "Initiate Training",
    "Seller": "Seller",
    "Sales Manager": "Sales Manager",
    "Date": "Date",
    "Time": "Time",
    "Download": "Download",
    "Submit": "Submit",
    "Supervisor": "Supervisor",
    "Select Supervisor": "Select Supervisor",
    "Select a Seller": "Select a Seller",
    "Belt": "Belt",
    "Topics/Skills": "Topics/Skills",
    "Select a Topic": "Select a Topic",
    "Close": "Close",
    "View Schedule": "View Schedule",
    "Edit Schedule": "Edit Schedule",
    "Evaluate": "Evaluate",
    "Edit": "Edit",
    "Delete": "Delete",
    "Back": "Back",
    "Pass": "Pass",
    "Fail": "Fail",
    "White": "White",
    "Green": "Green",
    "Black": "Black",
    "of Sellers with White Belt": "of Sellers with White Belt",
    "of Sellers with Green Belt": "of Sellers with Green Belt",
    "of Sellers with Black Belt": "of Sellers with Black Belt",
    "Seller Name": "Seller Name",
    "Seller Status": "Seller Status",
    "Completed Topics": "Completed Topics",
    "Next Topic / Topic to Restudy": "Next Topic / Topic to Restudy",
    "Complete Percentage Within Belt (%)": "Complete Percentage Within Belt (%)",
    "Detailed Log": "Detailed Log",
    "Seller Details": "Seller Details",
    "Completed All Modules By": "Completed All Modules By",
    "Within Belt": "Within Belt",
    "Overall": "Overall",
    "Attempts Made": "Attempts Made",
    "Completion Date": "Completion Date",
    "Schedule New Training": "Schedule New Training",
    "Are you sure": "Are you sure",
    "To logout your current session?": "To logout your current session?",
    "OK": "OK",
    "Cancel": "Cancel",
    "Confirmation": "Confirmation",
    "Are you sure to edit this scheduled training?": "Are you sure to edit this scheduled training?",
    "Are you sure you want to delete this training schedule?": "Are you sure you want to delete this training schedule?",
    "Evaluate this training as Passed?": "Evaluate this training as Passed?",
    "Evaluate this training as Failed?": "Evaluate this training as Failed?",
    "Confirm": "Confirm",
    "Error": "Error",
    "Please select language before proceed.": "Please select language before proceed.",
    "CONFIRM": "CONFIRM",
    "You are about the complete this quiz, proceed to evaluate test?": "You are about the complete this quiz, proceed to evaluate test?",
    "Yes, Proceed": "Yes, Proceed",
    "No, I want to double check my answers": "No, I want to double check my answers",
    "Failed": "Failed",
    "Congratulations, you have passed your test!": "Congratulations, you have passed your test!",
    "Sorry, you have failed your test.": "Sorry, you have failed your test.",
    "Required Input Error": "Required Input Error",
    "Please key in all the required input field (date, time, topic, business objective, training objective)": "Please key in all the required input field (date, time, topic, business objective, training objective)",
    "Understand": "Understand",
    "You have completed all the belt trainings": "You have completed all the belt trainings",
    "You must complete all the white belt modules.": "You must complete all the white belt modules.",
    "You must complete all the green belt modules.": "You must complete all the green belt modules.",
    "You must complete all the black belt modules.": "You must complete all the black belt modules.",
    "You can only initiate White training once": "You can only initiate White training once",
    "You can only initiate Green training once": "You can only initiate Green training once",
    "You can only initiate Black training once": "You can only initiate Black training once",
    "Initiate training with supervisor?": "Initiate training with supervisor?",
    "Please key in all the required input field (date, time, topic, seller)": "Please key in all the required input field (date, time, topic, seller)",
    "Already initiated a training towards this seller": "Already initiated a training towards this seller",
    "Initiate training with seller?": "Initiate training with seller?",
    "This seller need to complete all the modules first": "This seller need to complete all the modules first",
    "Please key in all the required input field (date, time, manager)": "Please key in all the required input field (date, time, manager)",
    "Please complete the EDAC Theory first": "Please complete the EDAC Theory first",
    "You have already completed this training": "You have already completed this training",
    "Initiate training with sales manager?": "Initiate training with sales manager?",
    "Please key in all the required input field (date, time, manager, supervisor)": "Please key in all the required input field (date, time, manager, supervisor)",
    "Complete": "Complete",
    "Have you completed the study session?": "Have you completed the study session?",
    "Sunday": "Sunday",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "JANUARY": "JANUARY",
    "FEBRUARY": "FEBRUARY",
    "MARCH": "MARCH",
    "APRIL": "APRIL",
    "MAY": "MAY",
    "JUNE": "JUNE",
    "JULY": "JULY",
    "AUGUST": "AUGUST",
    "SEPTEMBER": "SEPTEMBER",
    "OCTOBER": "OCTOBER",
    "NOVEMBER": "NOVEMBER",
    "DECEMBER": "DECEMBER",
    "Sun": "Sun",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "May",
    "June": "June",
    "July": "July",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Today": "Today",
}

const translationCh = {
    "Select Site Language": "选择网站语言",
    "Login": "登录",
    "Seller ID / Supervisor ID": "销售员编号 / 主管编号",
    "Password": "密码",
    "Forgot Password? Click here to reset": "忘记密码? 点击重置",
    "Register New Seller Account": "注册新销售员账户",
    "Register New Supervisor Account": "注册新主管账户",
    "Register": "注册",
    "Key in your details": "输入您的资料",
    "Already Registered?": "是否已注册？",
    "Click here to login": "点击登录",
    "Full Name as Per IC": "身份证号码全名",
    "Phone Number": "手机号码",
    "Seller ID": "销售员编号",
    "Supervisor ID": "主管编号",
    "Email": "电子邮件",
    "Distributor": "经销商",
    "SIGN OUT": "登出",
    "Complete All Modules By": "完成所有的板块",
    "Home": "主页",
    "Calendar": "日历",
    "Training Topic": "训练课题",
    "Sellers": "销售员",
    "Supervisor Topic": "主管课题",
    "Download Certificate": "下载文凭",
    "White Belt": "白带",
    "Green Belt": "绿带",
    "Black Belt": "黑带",
    "Dashboard": "控制面板",
    "Training Status": "训练状态",
    "Completed": "完成",
    "Not Completed": "未完成",
    "To Be Completed": "待完成",
    "No.": "编号",
    "Topic Belt": "主题级别",
    "Content": "内容",
    "Expected Completion Date": "预期完成日",
    "Actual Completion Date": "实际完成日",
    "Status": "状态",
    "Detailed Infomation": "详情资料",
    "Remaining Task": "剩余任务",
    "Topics": "课题",
    "Quizzes": "问题",
    "Completion Status": "完成程度",
    "White Belt Status": "白带状态",
    "Green Belt Status": "绿带状态",
    "Black Belt Status": "黑带状态",
    "Initiate": "发起",
    "Passed": "通过",
    "Not Achieved": "未达成",
    "Pending": "待定",
    "Upcoming": "即将到来",
    "Theory": "理论",
    "Quiz": "问题",
    "Progress Summary": "进展概况",
    "Task": "任务",
    "Category": "类别",
    "Select Your Language": "选择您的语言",
    "Malay": "马来文",
    "English": "英文",
    "Chinese": "中文",
    "100% passing marks for quizzes are required before proceeding to the next topic.": "必须100%答对所有问题，才能前往下一个课题。",
    "No restrictions on attempts to try.": "可无限制尝试。",
    "START NOW": "开始",
    "Prev": "前一个",
    "Next": "下一个",
    "Done": "完成",
    "Select All That Applies": "选择所有适用的",
    "Select The Right Arrangement": "选择正确的排列",
    "Previous": "前一个",
    "Initiate Training": "发起训练",
    "Seller": "销售员",
    "Sales Manager": "销售经理",
    "Date": "日期",
    "Time": "时间",
    "Download": "下载",
    "Submit": "成交",
    "Supervisor": "主管",
    "Select Supervisor": "选择主管",
    "Select a Seller": "选择销售员",
    "Belt": "级别",
    "Topics/Skills": "主题/技巧",
    "Select a Topic": "选择主题",
    "Close": "关闭",
    "View Schedule": "浏览行程",
    "Edit Schedule": "编辑行程",
    "Evaluate": "评估",
    "Edit": "编辑",
    "Delete": "删除",
    "Back": "返回",
    "Pass": "及格",
    "Fail": "不及格",
    "White": "白",
    "Green": "绿",
    "Black": "黑",
    "of Sellers with White Belt": "持白带的销售员",
    "of Sellers with Green Belt": "持绿带的销售员",
    "of Sellers with Black Belt": "持黑带的销售员",
    "Seller Name": "销售员名字",
    "Seller Status": "销售员状态",
    "Completed Topics": "完成课题",
    "Next Topic / Topic to Restudy": "下个课题 / 重新学习的课题",
    "Complete Percentage Within Belt (%)": "级别的完成百分率 (%)",
    "Detailed Log": "详细日记",
    "Seller Details": "销售员详情",
    "Completed All Modules By": "完成所有的板块",
    "Within Belt": "级别状态",
    "Overall": "整体",
    "Attempts Made": "尝试次数",
    "Completion Date": "实际完成日期",
    "Schedule New Training": "发起新训练",
    "Are you sure": "您是否确定",
    "To logout your current session?": "要登出吗？",
    "OK": "确定",
    "Cancel": "取消",
    "Confirmation": "确定",
    "Are you sure to edit this scheduled training?": "您是否确定要编辑此训练？",
    "Are you sure you want to delete this training schedule?": "您是否确定要删除此训练？",
    "Evaluate this training as Passed?": "评估此训练为及格？",
    "Evaluate this training as Failed?": "评估此训练为不及格？",
    "Confirm": "确定",
    "Error": "错误",
    "Please select language before proceed.": "请在继续之前选择语言。",
    "CONFIRM": "确定",
    "You are about the complete this quiz, proceed to evaluate test?": "您即将完成此测验，前往评估测验？",
    "Yes, Proceed": "是，前往",
    "No, I want to double check my answers": "不，我想检查我的答案",
    "Failed": "失败",
    "Congratulations, you have passed your test!": "恭喜，您已通过测验！",
    "Sorry, you have failed your test.": "抱歉，您未通过测验。",
    "Required Input Error": "输入错误",
    "Please key in all the required input field (date, time, topic, business objective, training objective)": "请输入所有必填的资料 (日期, 时间, 课题, 业务目的, 训练目的)",
    "Understand": "明白",
    "You have completed all the belt trainings": "您已完成所有级别的训练",
    "You must complete all the white belt modules.": "您必须完成所有白带的板块。",
    "You must complete all the green belt modules.": "您必须完成所有绿带的板块。",
    "You must complete all the black belt modules.": "您必须完成所有黑带的板块。",
    "You can only initiate White training once": "您只能发起一次白级别的训练",
    "You can only initiate Green training once": "您只能发起一次绿级别的训练",
    "You can only initiate Black training once": "您只能发起一次黑级别的训练",
    "Initiate training with supervisor?": "对主管发起训练？",
    "Please key in all the required input field (date, time, topic, seller)": "请输入所有必填的资料 (日期, 时间, 课题, 销售员)",
    "Already initiated a training towards this seller": "已经对该销售员发起训练",
    "Initiate training with seller?": "对销售员发起训练？",
    "This seller need to complete all the modules first": "此销售员需要先完成所有板块",
    "Please key in all the required input field (date, time, manager)": "请输入所有必填的资料 (日期, 时间, 经理)",
    "Please complete the EDAC Theory first": "请先完成 EDAC 理论",
    "You have already completed this training": "您已经完成了此训练",
    "Initiate training with sales manager?": "对销售经理发起训练？",
    "Please key in all the required input field (date, time, manager, supervisor)": "请输入所有必填的资料 (日期, 时间, 经理, 主管)",
    "Complete": "完成",
    "Have you completed the study session?": "您完成了学习课程吗？",
    "Sunday": "周日",
    "Monday": "周一",
    "Tuesday": "周二",
    "Wednesday": "周三",
    "Thursday": "周四",
    "Friday": "周五",
    "Saturday": "周六",
    "JANUARY": "一月",
    "FEBRUARY": "二月",
    "MARCH": "三月",
    "APRIL": "四月",
    "MAY": "五月",
    "JUNE": "六月",
    "JULY": "七月",
    "AUGUST": "八月",
    "SEPTEMBER": "九月",
    "OCTOBER": "十月",
    "NOVEMBER": "十一月",
    "DECEMBER": "十二月",
    "Sun": "日",
    "Mon": "一",
    "Tue": "二",
    "Wed": "三",
    "Thu": "四",
    "Fri": "五",
    "Sat": "六",
    "Jan": "一月",
    "Feb": "二月",
    "Mar": "三月",
    "Apr": "四月",
    "May": "五月",
    "June": "六月",
    "July": "七月",
    "Aug": "八月",
    "Sep": "九月",
    "Oct": "十月",
    "Nov": "十一月",
    "Dec": "十二月",
    "Today": "今天",
}

const translationBm = {
    "Select Site Language": "Pilih Bahasa Laman",
    "Login": "Log Masuk",
    "Seller ID / Supervisor ID": "ID Penjual / ID Penyelia",
    "Password": "Kata Laluan",
    "Forgot Password? Click here to reset": "Lupa kata laluan? Klik sini untuk menetapkan semula",
    "Register New Seller Account": "Daftar Akaun Baharu Penjual",
    "Register New Supervisor Account": "Daftar Akaun Baharu Penyelia",
    "Register": "Daftar",
    "Key in your details": "Masukkan Maklumat Anda",
    "Already Registered?": "Sudah Mendaftar?",
    "Click here to login": "Klik sini untuk Log Masuk",
    "Full Name as Per IC": "Nama Penuh Seperti dalam Kad Pengenalan",
    "Phone Number": "Nombor Telefon",
    "Seller ID": "ID Penjual",
    "Supervisor ID": "ID Penyelia",
    "Email": "Emel",
    "Distributor": "Pengagih",
    "SIGN OUT": "LOG KELUAR",
    "Complete All Modules By": "Lengkapkan Semua Modul pada",
    "Home": "Halaman Utama",
    "Calendar": "Kalendar",
    "Training Topic": "Topik Latihan",
    "Sellers": "Penjual",
    "Supervisor Topic": "Topik Penyelia",
    "Download Certificate": "Muat Turun Sijil",
    "White Belt": "Tali Pinggang Putih",
    "Green Belt": "Tali Pinggang Hijau",
    "Black Belt": "Tali Pinggang Hitam",
    "Dashboard": "Papan Pemuka",
    "Training Status": "Status Latihan",
    "Completed": "Sudah Siap",
    "Not Completed": "Belum Disiapkan",
    "To Be Completed": "Hampir Siap",
    "No.": "No.",
    "Topic Belt": "Topik Tali Pinggang",
    "Content": "Kandungan",
    "Expected Completion Date": "Jangkaan Tarikh akan Siap",
    "Actual Completion Date": "Tarikh Siap Sebenar",
    "Status": "Status",
    "Detailed Infomation": "Maklumat Lanjut",
    "Remaining Task": "Tugasan yang belum Siap",
    "Topics": "Topik",
    "Quizzes": "Kuiz",
    "Completion Status": "Status Penyiapan",
    "White Belt Status": "Status Tali Pinggang Putih",
    "Green Belt Status": "Status Tali Pinggang Hijau",
    "Black Belt Status": "Status Tali Pinggang Hitam",
    "Initiate": "Mulakan",
    "Passed": "Lulus",
    "Not Achieved": "Gagal",
    "Pending": "Tertangguh",
    "Upcoming": "Akan Datang",
    "Theory": "Teori",
    "Quiz": "Kuiz",
    "Progress Summary": "Ringkasan Kemajuan",
    "Task": "Tugasan",
    "Category": "Kategori",
    "Select Your Language": "Pilih Bahasa",
    "Malay": "Bahasa Melayu",
    "English": "Bahasa Inggeris",
    "Chinese": "Bahasa Cina",
    "100% passing marks for quizzes are required before proceeding to the next topic.": "Lulus 100% adalah mandatori sebelum teruskan topik seterusnya",
    "No restrictions on attempts to try.": "Tiada had percubaan",
    "START NOW": "MULA",
    "Prev": "Sebelum",
    "Next": "Seterusnya",
    "Done": "Siap",
    "Select All That Applies": "Pilih yang berkenaan",
    "Select The Right Arrangement": "Pilih susunan yang tepat",
    "Previous": "Sebelum",
    "Initiate Training": "Mulakan Latihan",
    "Seller": "Penjual",
    "Sales Manager": "Pengurus Jualan",
    "Date": "Tarikh",
    "Time": "Masa",
    "Download": "Muat Turun",
    "Submit": "Hantar",
    "Supervisor": "Penyelia",
    "Select Supervisor": "Pilih Penyelia",
    "Select a Seller": "Pilih Penjual",
    "Belt": "Tali Pinggang",
    "Topics/Skills": "Topik/Kemahiran",
    "Select a Topic": "Pilih Topik",
    "Close": "Tutup",
    "View Schedule": "Lihat Jadual",
    "Edit Schedule": "Menyuting Jadual",
    "Evaluate": "Penilaian",
    "Edit": "Menyunting",
    "Delete": "Batalkan",
    "Back": "Kembali",
    "Pass": "Lulus",
    "Fail": "Gagal",
    "White": "Putih",
    "Green": "Hijau",
    "Black": "Hitam",
    "of Sellers with White Belt": "daripada penjual Tali Pinggang Putih",
    "of Sellers with Green Belt": "daripada penjual Tali Pinggang Hijau",
    "of Sellers with Black Belt": "daripada penjual Tali Pinggang Hitam",
    "Seller Name": "Nama Penjual",
    "Seller Status": "Status Penjual",
    "Completed Topics": "Topik yang Telah Siap",
    "Next Topic / Topic to Restudy": "Topik Seterusnya / Topik untuk Dikaji Semula",
    "Complete Percentage Within Belt (%)": "Peratusan Penyiapan dalam kalangan Tali Pinggang (%)",
    "Detailed Log": "Log Terperinci",
    "Seller Details": "Maklumat Penjual",
    "Completed All Modules By": "Lengkapkan semua Modul pada",
    "Within Belt": "Dalam kalangan Tali Pinggang",
    "Overall": "Keseluruhan",
    "Attempts Made": "Cubaan yang Dibuat",
    "Completion Date": "Tarikh Siap Sebenar",
    "Schedule New Training": "Latihan Baharu yang Dijadualkan",
    "Are you sure": "Adakah anda pasti",
    "To logout your current session?": "logout sesi anda?",
    "OK": "Ok",
    "Cancel": "Batal",
    "Confirmation": "Pengesahan",
    "Are you sure to edit this scheduled training?": "Anda pasti nak menyunting latihan ini?",
    "Are you sure you want to delete this training schedule?": "Anda pasti nak batalkan latihan ini?",
    "Evaluate this training as Passed?": "Nilaikan latihan ini sebagai lulus?",
    "Evaluate this training as Failed?": "Nilaikan latihan ini sebagai gagal?",
    "Confirm": "Sahkan",
    "Error": "Ralat",
    "Please select language before proceed.": "Sila pilih bahasa sebelum meneruskan.",
    "CONFIRM": "Pengesahan",
    "You are about the complete this quiz, proceed to evaluate test?": "Anda akan siap kuiz ini, teruskan untuk menilai ujian?",
    "Yes, Proceed": "Ya, teruskan",
    "No, I want to double check my answers": "Tidak, saya ingin menyemak semula jawapan saya",
    "Failed": "Gagal",
    "Congratulations, you have passed your test!": "Tahniah, anda telah lulus ujian!",
    "Sorry, you have failed your test.": "Maaf, anda telah gagal ujian.",
    "Required Input Error": "Ralat Input",
    "Please key in all the required input field (date, time, topic, business objective, training objective)": "Sila masukkan semua bidang input yang diperlukan (tarikh, masa, topik, objektif perniagaan, objektif latihan)",
    "Understand": "Faham",
    "You have completed all the belt trainings": "Anda telah melengkapkan semua latihan",
    "You must complete all the white belt modules.": "Anda mesti melengkapkan semua modul tali pinggang putih.",
    "You must complete all the green belt modules.": "Anda mesti melengkapkan semua modul tali pinggang hijau.",
    "You must complete all the black belt modules.": "Anda mesti melengkapkan semua modul tali pinggang hitam.",
    "You can only initiate White training once": "Anda hanya boleh memulakan latihan putih sekali",
    "You can only initiate Green training once": "Anda hanya boleh memulakan latihan hijau sekali",
    "You can only initiate Black training once": "Anda hanya boleh memulakan latihan hitam sekali",
    "Initiate training with supervisor?": "Mulakan latihan dengan penyelia?",
    "Please key in all the required input field (date, time, topic, seller)": "Sila masukkan semua bidang input yang diperlukan (tarikh, masa, topik, penjual)",
    "Already initiated a training towards this seller": "Sudah memulakan latihan untuk penjual ini",
    "Initiate training with seller?": "Mulakan latihan dengan penjual?",
    "This seller need to complete all the modules first": "Penjual ini perlu melengkapkan semua modul terlebih dahulu",
    "Please key in all the required input field (date, time, manager)": "Sila masukkan semua bidang input yang diperlukan (tarikh, masa, pengurus)",
    "Please complete the EDAC Theory first": "Sila lengkapkan Teori EDAC terlebih dahulu",
    "You have already completed this training": "Anda telah siap latihan ini",
    "Initiate training with sales manager?": "Mulakan latihan dengan pengurus jualan?",
    "Please key in all the required input field (date, time, manager, supervisor)": "Sila masukkan semua bidang input yang diperlukan (tarikh, masa, pengurus, penyelia)",
    "Complete": "Siap",
    "Have you completed the study session?": "Sudahkan anda siap sesi pengajian?", 
    "Sunday": "Ahad",
    "Monday": "Isnin",
    "Tuesday": "Selasa",
    "Wednesday": "Rabu",
    "Thursday": "Khamis",
    "Friday": "Jumaat",
    "Saturday": "Sabtu",
    "JANUARY": "JANUARI",
    "FEBRUARY": "FEBRUARI",
    "MARCH": "MAC",
    "APRIL": "APRIL",
    "MAY": "MEI",
    "JUNE": "JUN",
    "JULY": "JULAI",
    "AUGUST": "OGOS",
    "SEPTEMBER": "SEPTEMBER",
    "OCTOBER": "OKTOBER",
    "NOVEMBER": "NOVEMBER",
    "DECEMBER": "DISEMBER",
    "Sun": "AHD",
    "Mon": "ISN",
    "Tue": "SEL",
    "Wed": "RAB",
    "Thu": "KHA",
    "Fri": "JUM",
    "Sat": "SAB",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mac",
    "Apr": "Apr",
    "May": "Mei",
    "June": "Jun",
    "July": "Jul",
    "Aug": "Ogos",
    "Sep": "Sep",
    "Oct": "Okt",
    "Nov": "Nov",
    "Dec": "Dis",
    "Today": "Hari ini",
}

const resources = {
  en: {
    translation: translationEn
  },
  ch: {
    translation: translationCh
  },
  bm: {
    translation: translationBm
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("language") || "en", 

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n; 