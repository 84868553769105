import moment from 'moment';

let years = [];
const months = moment.months();
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
let dates = [];

const thisYear = moment().year();
const thisMonth = moment().format("MMMM");
const thisDate = moment().format("D");

let year = thisYear - 21;

while (year <= thisYear + 21) {
    for (const month of months) {
        const daysInMonth = moment(`${year}-${month}`, "YYYY-MMMM").daysInMonth();
        for (let date = 1 ; date <= daysInMonth ; date ++) {
            const day = days[moment(`${date}-${month}-${year}`, "DD-MMMM-YYYY").day()];
            let dayObj = {
                year,
                month,
                date,
                day,
                today : false
            };

            if (
                parseInt(year) === parseInt(thisYear) &&
                month === thisMonth &&
                parseInt(date) === parseInt(thisDate)
            ) dayObj.today = true;

            dates.push(dayObj);
        };
    };

    years.push(year);
    year ++;
};

const current = {
    year : thisYear,
    month : thisMonth,
    date : parseInt(thisDate)
};

export { years, months, days, dates, current };