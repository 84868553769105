import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { checkSession } from "./redux/actions/userActions";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPass from "./pages/auth/ForgotPass";
import ResetPass from "./pages/auth/ResetPass";

import Dashboard from "./pages/main/Dashboard";
import Calendar from "./pages/main/Calendar";

import Training from "./pages/main/seller/Training";
import Theory from "./pages/main/seller/Theory";
import Quiz from "./pages/main/seller/Quiz";

import Sellers from "./pages/main/supervisor/Sellers";
import SellerLog from "./pages/main/supervisor/SellerLog";

import AdminLogin from "./pages/auth/AdminLogin";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminCalendar from "./pages/admin/AdminCalendar";
import DistributorView from "./pages/admin/DistributorView";
import SupervisorView from "./pages/admin/SupervisorView";
import SupervisorDetailedView from "./pages/admin/SupervisorDetailedView";
import SupervisorLog from "./pages/admin/SupervisorLog";
import SellerView from "./pages/admin/SellerView";
import SellerDetailedView from "./pages/admin/SellerDetailedView";
import TrainingTopic from "./pages/admin/TrainingTopic";
import AddTopic from "./pages/admin/AddTopic";
import ResetAccount from "./pages/admin/ResetAccount";

function App({ userState, check }) {
	const { user, isAuthenticated, type } = userState;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => check(), []);

	return (
		<BrowserRouter>
			<div className="App">
				<>
					{!user || !isAuthenticated ? (
						<Switch>
							<Route exact path="/login" component={Login} />
							<Route exact path="/register/:type" component={Register} />
							<Route exact path="/admin/login" component={AdminLogin} />
							<Route exact path="/password/forgot" component={ForgotPass} />
							<Route exact path="/password/reset/:token" component={ResetPass} />
							<Redirect to="/login" />
						</Switch>
					) : type === "seller" ? (
						<Switch>
							<Route exact path="/seller/dashboard" component={Dashboard} />
							<Route exact path="/seller/calendar" component={Calendar} />
							<Route exact path="/seller/training" component={Training} />
							<Route exact path="/seller/training/topic/:topicId" component={Theory} />
							<Route exact path="/seller/training/quiz/:quizId" component={Quiz} />
							<Redirect to="/seller/dashboard" />
						</Switch>
					) : type === "supervisor" ? (
						<Switch>
							<Route exact path="/supervisor/dashboard" component={Dashboard} />
							<Route exact path="/supervisor/calendar" component={Calendar} />
							<Route exact path="/supervisor/sellers" component={Sellers} />
							<Route exact path="/supervisor/training" component={Training} />
							<Route exact path="/supervisor/sellers/log/:sellerId" component={SellerLog} />
							<Route exact path="/supervisor/training/topic/:topicId" component={Theory} />
							<Route exact path="/supervisor/training/quiz/:quizId" component={Quiz} />
							<Redirect to="/supervisor/dashboard" />
						</Switch>
					) : type === "admin" ? (
						<Switch>
							<Route exact path="/admin/dashboard" component={AdminDashboard} />
							<Route exact path="/admin/calendar" component={AdminCalendar} />
							<Route exact path="/admin/distributor" component={DistributorView} />
							<Route exact path="/admin/supervisor" component={SupervisorView} />
							<Route exact path="/admin/supervisor/seller/:supervisorId" component={SupervisorDetailedView} />
							<Route exact path="/admin/supervisor/log/:supervisorId" component={SupervisorLog} />
							<Route exact path="/admin/seller" component={SellerView} />
							<Route exact path="/admin/seller/log/:sellerId" component={SellerDetailedView} />
							<Route exact path="/admin/trainingTopic" component={TrainingTopic} />
							<Route exact path="/admin/trainingTopic/add" component={AddTopic} />
							<Route exact path="/admin/trainingTopic/edit/:topicId" component={AddTopic} />
							<Route exact path="/admin/resetAccount" component={ResetAccount} />
							<Redirect to="/admin/dashboard" />
						</Switch>
					) : (
						<Redirect to="/login" />
					)}
				</>
			</div>
		</BrowserRouter>
	);
}

const mapStateToProps = state => {
	return {
		userState: state.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		check: () => dispatch(checkSession())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
