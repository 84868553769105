import { connect } from "react-redux";
import moment from "moment";

const TaskList = ({ trainings }) => {
	return (
		<div id="tasks-container">
			{trainings.map(training => (
				<div key={training._id} className="task" onClick={() => console.log(training)}>
					<div className="task-head">
						<div className="task-title">
							<div className={`task-color ${training.evaluation && training.evaluation === "Passed" ? "completed" : training.evaluation && training.evaluation === "Failed" ? "failed" : ""}`}></div>
							<p>{training.trainingTopic.length > 16 ? training.trainingTopic.slice(0, 16) + "..." : training.trainingTopic}</p>
						</div>
						<p className="task-date">{moment(training.trainingDate).format("DD MMMM YYYY")}</p>
					</div>

					<div className="task-foot">
						<div className={`task-time-container ${training.evaluation && training.evaluation === "Passed" ? "completed" : training.evaluation && training.evaluation === "Failed" ? "failed" : ""}`}>
							<p className="task-time">{training.trainingTime}</p>
						</div>
						<p className="arrow">&gt;</p>
					</div>
				</div>
			))}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		trainings: state.user.user.trainings
	};
};

export default connect(mapStateToProps, null)(TaskList);
