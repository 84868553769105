import { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import TaskList from "../../components/dashboard/TaskList";
import BigCalendar from "../../components/calendar/BigCalendar";
import { useTranslation } from "react-i18next";

const Calendar = () => {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<div id="dashboard-container">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow}/>
			</div>

			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>

				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-calendar-week"></i>
						<span> {t("Calendar")}</span>
					</h2>
				</div>

				<div id="content">
					<div id="big-calendar-container">
						<BigCalendar />
					</div>
				</div>
			</div>

			<div id="rightbar-container">
				<TaskList />
			</div>
		</div>
	);
};

export default Calendar;
