import { useTranslation } from "react-i18next";
import { useState } from 'react';

// multipleChoice / sequence / recap
// sequence {step , option}
const QuizRenderer = ({ quiz, handleMCQ, handleSequence }) => {
	const { t } = useTranslation();
	const [recap, setRecap] = useState("");
	const [showRecap, setShow] = useState(false);

	return (
		<div>
			<h3>
				Q{quiz.questionNo}:{" "}
				<span style={{ fontSize: "20px" }}>
					{quiz.question.split("\\n").map(x => (
						<span key={x}>
							{x} <br />
						</span>
					))}
				</span>
			</h3>

			{quiz.questionType === "multipleChoice" && quiz.correctAnswer.length === 1 ? (
				// MCQ
				// one correct answer only
				<div className="form-check mt-5 ms-5">
					{quiz.answerChoices
						.filter(choice => choice.answer !== "" && choice.answer)
						.map(choice => (
							<div key={choice._id} className="mb-2">
								<input className="form-check-input me-3" type="radio" name={quiz._id} id={quiz.id} value={choice.choice} onChange={e => handleMCQ(e.target.value, "single")} defaultChecked={quiz.selectedAnswer && quiz.selectedAnswer.includes(choice.choice)} />
								<label className="form-check-label fw-bold">
									{choice.choice}: {choice.answer}
								</label>
							</div>
						))}
				</div>
			) : quiz.questionType === "multipleChoice" && quiz.correctAnswer.length > 1 ? (
				// MCQ
				// multiple correct answer
				<div className="form-check mt-5 ms-5">
					<h2 className="fw-bold">{t("Select All That Applies")}</h2>
					{quiz.answerChoices
						.filter(choice => choice.answer !== "" && choice.answer)
						.map(choice => (
							<div key={choice._id} className="mb-2">
								<input className="form-check-input me-3" type="checkbox" name={choice._id} id={choice.id} value={choice.choice} onChange={e => handleMCQ(e.target.value, "multi")} defaultChecked={quiz.selectedAnswer && quiz.selectedAnswer.includes(choice.choice)} />
								<label className="form-check-label fw-bold">
									{choice.choice}: {choice.answer}
								</label>
							</div>
						))}
				</div>
			) : quiz.questionType === "sequence" ? (
				// SEQUENCE
				<div className="mt-5 ms-5">
					<h2 className="fw-bold">{t("Select The Right Arrangement")}</h2>
					{quiz.options.map((val, ind, arr) => (
						<div key={val._id} className="form-inline mb-2">
							<label className="me-3">{ind + 1}: </label>
							<select id={val._id} name={ind} className="form-select quiz-form-select" style={{ width: "35%", display: "inline-block" }} defaultValue={quiz.selectedAnswer ? quiz.selectedAnswer[ind] || "" : ""} onChange={handleSequence} required>
								<option disabled value="">
									Select Step {ind + 1}
								</option>
								{arr.map(ans => (
									<option key={ans._id} value={ans.number}>
										{ans.option}
									</option>
								))}
							</select>
						</div>
					))}
				</div>
			) : quiz.questionType === "recap" ? (
				// RECAP
				<div className="mt-5 ms-5">
					<textarea id="recap" name="recap" value={recap} onChange={e => setRecap(e.target.value)} className="form-control" disabled={showRecap}></textarea>
					<br />
					<button onClick={() => setShow(!showRecap)} className="btn btn-info">
						Compare Answer
					</button>
					{
						showRecap
						? (
							<h3 className="mt-5">
								{quiz.recap.split("\\n").map(x => (
									<span key={x}>
										{x} <br />
									</span>
								))}
							</h3>
						)
						: null
					}
					
				</div>
			) : null}
		</div>
	);
};

export default QuizRenderer;
