import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { addTopic, fetchEditTopic, editTopic, deleteTopic } from "../../redux/actions/adminActions";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import AddQuiz from "../../components/admin/AddQuiz";
import Loading from "../../components/loader/Loading";
import mySwal from "../../utilities/swal";
import processTopic from "../../utilities/processTopic";

const AddTopic = ({ history, match, dist }) => {
	// ----- STATE ----- //
	const [title, setTitle] = useState("");
	const [belt, setBelt] = useState("");
	const [mandatory, setMandatory] = useState([]);
	const [engPdf, setEngPdf] = useState(null);
	const [mlyPdf, setMlyPdf] = useState(null);
	const [chiPdf, setChiPdf] = useState(null);
	const [quiz, setQuiz] = useState(false);
	const [topicNo, setTopicNo] = useState(0);

	const [language, setLanguage] = useState("");
	const [engQuiz, setEngQuiz] = useState([]);
	const [mlyQuiz, setMlyQuiz] = useState([]);
	const [chiQuiz, setChiQuiz] = useState([]);

	const [isAdding, setAdding] = useState(false);
	const [currentIndex, setIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isEdit, setEdit] = useState(false);

	// ----- REFERENCE ----- //
	const engPdfInput = useRef();
	const mlyPdfInput = useRef();
	const chiPdfInput = useRef();

	// ----- FUNCTIONS ----- //
	useEffect(() => {
		if (dist !== "all") {
			history.push("/admin/calendar");
		} else {
			const { topicId } = match.params;
			if (topicId) {
				setLoading(true);
				setEdit(true);
				fetchEditTopic({ topicId }, (err, data) => {
					if (err) {
						setLoading(false);
						mySwal.fire("Error", err, "error");
					} else {
						// console.log(data);
						setTitle(data.theory.title);
						setTopicNo(data.theory.topicNo);
						setBelt(data.theory.beltType);
						setMandatory(data.theory.mandatoryFor);
						setQuiz(data.theory.quiz);
						setEngQuiz(
							data.quizzes
								.filter(x => x.language === "English")
								.map(x => {
									if (x.sequence) {
										return { ...x, sequence: x.sequence.split(",") };
									} else {
										return x;
									}
								})
						);
						setMlyQuiz(
							data.quizzes
								.filter(x => x.language === "Malay")
								.map(x => {
									if (x.sequence) {
										return { ...x, sequence: x.sequence.split(",") };
									} else {
										return x;
									}
								})
						);
						setChiQuiz(
							data.quizzes
								.filter(x => x.language === "Chinese")
								.map(x => {
									if (x.sequence) {
										return { ...x, sequence: x.sequence.split(",") };
									} else {
										return x;
									}
								})
						);
						setLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheckbox = e => (!mandatory.includes(e.target.id) ? setMandatory([...mandatory, e.target.id]) : setMandatory(mandatory.filter(val => val !== e.target.id)));

	// submit
	const handleSubmit = e => {
		if (!isEdit) {
			mySwal
				.fire({
					title: "Confirm",
					text: "Add this new topic?",
					icon: "question",
					showCancelButton: true,
					cancelButtonColor: "#d33",
					confirmButtonText: "Submit"
				})
				.then(click => {
					if (click.isConfirmed && click.value) {
						const processObject = {
							title,
							belt,
							mandatory,
							engPdf,
							mlyPdf,
							chiPdf,
							quiz,
							engQuiz,
							mlyQuiz,
							chiQuiz
						};
						setLoading(true);
						processTopic(processObject, false, (err, sendThis) => {
							if (err) {
								setLoading(false);
								mySwal.fire("Error", err, "error");
							} else {
								// console.log(sendThis);
								addTopic(sendThis, (erro, data) => {
									console.log({ err, data });
									if (erro) {
										setLoading(false);
										mySwal.fire("Error", err, "error");
									} else {
										setLoading(false);
										history.push("/admin/trainingTopic");
									}
								});
							}
						});
					}
				});
		} else {
			mySwal
				.fire({
					title: "Confirm",
					text: "Edit your topic and quizzes?",
					icon: "question",
					showCancelButton: true,
					cancelButtonColor: "#d33",
					confirmButtonText: "Submit"
				})
				.then(click => {
					if (click.isConfirmed && click.value) {
						console.log("Edit");
						setLoading(true);
						const { topicId } = match.params;
						const processObject = {
							title,
							belt,
							mandatory,
							engPdf,
							mlyPdf,
							chiPdf,
							quiz,
							engQuiz,
							mlyQuiz,
							chiQuiz,
							topicNo,
							topicId
						};
						processTopic(processObject, true, (err, sendThis) => {
							if (err) {
								setLoading(false);
								mySwal.fire("Error", err, "error");
							} else {
								// console.log(sendThis);
								editTopic(sendThis, (err, data) => {
									if (err) {
										setLoading(false);
										mySwal.fire("Error", err, "error");
									} else {
										setLoading(false);
										// console.log(data);
										history.push("/admin/trainingTopic");
									}
								});
							}
						});
					}
				});
		}
	};

	const handleDeleteTopic = e => {
		mySwal
			.fire({
				title: "Delete?",
				text: "Are you sure you want to delete this topic and all it's content?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonColor: "#d33",
				confirmButtonText: "YES",
				cancelButtonText: "NO"
			})
			.then(click => {
				if (click.isConfirmed && click.value) {
					const { topicId } = match.params;
					// console.log("Delete: ", topicId);
					setLoading(true);
					deleteTopic({ topicId }, (err, data) => {
						if (err) {
							setLoading(false);
							mySwal.fire("Error", err, "error");
						} else {
							setLoading(false);
							history.push("/admin/trainingTopic");
						}
					});
				}
			});
	};

	if (dist !== "all") {
		return <Redirect to="/admin/calendar" />;
	} else {
		return (
			<div className="dist-container">
				<div className="dist-sidebar">
					<AdminSidebar />
				</div>
				<div className="dist-content">
					<div className="content-title">
						<h2 className="m-0 p-0">
							<i className="bi bi-bullseye"></i> {!isEdit ? "Add" : "Edit"} {isAdding ? `${language} Quiz` : "Topic"}
						</h2>
					</div>

					{loading ? (
						<div className="mt-5">
							<Loading color="primary" />
						</div>
					) : (
						<div className="mt-5">
							{isEdit && !isAdding ? (
								<div className="mb-4">
									<button className="btn btn-danger" onClick={handleDeleteTopic}>
										Delete Topic
									</button>
								</div>
							) : null}
							{!isAdding ? (
								<div>
									<div className="row">
										{isEdit ? (
											<div className="form-group form-inline col-1">
												<label htmlFor="topicNo">Topic No: </label>
												<input type="number" id="topicNo" name="topicNo" className="form-control" value={topicNo} onChange={e => setTopicNo(e.target.value)} required />
											</div>
										) : null}
										<div className="form-group form-inline col">
											<label htmlFor="title">Topic Name: </label>
											<input type="text" id="title" name="title" className="form-control" value={title} onChange={e => setTitle(e.target.value)} required />
										</div>
										<div className="form-group form-inline col-4">
											<label htmlFor="belt">Belt: </label>
											<select id="belt" name="belt" className="form-select" value={belt} onChange={e => setBelt(e.target.value)} required>
												<option disabled value="">
													Select Belt
												</option>
												<option value="White">White Belt</option>
												<option value="Green">Green Belt</option>
												<option value="Black">Black Belt</option>
											</select>
										</div>
									</div>

									<div className="form-group mt-4 d-flex">
										<h6 className="me-3">Mandatory For:</h6>
										<div>
											<div className="form-check form-check-inline">
												<input type="checkbox" className="form-check-input" id="LHM" name="LHM" onChange={handleCheckbox} checked={mandatory.includes("LHM")} />
												<label htmlFor="LHM" className="form-check-label">
													LHM
												</label>
											</div>

											<div className="form-check form-check-inline">
												<input type="checkbox" className="form-check-input" id="KTC" name="KTC" onChange={handleCheckbox} checked={mandatory.includes("KTC")} />
												<label htmlFor="KTC" className="form-check-label">
													KTC
												</label>
											</div>

											<div className="form-check form-check-inline">
												<input type="checkbox" className="form-check-input" id="MER-S" name="MER-S" onChange={handleCheckbox} checked={mandatory.includes("MER-S")} />
												<label htmlFor="MER-S" className="form-check-label">
													MER-S
												</label>
											</div>

											<div className="form-check form-check-inline">
												<input type="checkbox" className="form-check-input" id="MER-EC" name="MER-EC" onChange={handleCheckbox} checked={mandatory.includes("MER-EC")} />
												<label htmlFor="MER-EC" className="form-check-label">
													MER-EC
												</label>
											</div>

											<div className="form-check form-check-inline">
												<input type="checkbox" className="form-check-input" id="KTCB" name="KTCB" onChange={handleCheckbox} checked={mandatory.includes("KTCB")} />
												<label htmlFor="KTCB" className="form-check-label">
													KTCB
												</label>
											</div>
										</div>
									</div>

									<div className="mt-4">
										<div className="form-group row">
											<div className="col-2">
												<label htmlFor="engPdf" className="form-label me-3 pt-2">
													English PDF:
												</label>
											</div>
											<div className="col-10">
												<div className="input-group">
													<input type="file" className="form-control" accept="application/pdf" id="engPdf" name="engPdf" ref={engPdfInput} onChange={e => setEngPdf(e.target.files[0])} />
													<button
														type="button"
														className="btn btn-outline-danger btn-warning"
														onClick={() => {
															setEngPdf(null);
															engPdfInput.current.value = "";
														}}
													>
														&times;
													</button>
												</div>
											</div>
										</div>

										<div className="form-group mt-2 row">
											<div className="col-2">
												<label htmlFor="mlyPdf" className="form-label me-3 pt-2">
													Malay PDF:
												</label>
											</div>
											<div className="col-10">
												<div className="input-group">
													<input type="file" className="form-control" accept="application/pdf" id="mlyPdf" name="mlyPdf" ref={mlyPdfInput} onChange={e => setMlyPdf(e.target.files[0])} />
													<button
														type="button"
														className="btn btn-outline-danger btn-warning"
														onClick={() => {
															setMlyPdf(null);
															mlyPdfInput.current.value = "";
														}}
													>
														&times;
													</button>
												</div>
											</div>
										</div>

										<div className="form-group mt-2 row">
											<div className="col-2">
												<label htmlFor="chiPdf" className="form-label me-3 pt-2">
													Chinese PDF:
												</label>
											</div>
											<div className="col-10">
												<div className="input-group">
													<input type="file" className="form-control" accept="application/pdf" id="chiPdf" name="chiPdf" ref={chiPdfInput} onChange={e => setChiPdf(e.target.files[0])} />
													<button
														type="button"
														className="btn btn-outline-danger btn-warning"
														onClick={() => {
															setChiPdf(null);
															chiPdfInput.current.value = "";
														}}
													>
														&times;
													</button>
												</div>
											</div>
										</div>
									</div>

									{isEdit ? <div className="form-text m-0 p-0">Upload new PDF file to replace the current PDF in used.</div> : null}

									<div className="mt-4">
										<h6>Quiz</h6>
										<div>
											<div className="form-check form-check-inline">
												<input type="radio" className="form-check-input" id="yes" name="quiz" onChange={() => setQuiz(true)} checked={quiz} />
												<label htmlFor="yes" className="form-check-label">
													Yes
												</label>
											</div>

											<div className="form-check form-check-inline">
												<input type="radio" className="form-check-input" id="no" name="quiz" onChange={() => setQuiz(false)} checked={!quiz} />
												<label htmlFor="no" className="form-check-label">
													No
												</label>
											</div>
										</div>
									</div>

									{quiz ? (
										<div className="mt-5 d-flex">
											<div className="me-2">
												<button
													className="btn btn-primary"
													onClick={() => {
														setAdding(true);
														setLanguage("English");
														setIndex(0);
													}}
												>
													{engQuiz.length ? "Edit" : "Add"} English Quiz
												</button>

												<p>
													Total Quiz: <strong>{engQuiz.length}</strong>
												</p>
											</div>

											<div className="me-2">
												<button
													className="btn btn-primary"
													onClick={() => {
														setAdding(true);
														setLanguage("Malay");
														setIndex(0);
													}}
												>
													{mlyQuiz.length ? "Edit" : "Add"} Malay Quiz
												</button>

												<p>
													Total Quiz: <strong>{mlyQuiz.length}</strong>
												</p>
											</div>

											<div className="me-2">
												<button
													className="btn btn-primary"
													onClick={() => {
														setAdding(true);
														setLanguage("Chinese");
														setIndex(0);
													}}
												>
													{chiQuiz.length ? "Edit" : "Add"} Chinese Quiz
												</button>

												<p>
													Total Quiz: <strong>{chiQuiz.length}</strong>
												</p>
											</div>
										</div>
									) : null}

									<div>
										<button className="btn btn-success form-control fw-bold" onClick={handleSubmit}>
											Submit
										</button>
									</div>
								</div>
							) : (
								<div>
									<AddQuiz
										setAdding={setAdding}
										quiz={language === "English" ? engQuiz : language === "Malay" ? mlyQuiz : chiQuiz}
										setQuiz={language === "English" ? setEngQuiz : language === "Malay" ? setMlyQuiz : setChiQuiz}
										language={language}
										currentIndex={currentIndex}
										setIndex={setIndex}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(AddTopic);
