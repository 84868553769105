import { useState, useRef } from "react";
import { connect } from "react-redux";
import { addSchedule } from "../../redux/actions/userActions";
import { Modal } from "react-bootstrap";
import ShowLoader from "../loader/ShowLoader";
import Loading from "../loader/Loading";
import ErrorAlert from "../error/Error";
import ScheduleContent from "./ScheduleContent";
import mySwal from "../../utilities/swal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";

const BigCalendarFooter = ({ type, user, addSchedule }) => {
	const { t } = useTranslation();
	// ----- States ----- //
	const downloadTarget = useRef();
	const downloadP2 = useRef();
	const downloadP3 = useRef();
	const [loading, setLoading] = useState(false);
	const [showModal, setModal] = useState(false);
	const [date, setDate] = useState("");
	const [time, setTime] = useState("");
	const [topic, setTopic] = useState([]);
	const [seller, setSeller] = useState("");
	const [mytype, setMytype] = useState(type === "admin" ? "edac" : "seller");
	const [edacForm, setEdacForm] = useState({
		manager: "",
		bObjective: "",
		tObjective: "",
		strength: "",
		nStep: "",
		bResult: "",
		tResult: "",
		opportunity: "",
		knowledge: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		skill: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		attitude: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		table: {
			a1: "",
			a2: "",
			a3: "",
			a4: "",
			a5: "",
			a6: "",
			b1: "",
			b2: "",
			b3: "",
			b4: "",
			b5: "",
			c1: "",
			c2: "",
			c3: "",
			c4: "",
			d1: "",
			d2: "",
			d3: "",
			d4: ""
		}
	});

	// eform
	// business summary
	const [bsObjective, setBsObjective] = useState("");
	const [bsSummary, setBsSummary] = useState("");
	// training summary
	const [tsObjective, setTsObjective] = useState("");
	const [tsSummary, setTsSummary] = useState("");
	const [highlight, setHighlight] = useState("");
	const [nextStep, setNextStep] = useState("");
	// assessment
	// business summary
	const [bsScore, setBsScore] = useState(0);
	const [bsRemark, setBsRemark] = useState("");
	// training summary
	const [tsScore, setTsScore] = useState(0);
	const [tsRemark, setTsRemark] = useState("");

	// ----- Variables ----- //
	const belt =
		type === "seller"
			? !user.whiteBeltComplete && !user.greenBeltComplete && !user.blackBeltComplete
				? "White"
				: user.whiteBeltComplete && !user.greenBeltComplete && !user.blackBeltComplete
				? "Green"
				: user.whiteBeltComplete && user.greenBeltComplete && !user.blackBeltComplete
				? "Black"
				: ""
			: type === "supervisor"
			? user.allsellers.filter(x => x.sellerId === seller)[0]
				? user.allsellers.filter(x => x.sellerId === seller)[0].status
				: ""
			: "";

	const getTasks = () => {
		if (type === "seller") {
			if (belt === "White") {
				return [...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo)];
			} else if (belt === "Green") {
				return [
					...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Black") {
				return [
					...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Black").sort((a, b) => a.topicNo - b.topicNo)
				];
			} else {
				return [];
			}
		} else if (type === "supervisor") {
			if (belt === "White") {
				return [
					...user.alltasks
						.filter(task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Green") {
				return [
					...user.alltasks.filter(
						task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId)
					).sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(
						task => task.beltType === "Green" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId)
					).sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Black") {
				return [
					...user.alltasks.filter(
						task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId)
					).sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(
						task => task.beltType === "Green" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId)
					).sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(
						task => task.beltType === "Black" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId)
					).sort((a, b) => a.topicNo - b.topicNo)
				];
			} else {
				return [];
			}
		} else {
			return [];
		}
	};
	const tasks = getTasks();
	const sellers = type === "supervisor" ? user.allsellers : [];
	const supervisors = type === "admin" ? user.supervisors : [];
	const [supervisor, setSupervisor] = useState("");
	// ----- Functions ----- //

	// close modal
	const closeModal = () => {
		setDate("");
		setTime("");
		setBsObjective("");
		setBsSummary("");
		setTsObjective("");
		setTsSummary("");
		setHighlight("");
		setNextStep("");
		setBsScore("");
		setBsRemark("");
		setTsScore("");
		setTsRemark("");
		setSeller("");
		setTopic("");
		setMytype(type === "admin" ? "edac" : "seller");
		setModal(false);
		setEdacForm({
			manager: "",
			bObjective: "",
			tObjective: "",
			strength: "",
			nStep: "",
			bResult: "",
			tResult: "",
			opportunity: "",
			knowledge: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			skill: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			attitude: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			table: {
				a1: "",
				a2: "",
				a3: "",
				a4: "",
				a5: "",
				a6: "",
				b1: "",
				b2: "",
				b3: "",
				b4: "",
				b5: "",
				c1: "",
				c2: "",
				c3: "",
				c4: "",
				d1: "",
				d2: "",
				d3: "",
				d4: ""
			}
		});
		setSupervisor("");
	};
	// add new schedule
	const addNewSchedule = e => {
		e.preventDefault();
		// console.log({ date, time, topic, bsObjective, bsSummary, tsObjective, tsSummary, highlight, nextStep, bsScore, bsRemark, tsScore, tsRemark });
		if (type === "seller") {
			if (!date || !time || !topic.length || !bsObjective || !tsObjective) {
				mySwal.fire({
					title: t("Required Input Error"),
					text: t("Please key in all the required input field (date, time, topic, business objective, training objective)"),
					icon: "info",
					confirmButtonText: t("Understand")
				});
			} else {
				if (!belt) {
					mySwal.fire("", t("You have completed all the belt trainings"), "info");
				} else {
					if (belt === "White" && parseInt(user.beltStatus.white) !== 100) {
						mySwal.fire("", t("You must complete all the white belt modules."), "info");
					} else if (belt === "Green" && parseInt(user.beltStatus.green) !== 100) {
						mySwal.fire("", t("You must complete all the green belt modules."), "info");
					} else if (belt === "Black" && parseInt(user.beltStatus.black) !== 100) {
						mySwal.fire("", t("You must complete all the black belt modules."), "info");
					} else if (user.trainings.filter(x => x.belt === belt && !x.evaluation).length || user.trainings.filter(x => x.belt === belt && x.evaluation === "Passed").length) {
						mySwal.fire("", t(`You can only initiate ${belt} training once`), "info");
					} else {
						const sendThis = {
							date,
							time,
							topic,
							bsObjective,
							bsSummary,
							tsObjective,
							tsSummary,
							highlight,
							nextStep,
							bsScore,
							bsRemark,
							tsScore,
							tsRemark,
							mytype,
							sellerId: user.sellerId,
							supervisorId: user.supervisorId,
							belt,
							trainingTopic: user.alltasks.filter(task => task._id === topic[0])[0].title,
							distributorId: user.distributorId
						};
						// console.log(sendThis);
						mySwal
							.fire({
								title: t("Confirmation"),
								text: t("Initiate training with supervisor?"),
								icon: "question",
								showCancelButton: true,
								cancelButtonText: t("Cancel"),
								confirmButtonText: t("Confirm")
							})
							.then(click => (click.isConfirmed && click.value ? addSchedule(sendThis, () => closeModal()) : console.log(click)));
					}
				}
			}
		} else if (type === "supervisor") {
			// supervisor
			if (mytype === "seller") {
				if (!date || !time || !topic.length || !seller) {
					mySwal.fire({
						title: t("Required Input Error"),
						text: t("Please key in all the required input field (date, time, topic, seller)"),
						icon: "info",
						confirmButtonText: t("Understand")
					});
				} else {
					const thisUser = user.allsellers.filter(x => x.sellerId === seller)[0];
					const userCompleted = thisUser.alltasks.filter(x => x.beltType === belt && x.completed && x.completed === true).length;
					const totalQuiz = user.alltasks.filter(x => x.beltType === belt).length;
					if (userCompleted === totalQuiz) {
						// console.log("All done");
						// console.log(user.trainings.filter(x => x.belt === belt && ));
						if (user.trainings.filter(x => x.belt === belt && x.evaluation === "Passed").length || user.trainings.filter(x => x.belt === belt && !x.evaluation).length) {
							mySwal.fire("", t("Already initiated a training towards this seller"), "info");
						} else {
							const sendThis = {
								date,
								time,
								topic,
								trainingTopic: user.alltasks.filter(task => task._id === topic[0])[0].title,
								sellerId: seller,
								supervisorId: user.supervisorId,
								belt,
								bsObjective,
								bsSummary,
								tsObjective,
								tsSummary,
								highlight,
								nextStep,
								bsScore,
								bsRemark,
								tsScore,
								tsRemark,
								mytype,
								distributorId: user.distributorId
							};
							// console.log(sendThis);
							mySwal
								.fire({
									title: t("Confirmation"),
									text: t("Initiate training with seller?"),
									icon: "question",
									showCancelButton: true,
									cancelButtonText: t("Cancel"),
									confirmButtonText: t("Confirm")
								})
								.then(click => (click.isConfirmed && click.value ? addSchedule(sendThis, () => closeModal()) : console.log(click)));
						}
					} else {
						mySwal.fire("", t("This seller need to complete all the modules first"), "info");
					}
				}
			} else {
				// edac
				if (!date || !time || !edacForm.manager) {
					mySwal.fire({
						title: t("Required Input Error"),
						text: t("Please key in all the required input field (date, time, manager)"),
						icon: "info",
						confirmButtonText: t("Understand")
					});
				} else {
					const edacTraining = user.alltasks.filter(x => x.beltType === "Black" && x.title === "EDAC")[0];
					if (edacTraining.completed === "notYet" || edacTraining.completed === false || edacTraining.evaluation === "Failed") {
						mySwal.fire("", t("Please complete the EDAC Theory first"), "info");
					} else {
						if (edacTraining.completed === true && edacTraining.evaluation === "Passed") {
							mySwal.fire("", t("You have already completed this training"), "info");
						} else {
							const sendThis = {
								date,
								time,
								mytype,
								supervisorId: user.supervisorId,
								edacForm,
								distributorId: user.distributorId
							};
							// console.log(sendThis);
							mySwal
								.fire({
									title: t("Confirmation"),
									text: t("Initiate training with sales manager?"),
									icon: "question",
									showCancelButton: true,
									cancelButtonText: t("Cancel"),
									confirmButtonText: t("Confirm")
								})
								.then(click => (click.isConfirmed && click.value ? addSchedule(sendThis, () => closeModal()) : console.log(click)));
						}
					}
				}
			}
		} else {
			// admin
			if (!edacForm.manager || !date || !time || !supervisor) {
				mySwal.fire({
					title: t("Required Input Error"),
					text: t("Please key in all the required input field (date, time, manager, supervisor)"),
					icon: "info",
					confirmButtonText: t("Understand")
				});
			} else {
				const sendThis = {
					date,
					time,
					mytype,
					supervisorId: supervisor,
					distributorId: supervisors.filter(x => x.supervisorId === supervisor)[0].distributorId,
					edacForm
				};
				// console.log(sendThis);
				mySwal
					.fire({
						title: t("Confirmation"),
						text: t("Initiate training with supervisor?"),
						icon: "question",
						showCancelButton: true,
						cancelButtonText: t("Cancel"),
						confirmButtonText: t("Confirm")
					})
					.then(click => (click.isConfirmed && click.value ? addSchedule(sendThis, () => closeModal()) : console.log(click)));
			}
		}
	};

	const downloadPdf = () => {
		setLoading(true);
		const margin = 10;

		html2canvas(downloadTarget.current).then(canvas1 => {
			let doc = new jsPDF();
			const image1 = canvas1.toDataURL("image/png");
			const image1Props = doc.getImageProperties(image1);
			const pageWidth = doc.internal.pageSize.getWidth();
			// const pageHeight = doc.internal.pageSize.getHeight();
			const page1Height = (image1Props.height * pageWidth) / image1Props.width;
			doc.addImage(image1, "PNG", margin, margin, pageWidth - margin * 2, page1Height - margin * 2);
			html2canvas(downloadP2.current).then(canvas2 => {
				const image2 = canvas2.toDataURL("image/png");
				const image2Props = doc.getImageProperties(image2);
				const page2Height = (image2Props.height * pageWidth) / image2Props.width;
				doc.addPage();
				doc.addImage(image2, "PNG", margin, margin, pageWidth - margin * 2, page2Height - margin * 2);

				if (downloadP3.current) {
					html2canvas(downloadP3.current).then(canvas3 => {
						const image3 = canvas3.toDataURL("image/png");
						const image3Props = doc.getImageProperties(image3);
						const page3Height = (image3Props.height * pageWidth) / image3Props.width;
						doc.addPage();
						doc.addImage(image3, "PNG", margin, margin, pageWidth - margin * 2, page3Height - margin * 2);

						doc.save("evaluationForm.pdf");
						setLoading(false);
					});
				} else {
					doc.save("evaluationForm.pdf");
					setLoading(false);
				}
			});
		});
	};

	return (
		<div>
			<div id="supervisor-calendar-footer">
				<button onClick={() => setModal(true)}>{type === "seller" ? t("Initiate") : t("Schedule New Training")}</button>

				<div id="legend-container">
					<div id="passed" className="legend">
						<p>{t("Passed")}</p>
					</div>
					<div id="failed" className="legend">
						<p>{t("Not Achieved")}</p>
					</div>
					<div id="pending" className="legend">
						<p>{t("Pending")}</p>
					</div>
					<div id="upcoming" className="legend">
						<p>{t("Upcoming")}</p>
					</div>
				</div>
			</div>

			<Modal id="add-modal" scrollable={true} show={showModal} onHide={() => closeModal()} size="xl">
				<Modal.Header closeButton closeLabel={t("Close")}>
					<Modal.Title>{t("Initiate Training")}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{type === "supervisor" ? (
						<div className="d-flex">
							<button type="button" onClick={() => setMytype("seller")} className={`btn form-control btn-${mytype === "seller" ? "primary" : "secondary"}`}>
								{t("Seller")}
							</button>
							<button type="button" onClick={() => setMytype("edac")} className={`btn form-control btn-${mytype === "edac" ? "primary" : "secondary"}`}>
								{t("Sales Manager")}
							</button>
						</div>
					) : null}

					<ErrorAlert />
					<div>
						<div className="row">
							<div className="col form-group">
								<label htmlFor="date">{t("Date")}</label>
								<input type="date" id="date" name="date" className="form-control" value={date} onChange={e => setDate(e.target.value)} required />
							</div>
							<div className="col form-group">
								<label htmlFor="time">{t("Time")}</label>
								<input type="time" id="time" name="time" className="form-control" value={time} onChange={e => setTime(e.target.value)} required />
							</div>
						</div>

						<ScheduleContent
							type={type}
							topic={topic}
							setTopic={setTopic}
							bsObjective={bsObjective}
							setBsObjective={setBsObjective}
							bsSummary={bsSummary}
							setBsSummary={setBsSummary}
							tsObjective={tsObjective}
							setTsObjective={setTsObjective}
							tsSummary={tsSummary}
							setTsSummary={setTsSummary}
							highlight={highlight}
							setHighlight={setHighlight}
							nextStep={nextStep}
							setNextStep={setNextStep}
							bsScore={bsScore}
							setBsScore={setBsScore}
							bsRemark={bsRemark}
							setBsRemark={setBsRemark}
							tsScore={tsScore}
							setTsScore={setTsScore}
							tsRemark={tsRemark}
							setTsRemark={setTsRemark}
							supervisorName={user.supervisorName || ""}
							belt={belt}
							tasks={tasks}
							modalMode="edit"
							isComplete={false}
							sellers={sellers}
							seller={seller}
							setSeller={setSeller}
							mytype={mytype}
							downloadTarget={downloadTarget}
							downloadP2={downloadP2}
							downloadP3={downloadP3}
							edacForm={edacForm}
							setEdacForm={setEdacForm}
							supervisors={supervisors}
							supervisor={supervisor}
							setSupervisor={setSupervisor}
						/>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<ShowLoader
						component={
							<>
								{loading ? (
									<Loading color="primary" />
								) : (
									<button onClick={downloadPdf} className="btn btn-primary fw-bold" style={{ width: "120px" }}>
										{t("Download")}
									</button>
								)}

								<button className="btn btn-success fw-bold" style={{ width: "120px" }} onClick={addNewSchedule}>
									{t("Submit")}
								</button>
							</>
						}
						loadingColor="primary"
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		type: state.user.type,
		user: state.user.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addSchedule: (data, callback) => dispatch(addSchedule(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BigCalendarFooter);
