import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions/userActions';

import ShowLoader from "../../components/loader/ShowLoader";
import ErrorAlert from "../../components/error/Error";
import mySwal from '../../utilities/swal';


const ResetPass = ({ match, history, resetPassword }) => {
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const { token } = match.params;

    const handleSubmit = e => {
        e.preventDefault();
        if (password === confirm) {
            // console.log({ password, confirm, id });
            resetPassword({ token, password }, () => history.push('/login'));
        } else {
            mySwal.fire("Info", "Password and Confirm Password is not matching", "info");
        }
    }

    return (
        <div id="login-page" className="auth-container">
            <div className="auth-box">
                <div className="text-center">
                    <h4>Enter Your New Password</h4>
                </div>
                <ErrorAlert />
                <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)} required />
                        </div>

                        <div className="form-group mt-2">
                            <input type="password" className="form-control" placeholder="Confirm Password" value={confirm} onChange={e => setConfirm(e.target.value)} required />
                        </div>

                        <div className="mt-3">
                            <ShowLoader component={<input type="submit" value="Reset and Log In" className="big-button" />} loadingColor="dark" />
                        </div>
                    </form>
                </div>
                <div className="mt-3">
                    <p>
                        <Link to="/login">Back</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (data, callback) => dispatch(resetPassword(data, callback))
    };
};

export default connect(null, mapDispatchToProps)(ResetPass);