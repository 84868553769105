import { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import SellerDashboard from "../../components/dashboard/SellerDashboard";
import SupervisorDashboard from "../../components/dashboard/SupervisorDashboard";
// import Rightbar from "../../components/sidebar/Rightbar";
import SmallCalendar from "../../components/calendar/SmallCalendar";
import TaskList from "../../components/dashboard/TaskList";
import { useTranslation } from "react-i18next";

const Dashboard = ({ type }) => {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<div id="dashboard-container">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow}/>
			</div>
			
			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>
				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-house-door-fill"></i>
						<span> {t("Dashboard")}</span>
					</h2>
				</div>

				<div id="content">{type === "seller" ? <SellerDashboard /> : <SupervisorDashboard />}</div>
			</div>

			<div id="rightbar-container">
				<SmallCalendar />
				<TaskList />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		type: state.user.type
	};
};

export default connect(mapStateToProps, null)(Dashboard);
