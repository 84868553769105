const Loading = ({ color }) => {
	return (
		<div className="text-center">
			<div className={`spinner-grow text-${color}`} role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default Loading;
