import { Redirect, Link } from "react-router-dom";
import RegisterForm from "../../components/misc/RegisterForm";
import ErrorAlert from "../../components/error/Error";
import { useTranslation } from "react-i18next";

const Register = ({ match }) => {
	const { t } = useTranslation();
	const { type } = match.params;
	if (type !== "supervisor" && type !== "seller") return <Redirect to="/login" />;
	else {
		return (
			<div id="register-page" className="auth-container">
				<div className="auth-box" style={{ marginTop: "2vh" }}>
					<div className="text-center">
						<h4 className="auth-title">{t("Register")}</h4>
						<p>{t("Key in your details")}</p>
					</div>
					<ErrorAlert />
					<div className="mt-4">
						<RegisterForm type={type} />
						<div className="mt-4">
							<p>
								{t("Already Registered?")} <Link to="/login">{t("Click here to login")}</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Register;
