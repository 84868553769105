const tokenConfig = getState => {
	const { token } = getState().user;
	const config = {
		headers: {
			"Content-Type": "application/json"
		}
	};

	if (token) config.headers["auth-token"] = token;
	return config;
};

export default tokenConfig;
