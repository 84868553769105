import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";
import logo from "../../assets/logo/logo.png";
import SidebarBody from "./SidebarBody";
import mySwal from "../../utilities/swal";
import { useTranslation } from "react-i18next";

const Sidebar = ({ logout, setShow }) => {
	const { t } = useTranslation();
	const handleLogout = e => {
		mySwal
			.fire({
				title: t("Are you sure"),
				text: t("To logout your current session?"),
				icon: "question",
				showCancelButton: true,
				cancelButtonColor: "#d33",
				confirmButtonText: t("OK"),
				cancelButtonText: t("Cancel")
			})
			.then(result => {
				if (result.isConfirmed && result.value) {
					logout();
				}
			});
	};

	return (
		<div id="sidebar">
			<div id="sidebar-header">
				<img src={logo} alt="logo" className="img-fluid" id="sidebar-logo" />
				<i className="bi bi-x-lg sidebar-close-button" onClick={() => setShow(false)}></i>
			</div>

			<div id="sidebar-body">
				<SidebarBody />
			</div>

			<div id="sidebar-footer">
				<button className="btn" onClick={handleLogout}>
					{t("SIGN OUT")}
				</button>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logoutUser())
	};
};

export default connect(null, mapDispatchToProps)(Sidebar);
