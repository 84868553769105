import AdminSidebar from "../../components/sidebar/AdminSidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../components/loader/Loading";
import { CSVLink } from "react-csv";

const SupervisorDetailedView = ({ match }) => {
	const [data, setData] = useState([]);
	const [supervisor, setSupervisor] = useState({
		name: "",
		supervisorId: "",
		sellers: 0,
		completeRate: "0%"
	});
	const [loading, updateLoading] = useState(true);

	useEffect(() => {
		const { supervisorId } = match.params;
		axios
			.post("/api/admin/supervisorDetails", { supervisorId })
			.then(response => {
				console.log(response.data);
				setData(response.data.sellerData);
				setSupervisor({
					name: response.data.name,
					supervisorId: response.data.supervisorId,
					sellers: response.data.sellers,
					completeRate: response.data.completeRate
				});
				updateLoading(false);
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<div id="content-legend" className="row m-0 px-0 py-3">
						<div className="col">
							<p className="m-0 p-0 fw-bold">Supervisor</p>
						</div>
					</div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Seller Name</th>
								<th scope="col">Seller ID</th>
								<th scope="col">Distributor</th>
								<th scope="col">Complete All Modules By</th>
								<th scope="col">Belt</th>
								<th scope="col">Within Belt Progress</th>
								<th scope="col">Overall Progress</th>
							</tr>
						</thead>

						<tbody>
							{data.map(x => (
								<tr key={x._id}>
									<th scope="row">{x.name}</th>
									<td>{x.sellerId}</td>
									<td>{x.distributor}</td>
									<td>{x.completeDate}</td>
									<td>{x.belt}</td>
									<td>{x.beltRate}</td>
									<td>{x.allRate}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
	}

	const today = new Date();
	const csvDate = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;

	let csvData = {
		headers: [
			{
				key: "name",
				label: "Seller Name"
			},
			{
				key: "sellerId",
				label: "Seller ID"
			},
			{
				key: "distributor",
				label: "Distributor"
			},
			{
				key: "completeDate",
				label: "Complete All Modules By"
			},
			{
				key: "belt",
				label: "Belt"
			},
			{
				key: "beltRate",
				label: "Within Belt Progress"
			},
			{
				key: "allRate",
				label: "Overall Progress"
			}
		],
		data
	};

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>
			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Supervisor</span>
					</h2>
				</div>

				<div className="d-flex">
					<div className="col-3 mt-3">
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Supervisor Name</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{supervisor.name}</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Supervisor ID</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{supervisor.supervisorId}</p>
							</div>
						</div>
					</div>
					<div className="col-6 mt-3 ms-5">
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Number of Sellers</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{supervisor.sellers}</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Team Overall of Completion Percentage</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{supervisor.completeRate}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-3">{renderSpinner()}</div>

				<div className="admin-button-box">
					<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`supervisordetailed_${csvDate}.csv`} target="_blank">
						Download Report
					</CSVLink>
				</div>
			</div>
		</div>
	);
};

export default SupervisorDetailedView;
