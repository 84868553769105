import AdminSidebar from "../../components/sidebar/AdminSidebar";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Loading from "../../components/loader/Loading";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SellerDetailedView = ({ match }) => {
	const downloadTarget = useRef();
	const downloadP2 = useRef();
	const [seller, updateSeller] = useState({
		name: "",
		sellerId: "",
		beltRate: "0%",
		allRate: "0%",
		whiteTraining: null,
		greenTraining: null,
		blackTraining: null
	});
	const [data, setData] = useState([]);
	const [loading, updateLoading] = useState(null);
	const [selected, setSelected] = useState("whiteTraining");

	useEffect(() => {
		const { sellerId } = match.params;
		updateLoading(true);
		axios
			.post("/api/admin/sellerDetails", { sellerId })
			.then(response => {
				console.log(response.data);
				updateSeller({
					name: response.data.name,
					sellerId: response.data.sellerId,
					beltRate: response.data.beltRate,
					allRate: response.data.allRate,
					whiteTraining: response.data.whiteTraining,
					greenTraining: response.data.greenTraining,
					blackTraining: response.data.blackTraining
				});
				setData([
					...response.data.data.filter(x => x.category === "White").sort((a, b) => a.topicNo - b.topicNo),
					...response.data.data.filter(x => x.category === "Green").sort((a, b) => a.topicNo - b.topicNo),
					...response.data.data.filter(x => x.category === "Black").sort((a, b) => a.topicNo - b.topicNo)
				]);
				updateLoading(false);
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadPdf = val => {
		const changeVal = async () => setSelected(val);

		changeVal().then(() => {
			const margin = 10;
			html2canvas(downloadTarget.current).then(canvas1 => {
				let doc = new jsPDF();
				const image1 = canvas1.toDataURL("image/png");
				const image1Props = doc.getImageProperties(image1);
				const pageWidth = doc.internal.pageSize.getWidth();
				// const pageHeight = doc.internal.pageSize.getHeight();
				const page1Height = (image1Props.height * pageWidth) / image1Props.width;
				doc.addImage(image1, "PNG", margin, margin, pageWidth - margin * 2, page1Height - margin * 2);
				html2canvas(downloadP2.current).then(canvas2 => {
					const image2 = canvas2.toDataURL("image/png");
					const image2Props = doc.getImageProperties(image2);
					const page2Height = (image2Props.height * pageWidth) / image2Props.width;
					doc.addPage();
					doc.addImage(image2, "PNG", margin, margin, pageWidth - margin * 2, page2Height - margin * 2);
					doc.save("evaluationForm.pdf");
				});
			});
		});
	};

	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Seller Name</th>
								<th scope="col">Category</th>
								<th scope="col">No.</th>
								<th scope="col">Content</th>
								<th scope="col">Attempt Made</th>
								<th scope="col">Average Time Taken (s)</th>
								<th scope="col">Completion Date</th>
							</tr>
						</thead>

						<tbody>
							{data.map((x, i) => (
								<tr key={x._id}>
									<th scope="row">{x.sellerName}</th>
									<td>{x.category}</td>
									<td>{i + 1}</td>
									<td>{x.content}</td>
									<td>{x.attempt}</td>
									<td>{x.time}</td>
									<td>{x.date}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
	}

	const today = new Date();
	const csvDate = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;

	let csvData = {
		headers: [
			{
				key: "sellerName",
				label: "Seller Name"
			},
			{
				key: "category",
				label: "Category"
			},
			{
				key: "content",
				label: "Content"
			},
			{
				key: "attempt",
				label: "Attempt Made"
			},
			{
				key: "time",
				label: "Average Time Taken"
			},
			{
				key: "date",
				label: "Completion Date"
			}
		],
		data
	};

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>
			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Seller</span>
					</h2>
				</div>

				<div className="d-flex">
					<div className="col-5 mt-3">
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Seller Name</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{seller.name}</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Seller ID</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{seller.sellerId}</p>
							</div>
						</div>
					</div>
					<div className="col-6 mt-3 ms-5">
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Within Grade Progress Percentage (%)</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{seller.beltRate}</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Overall Progress Percentage (%)</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{seller.allRate}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col mt-3">
					<div className="row m-0">
						<div className="col-3 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="fw-bold m-0 p-0">Evaluated Training Assessment Form</p>
						</div>
						<div
							className="col-3 m-0 text-center py-2"
							style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}
						>
							<p className="m-0 p-0 fw-bold">White Belt Training</p>
						</div>
						<div
							className="col-3 m-0 text-center py-2"
							style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}
						>
							<p className="m-0 p-0 fw-bold">Green Belt Training</p>
						</div>
						<div
							className="col-3 m-0 text-center py-2"
							style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}
						>
							<p className="m-0 p-0 fw-bold">Black Belt Training</p>
						</div>
					</div>

					<div className="row m-0">
						<div className="col-3 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="fw-bold m-0 p-0"></p>
						</div>
						<div className="col-3 m-0 text-center p-0" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<button
								onClick={() => downloadPdf("whiteTraining")}
								style={{ border: "none", height: "100%", width: "100%", margin: 0, padding: 0, backgroundColor: "white", color: "#51f0fd", fontWeight: "bold" }}
								className="py-2"
							>
								Download
							</button>
						</div>
						<div className="col-3 m-0 text-center p-0" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<button
								onClick={() => downloadPdf("greenTraining")}
								style={{ border: "none", height: "100%", width: "100%", margin: 0, padding: 0, backgroundColor: "white", color: "#51f0fd", fontWeight: "bold" }}
								className="py-2"
							>
								Download
							</button>
						</div>
						<div className="col-3 m-0 text-center p-0" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<button
								onClick={() => downloadPdf("blackTraining")}
								style={{ border: "none", height: "100%", width: "100%", margin: 0, padding: 0, backgroundColor: "white", color: "#51f0fd", fontWeight: "bold" }}
								className="py-2"
							>
								Download
							</button>
						</div>
					</div>
				</div>

				<div className="mt-3">{renderSpinner()}</div>

				<div className="admin-button-box">
					<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`sellerdetailed_${csvDate}.csv`} target="_blank">
						Download Report
					</CSVLink>
				</div>

				<div style={{ backgroundColor: "white", width: "80%", opacity: 0 }}>
					<div id="e-form" className="mt-5">
						<div ref={downloadTarget}>
							<div>
								<h5 className="fw-bold">Business Summary</h5>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Objectives of the Day - (Seller to Pre-Fill)</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">Pre-align objective of the day with Supervisor based on White/Green/Black Belt Topics</p>
											<p>
												E.g. Selling P.O. amounting to RM XXXX;
												<br />
												selling a new initiative product to buyer etc
											</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="bsObjective" name="bsObjective" value={seller[selected] ? seller[selected].eform.bsObjective : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Summary of Results - (Supervisor to Fill)</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">Vs pre-aligned objective, Supervisor to evaluate Passed/Failed. Refer 'Remark' for key points by topics</p>
											<p> E.g. Vs initial planning amount, able to sold in how much? Able to sell in new initiative</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="bsSummary" name="bsSummary" value={seller[selected] ? seller[selected].eform.bsSummary : ""} disabled readOnly></textarea>
									</div>
								</div>
							</div>

							<hr />

							<div>
								<h5 className="fw-bold">Training Summary</h5>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Objectives of the Day - (Seller to Pre-Fill)</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">Pre-align objective of the day with Supervisor based on White/Green/Black Belt Topics</p>
											<p>
												E.g. Able to demonstrate sales target planning;
												<br />
												able to perform 9 Steps to the Call fluently etc
											</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="tsObjective" name="tsObjective" value={seller[selected] ? seller[selected].eform.tsObjective : ""} disabled readOnly></textarea>
									</div>
								</div>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Summary of Results - (Supervisor to Fill)</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">Vs pre-aligned objective, Supervisor to evaluate Passed/Failed. Refer 'Remark' for key points by topics</p>
											<p> E.g. Understand and able to demonstrate clear understanding of sales target planning, 9 Step to Call etc</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="tsSummary" name="tsSummary" value={seller[selected] ? seller[selected].eform.tsSummary : ""} disabled readOnly></textarea>
									</div>
								</div>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Highlights</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">Highlight on business results achieved vs business objective</p>
											<p>Highlight on trainings results vs key learning of the day</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="highlight" name="highlight" value={seller[selected] ? seller[selected].eform.highlight : ""} disabled readOnly></textarea>
									</div>
								</div>

								<div className="eform-section">
									<div className="section-label">
										<div className="label-header">
											<h6>Next Steps</h6>
										</div>
										<div className="label-body">
											<p className="mb-3">What can we do to ensure sustain learning, to be discussed jointly by trainer &amp; trainees (supervisors &amp; sellers)</p>
											<p>What would seller do differently next to ensure today's learning can be applied in the future</p>
										</div>
									</div>

									<div className="section-input">
										<textarea id="nextStep" name="nextStep" value={seller[selected] ? seller[selected].eform.nextStep : ""} disabled readOnly></textarea>
									</div>
								</div>
							</div>
						</div>
						<hr />

						<div ref={downloadP2}>
							<div id="assessment-guideline">
								<div id="guideline-header">
									<h6 className="fw-bold">Assessment Guidelines</h6>
								</div>
								<div id="guideline-body">
									<dl>
										<dt className="fw-normal mb-3">* Trainee to do 100% of the call independently during assessment, without any help</dt>
										<dt className="fw-normal mb-3">* Trainer/Supervisor/Qualifier will observe the demonstration of the skill during the call</dt>
										<dt className="fw-normal">* Assessment criteria:</dt>
										<dd className="m-0 p-0">&nbsp;a) Min Business Results: 80%</dd>
										<dd className="m-0 p-0">&nbsp;b) Min Training Results: 70%</dd>
									</dl>
								</div>
							</div>

							<div className="my-4">
								<h5 className="fw-bold mb-2">Business Summary</h5>
								<ul className="list-group">
									<li className="list-group-item">
										<div className="left">
											<p>Score</p>
										</div>
										<div className="right"></div>
									</li>
									<li className="list-group-item">
										<div className="left">
											<p>%</p>
										</div>
										<div className="right">
											<input type="number" id="bsScore" name="bsScore" className="form-control" value={seller[selected] ? seller[selected].eform.bsScore : ""} disabled readOnly />
										</div>
									</li>
									<li className="list-group-item">
										<div className="left">
											<p>Other Remarks (If Any)</p>
										</div>
										<div className="right">
											<textarea id="bsRemark" name="bsRemark" value={seller[selected] ? seller[selected].eform.bsRemark : ""} disabled readOnly></textarea>
										</div>
									</li>
								</ul>
							</div>

							<div>
								<h5 className="fw-bold mb-2">Training Summary</h5>
								<ul className="list-group">
									<li className="list-group-item">
										<div className="left">
											<p>Score</p>
										</div>
										<div className="right"></div>
									</li>
									<li className="list-group-item">
										<div className="left">
											<p>%</p>
										</div>
										<div className="right">
											<input type="number" id="tsScore" name="tsScore" className="form-control" value={seller[selected] ? seller[selected].eform.tsScore : ""} disabled readOnly />
										</div>
									</li>
									<li className="list-group-item">
										<div className="left">
											<p>Other Remarks (If Any)</p>
										</div>
										<div className="right">
											<textarea id="tsRemark" name="tsRemark" value={seller[selected] ? seller[selected].eform.tsRemark : ""} disabled readOnly></textarea>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SellerDetailedView;
