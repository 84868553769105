import { connect } from "react-redux";

const ErrorAlert = ({ error, clearError }) => {
	if (error) {
		return (
			<div className="alert alert-warning alert-dismissible fade show" role="alert">
				<strong>{error}</strong>
				<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => clearError()}></button>
			</div>
		);
	} else {
		return <></>;
	}
};

const mapStateToProps = state => {
	return {
		error: state.page.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clearError: () => dispatch({ type: "CLEAR_ERROR" })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
