import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import whiteBelt from "../../assets/images/whiteBelt.png";
import greenBelt from "../../assets/images/greenBelt.png";
import blackBelt from "../../assets/images/blackBelt.png";
import Loading from "../../components/loader/Loading";
import RoundProgress from "../../components/dashboard/RoundProgress";

const DistributorView = ({ dist }) => {
	const [data, setData] = useState(null);
	const [loading, updateLoading] = useState(null);

	const [selected, setSelected] = useState("MER-S");
	const [type, setType] = useState("supervisor");

	useEffect(() => {
		if (dist === "lhm") setSelected("LHM");
		else if (dist === "ktcb") setSelected("KTCB");
		else if (dist === "merec") setSelected("MER-EC");
		const URL = "/api/admin/distributorDashboard";
		updateLoading(true);
		axios
			.get(URL)
			.then(response => {
				updateLoading(false);
				setData(response.data);
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<div className="admin-box">
						<div>
							<div id="admin-container-2">
								<div className="admin-item">
									<div className="admin-left">
										<img src={whiteBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {type[0].toUpperCase() + type.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data ? data[selected][type].white : ""}</h1>
										</div>
									</div>
									<div className="admin-right-2">
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Total Modules To Be Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].whiteModules : ""}</h1>
											</div>
										</div>
										<div className="admin-line"></div>
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Actual Total Modules Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].whiteCompleted : ""}</h1>
											</div>
										</div>
										{/* <div className="admin-line"></div>
										<div className="admin-list">
											<RoundProgress val={data ? data[selected][type].whiteRate : 0} />
										</div> */}
									</div>
								</div>
							</div>
						</div>
						<div className="admin-list">
							<div id="admin-container">
								<div className="admin-item">
									<div className="admin-left">
										<img src={greenBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {type[0].toUpperCase() + type.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data ? data[selected][type].green : ""}</h1>
										</div>
									</div>
									<div className="admin-right-2">
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Total Modules To Be Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].greenModules : ""}</h1>
											</div>
										</div>
										<div className="admin-line"></div>
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Actual Total Modules Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].greenCompleted : ""}</h1>
											</div>
										</div>
										{/* <div className="admin-line"></div>
										<div className="admin-list">
											<RoundProgress val={data ? data[selected][type].greenRate : 0} />
										</div> */}
									</div>
								</div>
							</div>
							<div id="admin-container">
								<div className="admin-item">
									<div className="admin-left">
										<img src={blackBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {type[0].toUpperCase() + type.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data ? data[selected][type].black : ""}</h1>
										</div>
									</div>
									<div className="admin-right-2">
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Total Modules To Be Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].blackModules : ""}</h1>
											</div>
										</div>
										<div className="admin-line"></div>
										<div className="admin-list">
											<div>
												<h3 className="admin-text-2">Actual Total Modules Completed(2 Months)</h3>
											</div>
											<div>
												<h1 className="admin-text-3">{data ? data[selected][type].blackCompleted : ""}</h1>
											</div>
										</div>
										{/* <div className="admin-line"></div>
										<div className="admin-list">
											<RoundProgress val={data ? data[selected][type].blackRate : 0} />
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>

			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Distributor</span>
					</h2>
				</div>

				<div className="d-flex mt-4">
					{dist === "all" || dist === "mers" ? (
						<button type="button" style={{ width: "120px" }} onClick={() => setSelected("MER-S")} className={`btn btn-${selected === "MER-S" ? "warning" : "primary"} me-2`}>
							MER-S
						</button>
					) : null}

					{dist === "all" || dist === "merec" ? (
						<button type="button" style={{ width: "120px" }} onClick={() => setSelected("MER-EC")} className={`btn btn-${selected === "MER-EC" ? "warning" : "primary"} me-2`}>
							MER-EC
						</button>
					) : null}

					{dist === "all" || dist === "lhm" ? (
						<button type="button" style={{ width: "120px" }} onClick={() => setSelected("LHM")} className={`btn btn-${selected === "LHM" ? "warning" : "primary"} me-2`}>
							LHM
						</button>
					) : null}

					{dist === "all" || dist === "ktcb" ? (
						<button type="button" style={{ width: "120px" }} onClick={() => setSelected("KTCB")} className={`btn btn-${selected === "KTCB" ? "warning" : "primary"} me-2`}>
							KTCB
						</button>
					) : null}
				</div>

				<div className="col-4 mt-3">
					<div className="row m-0">
						<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="fw-bold m-0 p-0">Total Number of Supervisors</p>
						</div>
						<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="m-0 p-0">{data ? data[selected]?.totalSpvs : 0}</p>
						</div>
					</div>
					<div className="row m-0">
						<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="fw-bold m-0 p-0">Total Number of Sellers</p>
						</div>
						<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p className="m-0 p-0">{data ? data[selected]?.totalSellers : 0}</p>
						</div>
					</div>
				</div>

				<div className="mt-3 d-flex">
					<button type="button" style={{ width: "120px" }} onClick={() => setType("supervisor")} className={`btn btn-${type === "supervisor" ? "warning" : "primary"} me-2`}>
						Supervisor
					</button>
					<button type="button" style={{ width: "120px" }} onClick={() => setType("seller")} className={`btn btn-${type === "seller" ? "warning" : "primary"} me-2`}>
						Seller
					</button>
				</div>

				{renderSpinner()}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(DistributorView);
