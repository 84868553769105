import { connect } from "react-redux";
import RoundProgress from "./RoundProgress";
import DotProgress from "./DotProgress";
import whiteBelt from "../../assets/images/whiteBelt.png";
import greenBelt from "../../assets/images/greenBelt.png";
import blackBelt from "../../assets/images/blackBelt.png";
import { useTranslation } from "react-i18next";

const SellerInfo = ({ user }) => {
	// const currentStatus = user.beltStatus.black ? user.beltStatus.black : user.beltStatus.green ? user.beltStatus.green : user.beltStatus.white;
	const allTasksLength = user.alltasks.length;
	const completedTaskLength = user.alltasks.filter(x => x.completed === true && typeof x.completed === "boolean").length;
	const { t } = useTranslation();

	return (
		<div id="seller-info">
			<div id="detailed-info">
				<h4 className="fw-bold">{t("Detailed Infomation")}</h4>
				<div className="mt-4 d-flex">
					<div className="me-3">
						<RoundProgress val={user.allProgress} />
					</div>
					<div className="p-2" style={{ width: "100%" }}>
						<p className="fw-bold">{t("Remaining Task")}</p>

						<div>
							<div>
								<p className="m-0 p-0 mb-2">{t("Topics")}</p>
								<DotProgress total={allTasksLength} completed={completedTaskLength} />
							</div>

							<div className="mt-4">
								<p className="m-0 p-0 mb-2">{t("Quizzes")}</p>
								<DotProgress total={user.alltasks.filter(x => x.quiz).length} completed={user.alltasks.filter(x => x.quiz && x.completed === true && typeof x.completed === "boolean").length} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="belt-container">
				<div id="white-belt" className="belt-info">
					<h6 className="fw-bold">
						{t("White Belt")}
						<br /> {t("Completion Status")}
					</h6>
					<div className="text-center me-1">
						<RoundProgress val={user.beltStatus.white} imgSrc={whiteBelt} />
						<p>{t("White Belt Status")}</p>
					</div>
				</div>

				<div id="green-belt" className="belt-info text-center">
					<RoundProgress val={user.beltStatus.green} imgSrc={greenBelt} />
					<p>{t("Green Belt Status")}</p>
				</div>

				<div id="black-belt" className="belt-info text-center">
					<RoundProgress val={user.beltStatus.black} imgSrc={blackBelt} />
					<p>{t("Black Belt Status")}</p>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(SellerInfo);
