import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import mySwal from "../../utilities/swal";

const AdminSidebar = ({ logout, dist }) => {
	const history = useHistory();
	const handleLogout = () => {
		mySwal
			.fire({
				title: "Are you sure",
				text: "To logout your current session?",
				icon: "question",
				showCancelButton: true,
				cancelButtonColor: "#d33"
			})
			.then(result => {
				if (result.isConfirmed && result.value) {
					logout();
					history.push("/admin/login");
				}
			});
	};

	return (
		<div id="sidebar" className="dist-sidebar">
			<div id="sidebar-header" className="dist-sidebar-header">
				<img src={logo} alt="logo" className="img-fluid" id="sidebar-logo" />
			</div>

			<div id="sidebar-body">
				<div id="sidebar-nav-container" className="dist-nav-container">
					<ul className="nav flex-column">
						{dist === "all" ? (
							<li className="nav-item">
								<NavLink activeClassName="active" className="nav-link" to="/admin/dashboard">
									<i className="bi bi-house-door-fill"> </i>
									<span> Home</span>
								</NavLink>
							</li>
						) : null}

						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to="/admin/calendar">
								<i className="bi bi-calendar-week"></i>
								<span>Calendar</span>
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to="/admin/distributor">
								<i className="bi bi-person-circle"> </i>
								<span> Distributor</span>
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to="/admin/supervisor">
								<i className="bi bi-person-circle"> </i>
								<span> Supervisor</span>
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to="/admin/seller">
								<i className="bi bi-person-circle"> </i>
								<span> Seller</span>
							</NavLink>
						</li>

						{dist === "all" ? (
							<li className="nav-item">
								<NavLink activeClassName="active" className="nav-link" to="/admin/trainingTopic">
									<i className="bi bi-bullseye"> </i>
									<span> Training Topic</span>
								</NavLink>
							</li>
						) : null}

						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" to="/admin/resetAccount">
								<i className="bi bi-arrow-clockwise"> </i>
								<span> Reset Account</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<div id="sidebar-footer" className="dist-sidebar-footer">
				<button className="btn fw-bold" onClick={handleLogout}>
					SIGN OUT
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logoutUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
