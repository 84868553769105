import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const RoundProgress = ({val , imgSrc}) => {
    if (!imgSrc) {
        return (
            <div className="round-progress-container">
                <CircularProgressbar
                    minValue={0}
                    maxValue={100}
                    value={val}
                    text={`${val}%`}
                    strokeWidth={15}
                    styles={buildStyles({strokeLinecap: "butt"})}
                    className="round-progress"
                />
            </div>
        );

    } else {
        return (
            <div className="round-progress-container">
                <CircularProgressbarWithChildren
                    minValue={0}
                    maxValue={100}
                    value={val}
                    strokeWidth={15}
                    styles={buildStyles({strokeLinecap: "butt"})}
                    className="round-progress"
                >
                    <div className="progress-image-container">
                        <img src={imgSrc} alt="img" className="img-fluid progress-image" />
                    </div>
                </CircularProgressbarWithChildren>   
            </div>
        );
    }
};

export default RoundProgress;