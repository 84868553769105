import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { completeTask } from "../../../redux/actions/userActions";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { getTheory } from "../../../redux/actions/userActions";
import Sidebar from "../../../components/sidebar/Sidebar";
import SmallCalendar from "../../../components/calendar/SmallCalendar";
import TaskList from "../../../components/dashboard/TaskList";
import Loading from "../../../components/loader/Loading";
import mySwal from "../../../utilities/swal";
import ErrorAlert from "../../../components/error/Error";
import ShowLoader from "../../../components/loader/ShowLoader";
import { useTranslation } from "react-i18next";

const Theory = ({ match, getTheory, alltasks, sellerId, completeTask, history, supervisorId, type }) => {
	const { topicId } = match.params;
	// states
	const [pdfUrl, setPdfUrl] = useState(null);
	const [numPages, setNumPages] = useState(null);
	const [page, setPage] = useState(1);
	const [thisTopic, setTopic] = useState(null);
	const [show, setShow] = useState(false);
	const [allPdf, setAllPdf] = useState([]);
	const [duration, setDuration] = useState(0);
	const [toggle, setToggle] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (!toggle) {
			pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
			getTheory({ topicId }, data => {
				setPdfUrl(data[0].pdfFile);
				setAllPdf(data);
			});
			setTopic(alltasks.filter(x => x._id === topicId)[0]);
			setToggle(true);
		}

		let timer = null;

		timer = setInterval(() => setDuration(duration + 1), 1000);

		return () => {
			// console.log("Unmount", { duration });
			clearInterval(timer);
			timer = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duration]);

	const handlePage = val => {
		const newValue = page + val;
		if (newValue >= 1 && newValue <= numPages) setPage(newValue);
	};

	const onDocumentLoaded = ({ numPages: nextNumPages }) => setNumPages(nextNumPages);

	const handleDone = e => {
		if (thisTopic.completed === "notYet") {
			mySwal
				.fire({
					title: t("Complete"),
					text: t("Have you completed the study session?"),
					icon: "question",
					showCancelButton: true,
					confirmButtonText: t("Confirm"),
					cancelButtonText: t("Cancel")
				})
				.then(result => {
					if (result.isConfirmed && result.value) {
						// e.target.disabled = true;
						const mytype = "Theory";
						const scores = 100;
						let sendThis = {
							topicId,
							mytype,
							scores,
							duration,
							type
						};
						if (type === "seller") {
							sendThis.sellerId = sellerId;
						} else {
							sendThis.supervisorId = supervisorId;
						}
						// console.log(sendThis);
						completeTask(sendThis, () => history.push(`/${type}/training`));
					}
				});
		} else {
			history.push(`/${type}/training`);
		}
	};
	return (
		<div id="dashboard-container">
			<div id="sidebar-container" className={show ? "show-sidebar" : ""}>
				<Sidebar setShow={setShow} />
			</div>

			<div id="content-container">
				<i onClick={() => setShow(true)} className="bi bi-list sidebarMenu"></i>

				<div id="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-bullseye"></i>
						<span> {t("Training Topic")}</span>
					</h2>
				</div>
				<ErrorAlert />
				<div id="content" className="seller-theory">
					<div className="d-flex justify-content-between">
						<h3 className="fw-bold ms-5">{thisTopic && thisTopic.title}</h3>
						<div className="m-0 p-0">
							{allPdf.map(pdf => (
								<button
									style={{ width: "100px" }}
									className={`btn btn-${pdf.pdfFile === pdfUrl ? "primary fw-bold" : "secondary"} m-0 me-2`}
									key={pdf.language}
									onClick={() => pdf.pdfFile !== pdfUrl && setPdfUrl(pdf.pdfFile)}
								>
									{t(pdf.language)}
								</button>
							))}
						</div>
					</div>

					<div id="theory-container">
						<Document className="pdf-document" file={pdfUrl} loading={<Loading color="primary" />} noData="PDF not found" onLoadSuccess={onDocumentLoaded} renderMode="svg">
							<Page
								pageNumber={page}
								loading={<Loading color="primary" />}
								noData="Page not found"
								className="pdf-page"
								width={window.innerWidth <= 500 ? 300 : window.innerWidth <= 850 ? 500 : 800}
							/>
						</Document>
					</div>

					<div className="mt-3 d-flex justify-content-between" style={{ width: "100%" }}>
						<div>
							<button className="btn btn-warning me-2 fw-bold previous-button" style={{ width: "180px", color: "white" }} onClick={() => handlePage(-1)}>
								{t("Previous")}
							</button>
							<button className="btn btn-success ms-2 fw-bold next-button" style={{ width: "180px" }} onClick={() => handlePage(1)}>
								{t("Next")}
							</button>
						</div>

						<ShowLoader
							component={
								<button className="btn btn-primary fw-bold done-button" style={{ width: "240px" }} onClick={handleDone}>
									{t("Done")}
								</button>
							}
							loadingColor="primary"
						/>
					</div>
				</div>
			</div>

			<div id="rightbar-container">
				<div>
					<SmallCalendar />
				</div>
				<TaskList />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		alltasks: state.user.user.alltasks,
		sellerId: state.user.user.sellerId,
		supervisorId: state.user.user.supervisorId,
		type: state.user.type
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTheory: (data, callback) => dispatch(getTheory(data, callback)),
		completeTask: (data, callback) => dispatch(completeTask(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Theory);
