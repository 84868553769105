import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import whiteBelt from "../../assets/images/whiteBelt.png";
import greenBelt from "../../assets/images/greenBelt.png";
import blackBelt from "../../assets/images/blackBelt.png";

import RoundProgress from "../../components/dashboard/RoundProgress";
import axios from "axios";
import Loading from "../../components/loader/Loading";

const AdminDashboard = ({ dist, history }) => {
	const [loading, updateLoading] = useState(null);
	const [data, setData] = useState(null);
	const [selected, setSelected] = useState("supervisor");
	const [category, setCategory] = useState("all");

	useEffect(() => {
		if (dist !== "all") {
			history.push("/admin/calendar");
		} else {
			updateLoading(true);
			const URL = "/api/admin/dashboard";
			axios
				.get(URL)
				.then(response => {
					console.log(response.data);
					updateLoading(false);
					setData(response.data);
				})
				.catch(err => alert(err.response.data.error));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<div className="admin-box">
						<div>
							<div id="admin-container-2">
								<div className="admin-item">
									<div className="admin-left">
										<img src={whiteBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {selected[0].toUpperCase() + selected.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data?.[selected][category].totalWhite}</h1>
										</div>
									</div>
									<div className="admin-right">
										<div className="admin-round">
											<RoundProgress val={data?.[selected][category].whiteRate} />
										</div>
										<div className="admin-div-sm">
											<p className="admin-text-lg">PERCENTAGE</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="admin-list">
							<div id="admin-container">
								<div className="admin-item">
									<div className="admin-left">
										<img src={greenBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {selected[0].toUpperCase() + selected.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data?.[selected][category].totalGreen}</h1>
										</div>
									</div>
									<div className="admin-right">
										<div className="admin-round">
											<RoundProgress val={data?.[selected][category].greenRate} />
										</div>
										<div className="admin-div-sm">
											<p className="admin-text-lg">PERCENTAGE</p>
										</div>
									</div>
								</div>
							</div>
							<div id="admin-container">
								<div className="admin-item">
									<div className="admin-left">
										<img src={blackBelt} alt="belt" className="img-fluid" />
										<div className="admin-div">
											<p className="admin-text-sm">Number of {selected[0].toUpperCase() + selected.substr(1)}</p>
										</div>
										<div className="admin-div">
											<h1 className="admin-text">{data?.[selected][category].totalBlack}</h1>
										</div>
									</div>
									<div className="admin-right">
										<div className="admin-round">
											<RoundProgress val={data?.[selected][category].blackRate} />
										</div>
										<div className="admin-div-sm">
											<p className="admin-text-lg">PERCENTAGE</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	if (dist !== "all") {
		return <Redirect to="/admin/calendar" />;
	} else {
		return (
			<div className="dist-container">
				<div className="dist-sidebar">
					<AdminSidebar />
				</div>

				<div className="dist-content">
					<div className="content-title">
						<h2 className="m-0 p-0">
							<i className="bi bi-house-door-fill"></i>
							<span> Dashboard</span>
						</h2>
					</div>
					<div className="col-4 mt-3">
						<div className="row m-0">
							<div
								className="col-8 m-0 py-2"
								style={{
									border: "1px grey solid",
									backgroundColor: "#1686fa",
									color: "white",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<p className="fw-bold m-0 p-0">Total Number of Supervisors</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{data?.total.supervisor[category]}</p>
							</div>
						</div>
						<div className="row m-0">
							<div
								className="col-8 m-0 py-2"
								style={{
									border: "1px grey solid",
									backgroundColor: "#1686fa",
									color: "white",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<p className="fw-bold m-0 p-0">Total Number of Sellers</p>
							</div>
							<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="m-0 p-0">{data?.total.seller[category]}</p>
							</div>
						</div>
					</div>

					<div className="mt-3 d-flex">
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setSelected("supervisor")}
							className={`btn btn-${selected === "supervisor" ? "primary" : "light"} me-2`}
						>
							Supervisor
						</button>
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setSelected("seller")}
							className={`btn btn-${selected === "seller" ? "primary" : "light"} me-2`}
						>
							Seller
						</button>
					</div>

					<div className="mt-3 d-flex">
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setCategory("all")}
							className={`btn btn-${category === "all" ? "primary" : "light"} me-2`}
						>
							ALL
						</button>
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setCategory("mers")}
							className={`btn btn-${category === "mers" ? "primary" : "light"} me-2`}
						>
							MER-S
						</button>
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setCategory("merec")}
							className={`btn btn-${category === "merec" ? "primary" : "light"} me-2`}
						>
							MER-EC
						</button>
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setCategory("lhm")}
							className={`btn btn-${category === "lhm" ? "primary" : "light"} me-2`}
						>
							LHM
						</button>
						<button
							type="button"
							style={{ width: "120px" }}
							onClick={() => setCategory("ktcb")}
							className={`btn btn-${category === "ktcb" ? "primary" : "light"} me-2`}
						>
							KTCB
						</button>
					</div>

					{renderSpinner()}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(AdminDashboard);
