import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AdminSidebar from "../../components/sidebar/AdminSidebar";

const SupervisorLog = ({ match }) => {
	const downloadTarget = useRef();
	const downloadP2 = useRef();
	const downloadP3 = useRef();
	const [data, setData] = useState([]);
	const [supervisor, setSupervisor] = useState({
		name: "",
		supervisorId: "",
		beltRate: "0%",
		allRate: "0%",
		number: "",
		email: "",
		completeDate: "",
		whiteBeltDate: "",
		greenBeltDate: "",
		blackBeltDate: "",
		edacForm: null
	});

	useEffect(() => {
		const { supervisorId } = match.params;
		axios
			.post("/api/admin/supervisorLog", { supervisorId })
			.then(res => {
				console.log(res.data);
				setData([
					...res.data.data.filter(x => x.category === "White").sort((a, b) => a.topicNo - b.topicNo),
					...res.data.data.filter(x => x.category === "Green").sort((a, b) => a.topicNo - b.topicNo),
					...res.data.data.filter(x => x.category === "Black").sort((a, b) => a.topicNo - b.topicNo)
				]);
				setSupervisor({
					name: res.data.name,
					supervisorId: res.data.supervisorId,
					beltRate: res.data.beltRate,
					allRate: res.data.allRate,
					number: res.data.number,
					email: res.data.email,
					completeDate: res.data.completeDate,
					whiteBeltDate: res.data.whiteBeltDate,
					greenBeltDate: res.data.greenBeltDate,
					blackBeltDate: res.data.blackBeltDate,
					edacForm: res.data.edacForm
				});
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const csvData = {
		headers: [
			{ label: "Supervisor Name", key: "supervisorName" },
			{ label: "Category", key: "category" },
			{ label: "Content", key: "content" },
			{ label: "Attempt Made", key: "attempt" },
			{ label: "Average Time Taken", key: "time" },
			{ label: "Completion Date", key: "completeDate" }
		],
		data
	};

	const downloadPdf = () => {
		const margin = 10;
		html2canvas(downloadTarget.current).then(canvas1 => {
			let doc = new jsPDF();
			const image1 = canvas1.toDataURL("image/png");
			const image1Props = doc.getImageProperties(image1);
			const pageWidth = doc.internal.pageSize.getWidth();
			// const pageHeight = doc.internal.pageSize.getHeight();
			const page1Height = (image1Props.height * pageWidth) / image1Props.width;
			doc.addImage(image1, "PNG", margin, margin, pageWidth - margin * 2, page1Height - margin * 2);
			html2canvas(downloadP2.current).then(canvas2 => {
				const image2 = canvas2.toDataURL("image/png");
				const image2Props = doc.getImageProperties(image2);
				const page2Height = (image2Props.height * pageWidth) / image2Props.width;
				doc.addPage();
				doc.addImage(image2, "PNG", margin, margin, pageWidth - margin * 2, page2Height - margin * 2);

				if (downloadP3.current) {
					html2canvas(downloadP3.current).then(canvas3 => {
						const image3 = canvas3.toDataURL("image/png");
						const image3Props = doc.getImageProperties(image3);
						const page3Height = (image3Props.height * pageWidth) / image3Props.width;
						doc.addPage();
						doc.addImage(image3, "PNG", margin, margin, pageWidth - margin * 2, page3Height - margin * 2);

						doc.save("evaluationForm.pdf");
					});
				} else {
					doc.save("evaluationForm.pdf");
				}
			});
		});
	};

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>

			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Supervisor</span>
					</h2>
				</div>

				<div className="mt-3">
					<div className="d-flex">
						<div className="col-5 mt-3">
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Supervisor Name</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.name}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Supervisor ID</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.supervisorId}</p>
								</div>
							</div>
						</div>
						<div className="col-6 mt-3 ms-5">
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Within Grade Progress Percentage (%)</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.beltRate}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Overall Progress Percentage (%)</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.allRate}</p>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex">
						<div className="col-5 mt-3">
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Phone Number</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.number}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Email</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.email}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Complete All Module by</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.completeDate}</p>
								</div>
							</div>
						</div>
						<div className="col-6 mt-3 ms-5">
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Date Achieved White Belt</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.whiteBeltDate}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Date Achieved Green Belt</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.greenBeltDate}</p>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="fw-bold m-0 p-0">Date Achieved Black Belt</p>
								</div>
								<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
									<p className="m-0 p-0">{supervisor.blackBeltDate}</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-6 mt-3">
						<div className="row m-0">
							<div className="col-6 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0">Evaluated Training Assessment Form</p>
							</div>
							<div
								className="col-6 m-0 text-center py-2"
								style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}
							>
								<p className="m-0 p-0 fw-bold">EDAC Training</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-6 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<p className="fw-bold m-0 p-0"></p>
							</div>
							<div className="col-6 m-0 text-center p-0" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<button
									style={{ border: "none", height: "100%", width: "100%", margin: 0, padding: 0, backgroundColor: "white", color: "#51f0fd", fontWeight: "bold" }}
									className="py-2"
									onClick={downloadPdf}
								>
									Download
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-3">
					<div id="content-legend" className="row m-0 px-0 py-3">
						<div className="col">
							<p className="m-0 p-0 fw-bold">Supervisor</p>
						</div>
					</div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Supervisor Name</th>
								<th scope="col">Category</th>
								<th scope="col">No.</th>
								<th scope="col">Content</th>
								<th scope="col">Attempt Made</th>
								<th scope="col">Average Time Taken (s)</th>
								<th scope="col">Completion Date</th>
							</tr>
						</thead>

						<tbody>
							{data.map((x, i) => (
								<tr key={x._id}>
									<th scope="row">{x.supervisorName}</th>
									<td>{x.category}</td>
									<td>{i + 1}</td>
									<td>{x.content}</td>
									<td>{x.attempt}</td>
									<td>{x.time}</td>
									<td>{x.completeDate}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="admin-button-box">
					<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="supervisorLog.csv" target="_blank">
						Download Report
					</CSVLink>
				</div>

				<div style={{ backgroundColor: "white", opacity: 0, width: "80%" }} className="px-5 py-2">
					<div id="edac-form" className="mt-5">
						<div ref={downloadTarget}>
							<div>
								<div>
									<h5 className="fw-bold">Objective:</h5>
									<p>This EDAC Form act as a checklist to ensure Trainees (hereon Supervisors) are well-equipped with the topics learnt during the Achiever's Program and is able to train Sellers</p>
								</div>

								<div>
									<h5 className="fw-bold">Step-by-Step Guidance:</h5>
									<ol>
										<li>Supervisors are required to print this material template before in-field assessment</li>
										<li>Supervisors are required to complete this training under the guidance of Sales Manager and above or equivalent (i.e Sales Manager, General Manager etc)</li>
										<li>Supervisors &amp; trainers to pre-aligned objectivesprior to visit to ensure all objectives are met.</li>
										<li>Upon completing this training, Supervisor to upload into system for tracking purposes</li>
										<li>Once done, 'Passed' or 'Failed' status will be reflected on Supervisor's &amp; Admin's dashboard.</li>
										<li>Shall the assesssment is 'Failed', SPV to reschedule another in-field assessmenet and the same as above would applicable.</li>
									</ol>
								</div>

								<div>
									<h5 className="fw-bold">Passing Criteria:</h5>
									<p>Min 3 'YES' per segment A, B, C &amp; D are required to be qualified as 'Passed' for this assessment</p>
								</div>

								<div>
									<h5 className="fw-bold">Remark:</h5>
									<p>* KAMs are highly recommended to present during the session as shadow observer and to give any inputs where appropriate</p>
									<p>* Final decision on 'Pass' or 'Fail' shall still remain in the hands of Distributor Leadership Team (i.e. Sales Manager, General Manager etc)</p>
								</div>
							</div>

							<hr />

							<div>
								<div className="input-container">
									<div className="input-label">
										<h6>Business Objectives</h6>
									</div>
									<div className="textarea-container">
										<textarea id="bObjective" name="bObjective" value={supervisor.edacForm ? supervisor.edacForm.edacForm.bObjective : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Training Objectives</h6>
									</div>
									<div className="textarea-container">
										<textarea id="tObjective" name="tObjective" value={supervisor.edacForm ? supervisor.edacForm.edacForm.tObjective : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Strength</h6>
									</div>
									<div className="textarea-container">
										<textarea id="strength" name="strength" value={supervisor.edacForm ? supervisor.edacForm.edacForm.strength : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Next Step</h6>
									</div>
									<div className="textarea-container">
										<textarea id="nStep" name="nStep" value={supervisor.edacForm ? supervisor.edacForm.edacForm.nStep : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Business Results</h6>
									</div>
									<div className="textarea-container">
										<textarea id="bResult" name="bResult" value={supervisor.edacForm ? supervisor.edacForm.edacForm.bResult : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Training Results</h6>
									</div>
									<div className="textarea-container">
										<textarea id="tResult" name="tResult" value={supervisor.edacForm ? supervisor.edacForm.edacForm.tResult : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>Opportunities</h6>
									</div>
									<div className="textarea-container">
										<textarea id="opportunity" name="opportunity" value={supervisor.edacForm ? supervisor.edacForm.edacForm.opportunity : ""} readOnly disabled></textarea>
									</div>
								</div>
							</div>
						</div>

						<hr />

						<div ref={downloadP2}>
							<div>
								<div className="section-header">
									<div className="header-head">
										<h6>KNOWLEDGE</h6>
									</div>
									<div className="header-description">
										<p>Assess trainee's overall Knowledge as Trainer</p>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>PREPARATION</h6>
									</div>
									<div className="textarea-container">
										<textarea id="kPrep" name="kPrep" value={supervisor.edacForm ? supervisor.edacForm.edacForm.knowledge.preparation : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>ALIGNMENT</h6>
									</div>
									<div className="textarea-container">
										<textarea id="kAlign" name="kAlign" value={supervisor.edacForm ? supervisor.edacForm.edacForm.knowledge.alignment : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>IDENTIFYING OPPORTUNITIES</h6>
									</div>
									<div className="textarea-container">
										<textarea id="kOppor" name="kOppor" value={supervisor.edacForm ? supervisor.edacForm.edacForm.knowledge.opportunity : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>TRAINING CYCLE</h6>
									</div>
									<div className="textarea-container">
										<textarea id="kCycle" name="kCycle" value={supervisor.edacForm ? supervisor.edacForm.edacForm.knowledge.cycle : ""} readOnly disabled></textarea>
									</div>
								</div>
							</div>

							<hr />

							<div>
								<div className="section-header">
									<div className="header-head">
										<h6>SKILL</h6>
									</div>
									<div className="header-description">
										<p>Assess trainee's overall Skill in Training</p>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>PREPARATION</h6>
									</div>
									<div className="textarea-container">
										<textarea id="sPrep" name="sPrep" value={supervisor.edacForm ? supervisor.edacForm.edacForm.skill.preparation : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>ALIGNMENT</h6>
									</div>
									<div className="textarea-container">
										<textarea id="sAlign" name="sAlign" value={supervisor.edacForm ? supervisor.edacForm.edacForm.skill.alignment : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>IDENTIFYING OPPORTUNITIES</h6>
									</div>
									<div className="textarea-container">
										<textarea id="sOppor" name="sOppor" value={supervisor.edacForm ? supervisor.edacForm.edacForm.skill.opportunity : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>TRAINING CYCLE</h6>
									</div>
									<div className="textarea-container">
										<textarea id="sCycle" name="sCycle" value={supervisor.edacForm ? supervisor.edacForm.edacForm.skill.cycle : ""} readOnly disabled></textarea>
									</div>
								</div>
							</div>

							<hr />

							<div>
								<div className="section-header">
									<div className="header-head">
										<h6>ATTITUDE/BEHAVIOUR</h6>
									</div>
									<div className="header-description">
										<p>Describe demonstrated Attitude and Behaviour as Trainer</p>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>PREPARATION</h6>
									</div>
									<div className="textarea-container">
										<textarea id="aPrep" name="aPrep" value={supervisor.edacForm ? supervisor.edacForm.edacForm.attitude.preparation : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>ALIGNMENT</h6>
									</div>
									<div className="textarea-container">
										<textarea id="aAlign" name="aAlign" value={supervisor.edacForm ? supervisor.edacForm.edacForm.attitude.alignment : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>IDENTIFYING OPPORTUNITIES</h6>
									</div>
									<div className="textarea-container">
										<textarea id="aOppor" name="aOppor" value={supervisor.edacForm ? supervisor.edacForm.edacForm.attitude.opportunity : ""} readOnly disabled></textarea>
									</div>
								</div>

								<div className="input-container">
									<div className="input-label">
										<h6>TRAINING CYCLE</h6>
									</div>
									<div className="textarea-container">
										<textarea id="aCycle" name="aCycle" value={supervisor.edacForm ? supervisor.edacForm.edacForm.attitude.cycle : ""} readOnly disabled></textarea>
									</div>
								</div>
							</div>
						</div>

						<hr />

						<div ref={downloadP3}>
							<div>
								<h5 className="fw-bold">For Sales Manager to fill in</h5>

								<table className="edac-table table-bordered">
									<thead className="edac-tableHead">
										<tr>
											<th scope="col" className="text-start ps-3 py-2">
												A) Explanation
											</th>
											<th scope="col">Y/N</th>
										</tr>
									</thead>

									<tbody className="edac-tableBody">
										<tr>
											<th scope="row">Review results vs. business objectives (gaps)</th>
											<td>
												<input type="text" className="table-input" id="a1" name="a1" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a1 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Discuss major training opportunities</th>
											<td>
												<input type="text" className="table-input" id="a2" name="a2" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a2 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Stress benefits - What's in it for me?</th>
											<td>
												<input type="text" className="table-input" id="a3" name="a3" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a3 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Enumerate execution step-by-step</th>
											<td>
												<input type="text" className="table-input" id="a4" name="a4" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a4 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Ask to ensure understanding</th>
											<td>
												<input type="text" className="table-input" id="a5" name="a5" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a5 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Discuss training/business objectives before demo</th>
											<td>
												<input type="text" className="table-input" id="a6" name="a6" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.a6 : ""} readOnly disabled />
											</td>
										</tr>
									</tbody>

									<thead className="edac-tableHead">
										<tr>
											<th scope="col" className="text-start ps-3 py-2">
												B) Demonstration
											</th>
											<th scope="col">Y/N</th>
										</tr>
									</thead>

									<tbody className="edac-tableBody">
										<tr>
											<th scope="row">Follow closely pattern of explanation</th>
											<td>
												<input type="text" className="table-input" id="b1" name="b1" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.b1 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Show complete procedure</th>
											<td>
												<input type="text" className="table-input" id="b2" name="b2" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.b2 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Ensure trainee's attention on right execution</th>
											<td>
												<input type="text" className="table-input" id="b3" name="b3" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.b3 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Think aloud, ask, involve trainee</th>
											<td>
												<input type="text" className="table-input" id="b4" name="b4" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.b4 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Review results vs. training objectives</th>
											<td>
												<input type="text" className="table-input" id="b5" name="b5" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.b5 : ""} readOnly disabled />
											</td>
										</tr>
									</tbody>

									<thead className="edac-tableHead">
										<tr>
											<th scope="col" className="text-start ps-3 py-2">
												C) Application/Trail
											</th>
											<th scope="col">Y/N</th>
										</tr>
									</thead>

									<tbody className="edac-tableBody">
										<tr>
											<th scope="row">Ask trainee to specify steps he needs to do</th>
											<td>
												<input type="text" className="table-input" id="c1" name="c1" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.c1 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Closely monitor execution of aggreed steps</th>
											<td>
												<input type="text" className="table-input" id="c2" name="c2" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.c2 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Use Training Worksheet</th>
											<td>
												<input type="text" className="table-input" id="c3" name="c3" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.c3 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Call trainee's attention when needed</th>
											<td>
												<input type="text" className="table-input" id="c4" name="c4" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.c4 : ""} readOnly disabled />
											</td>
										</tr>
									</tbody>

									<thead className="edac-tableHead">
										<tr>
											<th scope="col" className="text-start ps-3 py-2">
												D) Critique
											</th>
											<th scope="col">Y/N</th>
										</tr>
									</thead>

									<tbody className="edac-tableBody">
										<tr>
											<th scope="row">Do call review - ORLN</th>
											<td>
												<input type="text" className="table-input" id="d1" name="d1" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.d1 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Commend trainee on right execution</th>
											<td>
												<input type="text" className="table-input" id="d2" name="d2" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.d2 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Agree on standards for subsequent calls</th>
											<td>
												<input type="text" className="table-input" id="d3" name="d3" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.d3 : ""} readOnly disabled />
											</td>
										</tr>

										<tr>
											<th scope="row">Challenge, uplift, motivate &amp; encourage</th>
											<td>
												<input type="text" className="table-input" id="d4" name="d4" value={supervisor.edacForm ? supervisor.edacForm.edacForm.table.d4 : ""} readOnly disabled />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SupervisorLog;
