// import { useState } from "react";
import mySwal from "../../utilities/swal";
const alpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const AddQuiz = ({ setAdding, quiz, setQuiz, language, currentIndex, setIndex }) => {
	console.log(quiz[currentIndex])
	// ----- FUNCTION ----- //
	// add quiz
	const addQuiz = e => {
		const newQuiz = {
			questionNo: quiz.length + 1,
			questionType: "multipleChoice",
			question: "",
			answerChoices: [],
			correctAnswer: [],
			options: [],
			sequence: [],
			recap: "",
			language
		};
		setQuiz([...quiz, newQuiz]);
		setIndex(quiz.length);
	};

	// delete quiz
	const deleteQuiz = e => {
		mySwal
			.fire({
				title: "Confirm",
				text: "Delete this question?",
				showCancelButton: true,
				confirmButtonColor: "#d33"
			})
			.then(result => {
				if (result.isConfirmed && result.value) {
					console.log("DElete");
					let copyQuiz = [...quiz];
					const target = copyQuiz[currentIndex];
					copyQuiz = copyQuiz
						.filter(x => x.questionNo !== target.questionNo)
						.map((x, i) => {
							return { ...x, questionNo: i + 1 };
						});
					if (!copyQuiz.length) setIndex(0);
					else if (currentIndex >= copyQuiz.length - 1) setIndex(copyQuiz.length - 1);

					setQuiz(copyQuiz);
				}
			});
	};

	const handleChange = e => {
		let copyQuiz = [...quiz];
		copyQuiz[currentIndex][e.target.name] = e.target.value;
		setQuiz(copyQuiz);
	};

	// change questionType
	const handleType = e => {
		let copyQuiz = [...quiz];
		copyQuiz[currentIndex].questionType = e.target.name;
		setQuiz(copyQuiz);
	};

	// mcq add answer choice
	const addChoice = e => {
		let copyQuiz = [...quiz];
		const newChoice = {
			choice: alpha[copyQuiz[currentIndex].answerChoices.length],
			answer: ""
		};
		if (copyQuiz[currentIndex].answerChoices.length < alpha.length) {
			copyQuiz[currentIndex].answerChoices = [...copyQuiz[currentIndex].answerChoices, newChoice];
			setQuiz(copyQuiz);
		}
	};

	// sequence add option
	const addOption = e => {
		let copyQuiz = [...quiz];
		const newOption = {
			number: copyQuiz[currentIndex].options.length + 1,
			option: ""
		};
		copyQuiz[currentIndex].options = [...copyQuiz[currentIndex].options, newOption];
		setQuiz(copyQuiz);
	};

	// mcq answer choice text input
	const handleChoiceAnswer = e => {
		let copyQuiz = [...quiz];
		const choiceIndex = copyQuiz[currentIndex].answerChoices.findIndex(x => x.choice === e.target.name);
		copyQuiz[currentIndex].answerChoices[choiceIndex].answer = e.target.value;
		setQuiz(copyQuiz);
	};

	// sequence answer text input
	const handleSequenceAnswer = e => {
		let copyQuiz = [...quiz];
		const optionIndex = copyQuiz[currentIndex].options.findIndex(x => x.number === parseInt(e.target.name));
		copyQuiz[currentIndex].options[optionIndex].option = e.target.value;
		setQuiz(copyQuiz);
	};

	// mcq answer choice checkbox set as correct answer
	const handleCorrectAnswer = e => {
		let copyQuiz = [...quiz];
		if (!copyQuiz[currentIndex].correctAnswer.includes(e.target.value)) copyQuiz[currentIndex].correctAnswer = [...copyQuiz[currentIndex].correctAnswer, e.target.value];
		else copyQuiz[currentIndex].correctAnswer = copyQuiz[currentIndex].correctAnswer.filter(x => x !== e.target.value);
		setQuiz(copyQuiz);
	};

	// sequence correct answer
	const handleSequence = e => {
		let copyQuiz = [...quiz];
		if (copyQuiz[currentIndex].sequence === "") copyQuiz[currentIndex].sequence = [];
		copyQuiz[currentIndex].sequence[e.target.name] = e.target.value;
		setQuiz(copyQuiz);
	};

	// mcp delete answer choice
	const deleteChoice = val => {
		let copyQuiz = [...quiz];
		copyQuiz[currentIndex].answerChoices = copyQuiz[currentIndex].answerChoices
			.filter(x => x.choice !== val)
			.map((choice, ind) => {
				return { ...choice, choice: alpha[ind] };
			});
		if (copyQuiz[currentIndex].correctAnswer.includes(val)) copyQuiz[currentIndex].correctAnswer = copyQuiz[currentIndex].correctAnswer.filter(x => x !== val);
		setQuiz(copyQuiz);
	};

	// sequence delete option
	const deleteOption = val => {
		let copyQuiz = [...quiz];
		copyQuiz[currentIndex].options = copyQuiz[currentIndex].options
			.filter(x => x.number !== val)
			.map((option, ind) => {
				return { ...option, number: ind + 1 };
			});
		copyQuiz[currentIndex].sequence = [];
		const sequences = document.querySelectorAll(".sequenceInput");
		for (let i = 0; i < sequences.length; i++) {
			sequences[i].value = "";
		}
		setQuiz(copyQuiz);
	};
	console.log(quiz[currentIndex]);
	return (
		<div>
			<div>
				{quiz.map((question, ind) => (
					<button key={question.questionNo} onClick={() => setIndex(ind)} className={`btn mb-1 me-1 btn-${currentIndex === ind ? "primary" : "secondary"}`}>
						Q{question.questionNo}
					</button>
				))}
				<button className="btn mb-1 me-1 btn-success" onClick={addQuiz}>
					+
				</button>
			</div>

			{quiz.length ? (
				<div className="mt-5">
					<div>
						<button
							type="button"
							id="multipleChoiceButton"
							name="multipleChoice"
							onClick={handleType}
							className={`btn me-2 btn-${quiz[currentIndex].questionType === "multipleChoice" ? "primary" : "secondary"}`}
						>
							Objective
						</button>
						<button type="button" id="sequenceButton" name="sequence" onClick={handleType} className={`btn me-2 btn-${quiz[currentIndex].questionType === "sequence" ? "primary" : "secondary"}`}>
							Arrangement
						</button>
						<button type="button" id="recapButton" name="recap" onClick={handleType} className={`btn me-2 btn-${quiz[currentIndex].questionType === "recap" ? "primary" : "secondary"}`}>
							Recap &amp; Ans
						</button>
					</div>

					<div className="mt-4">
						<button className="btn btn-danger" onClick={deleteQuiz}>
							<i className="bi bi-trash me-2"></i>Delete Question
						</button>
						{quiz[currentIndex].questionType === "multipleChoice" ? (
							<div className="mt-2">
								<div className="form-group d-flex">
									<label htmlFor="question" className="me-3">
										Question:{" "}
									</label>
									<textarea id="question" name="question" className="form-control" value={quiz[currentIndex].question} onChange={handleChange}></textarea>
								</div>

								<div className="form-group mt-3">
									<h6>Answer Choice: </h6>
									{quiz[currentIndex].answerChoices.map((choice, ind) => (
										<div key={ind} className="d-flex align-items-center justify-content-end" style={{ width: "50%" }}>
											<label htmlFor={choice.choice} className="me-3 fw-bold">
												{choice.choice}
											</label>
											<input type="text" id={choice.choice} name={choice.choice} placeholder="Enter answer here ..." value={choice.answer} onChange={handleChoiceAnswer} style={{ width: "400px" }} />

											<button className="btn btn-danger" onClick={() => deleteChoice(choice.choice)}>
												&times;
											</button>
											<div className="form-check ms-5">
												<input
													type="checkbox"
													className="form-check-input"
													id={`checkbox${choice.choice}`}
													name={`checkbox${choice.choice}`}
													value={choice.choice}
													checked={quiz[currentIndex].correctAnswer.includes(choice.choice)}
													onChange={handleCorrectAnswer}
												/>
												<label className="form-check-label">Set as Correct Answer</label>
											</div>
										</div>
									))}
									<button className="btn btn-primary mt-5" onClick={addChoice}>
										+ Add Answer
									</button>
								</div>
							</div>
						) : quiz[currentIndex].questionType === "sequence" ? (
							<div className="mt-2">
								<div className="form-group d-flex">
									<label htmlFor="question" className="me-3">
										Question:{" "}
									</label>
									<textarea id="question" name="question" className="form-control" value={quiz[currentIndex].question} onChange={handleChange}></textarea>
								</div>

								<div className="form-group mt-3">
									{quiz[currentIndex].options.map((option, ind) => (
										<div key={ind} className="d-flex align-items-center justify-content-end" style={{ width: "500px" }}>
											<label htmlFor={option.number} className="me-3 fw-bold">
												{option.number}
											</label>

											<input type="text" id={option.number} name={option.number} placeholder={`Option ${ind + 1}`} value={option.option} onChange={handleSequenceAnswer} style={{ width: "400px" }} />
											<button className="btn btn-danger" onClick={() => deleteOption(option.number)}>
												&times;
											</button>
										</div>
									))}
									<button className="btn btn-primary mt-5" onClick={addOption}>
										+ Add Option
									</button>
								</div>

								<div className="mt-3">
									<h6 className="fw-bold">Correct Answer Sequence</h6>
									<div className="d-flex">
										{quiz[currentIndex].options.map((option, ind) => (
											<div key={ind} className="d-flex align-items-center me-2">
												<input
													type="number"
													style={{ width: "50px", height: "50px", fontWeight: "bold" }}
													id={`number${ind}`}
													name={ind}
													className="sequenceInput"
													min={1}
													max={quiz[currentIndex].options.length}
													onChange={handleSequence}
													value={quiz[currentIndex].sequence[ind]}
												/>
												{ind !== quiz[currentIndex].options.length - 1 ? <h2 className="m-0 p-0 ms-2 fw-bold">&gt;</h2> : null}
											</div>
										))}
									</div>
								</div>
							</div>
						) : (
							<div className="mt-2">
								<div className="form-group d-flex">
									<label htmlFor="question" className="me-3">
										Question:{" "}
									</label>
									<textarea id="question" name="question" className="form-control" value={quiz[currentIndex].question} onChange={handleChange}></textarea>
								</div>

								<div className="form-group mt-2">
									<label htmlFor="recap">Recap Answer: </label>
									<textarea id="recap" name="recap" className="form-control" value={quiz[currentIndex].recap} onChange={handleChange}></textarea>
								</div>
							</div>
						)}
					</div>
				</div>
			) : null}

			<div className="my-5 text-end">
				<button className="btn btn-success" onClick={() => setAdding(false)}>
					Back
				</button>
			</div>
		</div>
	);
};

export default AddQuiz;
