import AdminSidebar from "../../components/sidebar/AdminSidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import Loading from "../../components/loader/Loading";
import { CSVLink } from "react-csv";

const SellerView = ({ dist, history }) => {
	const [seller, updateSeller] = useState([]);
	const [loading, updateLoading] = useState(true);
	useEffect(() => {
		const URL = "/api/admin/sellerDashboard";
		axios
			.post(URL, { dist })
			.then(response => {
				console.log(response.data);
				updateSeller(response.data);
				updateLoading(false);
			})
			.catch(err => alert(err.response.data.error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	function renderSpinner() {
		if (loading === true) {
			return (
				<div>
					<Loading></Loading>
				</div>
			);
		} else {
			return (
				<div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">Seller Name</th>
								<th scope="col">Phone Number</th>
								<th scope="col">Email</th>
								<th scope="col">Distributor</th>
								<th scope="col">Planned Date To Be Qualified</th>
								<th scope="col">Supervisor Name</th>
								<th scope="col">Belt</th>
								<th scope="col">Date Achieved White Belt</th>
								<th scope="col">Date Achieved Green Belt</th>
								<th scope="col">Date Achieved Black Belt</th>
								<th scope="col">Overal Progress</th>
								<th scope="col">Seller Log</th>
							</tr>
						</thead>

						<tbody>
							{seller.map(sl => (
								<tr key={sl._id}>
									<th scope="row">{sl.name}</th>
									<td>{sl.number}</td>
									<td>{sl.email}</td>
									<td>{sl.distributorId}</td>
									<td>{sl.completeDate}</td>
									<td>{sl.supervisorName}</td>
									<td>{sl.belt}</td>
									<td>{sl.whiteBeltDate}</td>
									<td>{sl.greenBeltDate}</td>
									<td>{sl.blackBeltDate}</td>
									<td>{sl.progress}</td>
									<td>
										<button className="btn btn-primary" onClick={() => history.push(`/admin/seller/log/${sl._id}`)}>
											Log
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
	}

	const today = new Date();
	const csvDate = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;

	let csvData = {
		headers: [
			{
				key: "sellername",
				label: "Seller Name"
			},
			{
				key: "number",
				label: "Phone Number"
			},
			{
				key: "email",
				label: "Email"
			},
			{
				key: "distributor",
				label: "Distributor"
			},
			{
				key: "completeby",
				label: "Complete All Modules By"
			},
			{
				key: "supervisorname",
				label: "Supervisor Name"
			},
			{
				key: "belt",
				label: "Belt"
			},
			{
				key: "datewhite",
				label: "Date Achieved White Belt"
			},
			{
				key: "dategreen",
				label: "Date Achieved Green Belt"
			},
			{
				key: "dateblack",
				label: "Date Achieved Black Belt"
			},
			{
				key: "progress",
				label: "Overall Progress"
			}
		],
		data: []
	};

	if (seller) {
		for (let i = 0; i < seller.length; i++) {
			const csvObject = {
				sellername: seller[i].name,
				number: seller[i].number,
				email: seller[i].email,
				distributor: seller[i].distributorId,
				completeby: moment(seller[i].createdAt).add(2, "M").format("DD-MM-YYYY"),
				supervisorname: seller[i].supervisorName,
				belt: seller[i].belt,
				datewhite: seller[i].whiteBeltDate,
				dategreen: seller[i].greenBeltDate,
				dateblack: seller[i].blackBeltDate,
				progress: seller[i].progress
			};

			csvData.data.push(csvObject);
		}
	}

	return (
		<div className="dist-container">
			<div className="dist-sidebar">
				<AdminSidebar />
			</div>
			<div className="dist-content">
				<div className="content-title">
					<h2 className="m-0 p-0">
						<i className="bi bi-person-circle"></i>
						<span> Seller</span>
					</h2>
				</div>

				<div className="mt-3">{renderSpinner()}</div>

				<div className="admin-button-box">
					<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`seller_${csvDate}.csv`} target="_blank">
						Download Report
					</CSVLink>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(SellerView);
