import { connect } from 'react-redux';
import { changeLang } from '../../redux/actions/userActions';
import { useTranslation } from "react-i18next";

const SelectLang = ({ lang, setLang }) => {

	const { t } = useTranslation();

	const langClass = val => {
		if (lang === val) {
			return "lang selected";
		} else {
			return "lang";
		}
	};

	return (
		<div id="lang-selector">
			<p>
				{t('Select Site Language')}:{" "}
				<span>
					<span onClick={() => setLang("bm")} className={langClass("bm")}>
						{" "}
						BM{" "}
					</span>
					|
					<span onClick={() => setLang("en")} className={langClass("en")}>
						{" "}
						EN{" "}
					</span>
					|
					<span onClick={() => setLang("ch")} className={langClass("ch")}>
						{" "}
						中文{" "}
					</span>
				</span>
			</p>
		</div>
	);
};

const mapStateToProps = state => {
    return {
        lang: state.page.language
    };
};

const mapDispatchToProps = dispatch => {
	return {
		setLang: lang => dispatch(changeLang(lang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLang); 
