import { connect } from "react-redux";
import moment from "moment";
import SellerInfo from "./SellerInfo";
import checkIcon from "../../assets/icons/checkIcon.png";
import crossIcon from "../../assets/icons/crossIcon.png";
import pendingIcon from "../../assets/icons/pendingIcon.png";
import { useTranslation } from "react-i18next";

const SellerDashboard = ({ user }) => {
	const { t } = useTranslation();
	const renderExpectedDate = belt => {
		const value = belt === "White" ? 3 : belt === "Green" ? 6 : 8;
		return moment(new Date(new Date(user.createdAt).setMonth(new Date(user.createdAt).getMonth() + value))).format("MMMM DD, YYYY");
	};

	const tasks = [
		...user.alltasks.filter(x => x.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
		...user.alltasks.filter(x => x.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo),
		...user.alltasks.filter(x => x.beltType === "Black").sort((a, b) => a.topicNo - b.topicNo)
	];

	return (
		<div id="seller-dashboard">
			<SellerInfo />

			<div id="main-content">
				<div>
					<div id="content-legend" className="row m-0 px-0 py-3">
						<div className="col">
							<p className="m-0 p-0">{t("Training Status")}</p>
						</div>
						<div className="col d-flex">
							<img src={checkIcon} alt="check" className="img-fluid" />
							<p>{t("Completed")}</p>
						</div>
						<div className="col d-flex">
							<img src={crossIcon} alt="cross" className="img-fluid" />
							<p>{t("Not Completed")}</p>
						</div>
						<div className="col d-flex">
							<img src={pendingIcon} alt="pending" className="img-fluid" />
							<p>{t("To Be Completed")}</p>
						</div>
					</div>

					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">{t("No.")}</th>
								<th scope="col">{t("Topic Belt")}</th>
								<th scope="col">{t("Content")}</th>
								<th scope="col">{t("Expected Completion Date")}</th>
								<th scope="col">{t("Actual Completion Date")}</th>
								<th scope="col">{t("Status")}</th>
							</tr>
						</thead>

						<tbody>
							{tasks.map((task, ind) => (
								<tr key={task._id}>
									<th scope="row">{ind + 1}</th>
									<td>{task.beltType} Belt</td>
									<td>{task.title}</td>
									<td>{renderExpectedDate(task.beltType)}</td>
									<td>{task.completedDate}</td>
									<td>
										{task.completed === "notYet" ? (
											<img src={pendingIcon} alt="pending" className="img-fluid" />
										) : task.completed === false && typeof task.completed === "boolean" ? (
											<img src={crossIcon} alt="incomplete" className="img-fluid" />
										) : task.completed === true && typeof task.completed === "boolean" ? (
											<img src={checkIcon} alt="completed" className="img-fluid" />
										) : (
											task.completed
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(SellerDashboard);
