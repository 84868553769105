import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import Loading from "../../components/loader/Loading";
import { fetchTopicDashboard } from "../../redux/actions/adminActions";
import mySwal from "../../utilities/swal";

const TrainingTopic = ({ history, dist }) => {
	const [supervisor, setSupervisor] = useState(0);
	const [seller, setSeller] = useState(0);
	const [topics, setTopics] = useState([]);
	const [selected, setSelected] = useState("White");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (dist !== "all") {
			history.push("/admin/calendar");
		} else {
			fetchTopicDashboard((err, data) => {
				if (err) mySwal.fire("Error", err, "error");
				else {
					console.log(data);
					setSupervisor(data.totalSupervisors);
					setSeller(data.totalSellers);
					setTopics(data.data);
					setLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (dist !== "all") {
		return <Redirect to="/admin/calendar" />;
	} else {
		return (
			<div className="dist-container">
				<div className="dist-sidebar">
					<AdminSidebar />
				</div>
				<div className="dist-content">
					<div className="content-title">
						<h2 className="m-0 p-0">
							<i className="bi bi-bullseye"></i> Training Topic
						</h2>
					</div>

					{loading ? (
						<div className="mt-5">
							<Loading color="primary" />
						</div>
					) : (
						<div className="mt-5">
							<div className="col-4">
								<div className="row m-0">
									<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<p className="fw-bold m-0 p-0">Total Number of Supervisors</p>
									</div>
									<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<p className="m-0 p-0">{supervisor}</p>
									</div>
								</div>
								<div className="row m-0">
									<div className="col-8 m-0 py-2" style={{ border: "1px grey solid", backgroundColor: "#1686fa", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<p className="fw-bold m-0 p-0">Total Number of Sellers</p>
									</div>
									<div className="col-4 m-0 text-center py-2" style={{ border: "1px grey solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<p className="m-0 p-0">{seller}</p>
									</div>
								</div>
							</div>

							<div className="mt-5">
								<div>
									<button className={`btn btn-${selected === "White" ? "primary" : "secondary"} me-2`} onClick={() => setSelected("White")}>
										White Belt
									</button>
									<button className={`btn btn-${selected === "Green" ? "primary" : "secondary"} me-2`} onClick={() => setSelected("Green")}>
										Green Belt
									</button>
									<button className={`btn btn-${selected === "Black" ? "primary" : "secondary"} me-2`} onClick={() => setSelected("Black")}>
										Black Belt
									</button>
								</div>
								<div className="mt-3">
									<div id="content-legend" className="py-2 ps-3">
										<p className="m-0 p-0 fw-bold">Training Status</p>
									</div>

									<table className="table table-responsive table-borderless">
										<thead>
											<tr>
												<th scope="col">Topic</th>
												<th scope="col">Topic Content</th>
												<th scope="col">Percentage Achieved</th>
												<th scope="col">Average Attempt</th>
												<th scope="col">Number of On-The-Job-Trainings Schedule</th>
												<th scope="col">Quiz (Y/N)</th>
												<th scope="col">Action</th>
											</tr>
										</thead>

										<tbody>
											{topics
												.filter(topic => topic.beltType === selected)
												.sort((a, b) => a.topicNo - b.topicNo)
												.map(topic => (
													<tr key={topic._id}>
														<th scope="row">{topic.topicNo}</th>
														<td>{topic.title}</td>
														<td>{topic.percentage}</td>
														<td>{topic.avgAttempt}</td>
														<td>{topic.training}</td>
														<td>{topic.quiz ? "Y" : "N"}</td>
														<td>
															<button className="btn btn-warning" onClick={() => history.push(`/admin/trainingTopic/edit/${topic._id}`)}>
																Update
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>

								<div>
									<Link to="/admin/trainingTopic/add" className="btn btn-info">
										Add New
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		dist: state.user.user.distributor
	};
};

export default connect(mapStateToProps, null)(TrainingTopic);
