import { connect } from "react-redux";
import whiteBelt from "../../assets/images/whiteBelt.png";
import greenBelt from "../../assets/images/greenBelt.png";
import blackBelt from "../../assets/images/blackBelt.png";
import { useTranslation } from "react-i18next";

import RoundProgress from "./RoundProgress";

const SupervisorDashboard = ({ user }) => {
	const { t } = useTranslation();

	return (
		<div id="supervisor-dashboard">
			<div id="detailed-info">
				<h4 className="fw-bold">{t("Detailed Infomation")}</h4>

				<div id="summary-list">
					<div className="summary-item">
						<div className="summary-header">
							<img src={whiteBelt} alt="belt" className="img-fluid" />
							<p>{t("White Belt Status")}</p>
						</div>
						<div className="summary-body">
							<RoundProgress val={user.sellerBeltStatus.white} />
						</div>
						<div className="summary-footer">
							<p>{t("of Sellers with White Belt")}</p>
						</div>
					</div>
					<div className="summary-item">
						<div className="summary-header">
							<img src={greenBelt} alt="belt" className="img-fluid" />
							<p>{t("Green Belt Status")}</p>
						</div>
						<div className="summary-body">
							<RoundProgress val={user.sellerBeltStatus.green} />
						</div>
						<div className="summary-footer">
							<p>{t("of Sellers with Green Belt")}</p>
						</div>
					</div>
					<div className="summary-item mb-0">
						<div className="summary-header">
							<img src={blackBelt} alt="belt" className="img-fluid" />
							<p>{t("Black Belt Status")}</p>
						</div>
						<div className="summary-body">
							<RoundProgress val={user.sellerBeltStatus.black} />
						</div>
						<div className="summary-footer">
							<p>{t("of Sellers with Black Belt")}</p>
						</div>
					</div>
				</div>
			</div>

			<div id="main-content">
				<div>
					<table className="table table-responsive table-borderless">
						<thead>
							<tr>
								<th scope="col">{t("Seller Name")}</th>
								<th scope="col">{t("Seller Status")}</th>
								<th scope="col">{t("Completed Topics")}</th>
								<th scope="col">{t("Next Topic / Topic to Restudy")}</th>
								<th scope="col">{t("Complete Percentage Within Belt (%)")}</th>
							</tr>
						</thead>

						<tbody>
							{user.allsellers.map(seller => (
								<tr key={seller._id}>
									<th scope="row">{seller.name}</th>
									<td>{seller.status} Belt</td>
									<td>{seller.completedTopic}</td>
									<td>{seller.nextTopic}</td>
									<td>{seller.beltProgress}%</td>
								</tr>
							))}
							{/* <tr>
								<th scope="row">Adam</th>
								<td>White Belt</td>
								<td>10 Theories</td>
								<td>3 Presuasive Selling Format</td>
								<td>28.75%</td>
							</tr>

							<tr>
								<th scope="row">Liana</th>
								<td>White Belt</td>
								<td>10 Theories</td>
								<td>3 Presuasive Selling Format</td>
								<td>40.00%</td>
							</tr>

							<tr>
								<th scope="row">Lien</th>
								<td>White Belt</td>
								<td>10 Theories</td>
								<td>3 Presuasive Selling Format</td>
								<td>50.75%</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(SupervisorDashboard);
