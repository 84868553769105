import { useState, useRef } from "react";
import { connect } from "react-redux";
import { editSchedule, evaluateSchedule, deleteSchedule } from "../../redux/actions/userActions";
import { years, months, days, dates, current } from "../../utilities/calendar";
import { Modal } from "react-bootstrap";
import moment from "moment";
import BigCalendarFooter from "./BigCalendarFooter";
import mySwal from "../../utilities/swal";
import ShowLoader from "../loader/ShowLoader";
import Loading from "../loader/Loading";
import ErrorAlert from "../error/Error";
import ScheduleContent from "./ScheduleContent";
import passedStamp from "../../assets/icons/passedStamp.png";
import failedStamp from "../../assets/icons/failedStamp.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";

const BigCalendar = ({ type, trainings, user, editSchedule, evalSchedule, deleteSchedule }) => {
	const { t } = useTranslation();
	// ----- States ----- //
	const downloadTarget = useRef();
	const downloadP2 = useRef();
	const downloadP3 = useRef();
	const [loading, setLoading] = useState(false);
	const [currentYear, setCurrentYear] = useState(current.year);
	const [currentMonth, setCurrentMonth] = useState(current.month);
	// modal state
	const [openModal, setModal] = useState(false);
	const [modalMode, setMode] = useState("view");
	const [isComplete, setIsComplete] = useState(false);
	// form
	const [scheduleId, setScheduleId] = useState("");
	const [date, setDate] = useState("");
	const [time, setTime] = useState("");
	const [seller, setSeller] = useState("");
	const [supervisor, setSupervisor] = useState("");
	const [belt, setBelt] = useState("");
	const [topic, setTopic] = useState("");
	const [trainingResult, setTrainingResult] = useState("");
	const [mytype, setMytype] = useState("");
	const [edacForm, setEdacForm] = useState({
		manager: "",
		bObjective: "",
		tObjective: "",
		strength: "",
		nStep: "",
		bResult: "",
		tResult: "",
		opportunity: "",
		knowledge: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		skill: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		attitude: {
			preparation: "",
			alignment: "",
			opportunity: "",
			cycle: ""
		},
		table: {
			a1: "",
			a2: "",
			a3: "",
			a4: "",
			a5: "",
			a6: "",
			b1: "",
			b2: "",
			b3: "",
			b4: "",
			b5: "",
			c1: "",
			c2: "",
			c3: "",
			c4: "",
			d1: "",
			d2: "",
			d3: "",
			d4: ""
		}
	});

	const getTasks = () => {
		if (type === "seller") {
			if (belt === "White") {
				return [...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo)];
			} else if (belt === "Green") {
				return [
					...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Black") {
				return [
					...user.alltasks.filter(task => task.beltType === "White").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Green").sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks.filter(task => task.beltType === "Black").sort((a, b) => a.topicNo - b.topicNo)
				];
			} else {
				return [];
			}
		} else if (type === "supervisor" && seller) {
			if (belt === "White") {
				return [
					...user.alltasks
						.filter(task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Green") {
				return [
					...user.alltasks
						.filter(task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks
						.filter(task => task.beltType === "Green" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo)
				];
			} else if (belt === "Black") {
				return [
					...user.alltasks
						.filter(task => task.beltType === "White" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks
						.filter(task => task.beltType === "Green" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo),
					...user.alltasks
						.filter(task => task.beltType === "Black" && task.mandatoryFor.includes(user.allsellers.filter(x => x.sellerId === seller)[0].distributorId))
						.sort((a, b) => a.topicNo - b.topicNo)
				];
			} else {
				return [];
			}
		} else {
			return [];
		}
	};

	const tasks = getTasks();
	const sellers = type === "supervisor" ? user.allsellers : [];
	const supervisors = type === "admin" ? user.supervisors : [];
	// eform
	// business summary
	const [bsObjective, setBsObjective] = useState("");
	const [bsSummary, setBsSummary] = useState("");
	// training summary
	const [tsObjective, setTsObjective] = useState("");
	const [tsSummary, setTsSummary] = useState("");
	const [highlight, setHighlight] = useState("");
	const [nextStep, setNextStep] = useState("");
	// assessment
	// business summary
	const [bsScore, setBsScore] = useState(0);
	const [bsRemark, setBsRemark] = useState("");
	// training summary
	const [tsScore, setTsScore] = useState(0);
	const [tsRemark, setTsRemark] = useState("");

	// ----- Variables ------ //
	let thisMonthDates = dates.filter(x => x.month === currentMonth && x.year === currentYear);
	const thisMonthFirst = thisMonthDates[0];
	const thisMonthLast = thisMonthDates[thisMonthDates.length - 1];
	const firstIndex = dates.findIndex(x => x.year === thisMonthFirst.year && x.month === thisMonthFirst.month && x.date === thisMonthFirst.date && x.day === thisMonthFirst.day);
	const lastIndex = dates.findIndex(x => x.year === thisMonthLast.year && x.month === thisMonthLast.month && x.date === thisMonthLast.date && x.day === thisMonthLast.day);
	const currentMonthIndex = months.findIndex(x => x === currentMonth);
	let lastMonth = months[currentMonthIndex - 1];
	let nextMonth = months[currentMonthIndex + 1];

	// ----- Functions ----- //
	// fill in front empty dates
	let unshiftCount = 1;
	while (thisMonthDates[0].day !== days[0]) {
		let unshiftDate = dates[firstIndex - unshiftCount];
		thisMonthDates.unshift(unshiftDate);
		unshiftCount++;
	}
	// fill in back empty dates
	let pushCount = 1;
	while (thisMonthDates[thisMonthDates.length - 1].day !== days[days.length - 1]) {
		let pushDate = dates[lastIndex + pushCount];
		thisMonthDates.push(pushDate);
		pushCount++;
	}

	if (!lastMonth) lastMonth = months[months.length - 1];
	if (!nextMonth) nextMonth = months[0];

	// jump to today
	const jumpToday = () => {
		setCurrentMonth(current.month);
		setCurrentYear(current.year);
	};
	// month navigation < >
	const handleMonth = val => {
		const setTo = months[currentMonthIndex + val];
		if (!setTo) {
			if (val > 0) {
				// increment
				if (currentYear !== years[years.length - 2]) {
					setCurrentYear(currentYear + val);
					setCurrentMonth(nextMonth);
				}
			} else {
				// decrement
				if (currentYear !== years[1]) {
					setCurrentYear(currentYear + val);
					setCurrentMonth(lastMonth);
				}
			}
		} else {
			setCurrentMonth(months[currentMonthIndex + val]);
		}
	};
	// classes for training schedules
	const handleClassName = training => {
		if (
			new Date(training.trainingDate).getDate() <= current.date ||
			new Date(training.trainingDate).getFullYear() < current.year ||
			new Date(training.trainingDate).getMonth() + 1 < months.findIndex(x => x === current.month) + 1
		) {
			if (!training.evaluation) {
				return "pending";
			} else if (training.evaluation && training.evaluation === "Passed") {
				return "passed";
			} else if (training.evaluation && training.evaluation === "Failed") {
				return "failed";
			}
		} else {
			if (!training.evaluation) {
				return "upcoming";
			} else if (training.evaluation && training.evaluation === "Passed") {
				return "passed";
			} else if (training.evaluation && training.evaluation === "Failed") {
				return "failed";
			}
		}
	};
	// close modal
	const closeModal = () => {
		setModal(false);
		setMode("view");
		setIsComplete(false);
		setScheduleId("");
		setDate("");
		setTime("");
		setSeller("");
		setSupervisor("");
		setBelt("");
		setTopic("");
		setBsObjective("");
		setBsSummary("");
		setTsObjective("");
		setTsSummary("");
		setHighlight("");
		setNextStep("");
		setBsScore(0);
		setBsRemark("");
		setTsScore(0);
		setTsRemark("");
		setTrainingResult("");
		setMytype("");
		setEdacForm({
			manager: "",
			bObjective: "",
			tObjective: "",
			strength: "",
			nStep: "",
			bResult: "",
			tResult: "",
			opportunity: "",
			knowledge: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			skill: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			attitude: {
				preparation: "",
				alignment: "",
				opportunity: "",
				cycle: ""
			},
			table: {
				a1: "",
				a2: "",
				a3: "",
				a4: "",
				a5: "",
				a6: "",
				b1: "",
				b2: "",
				b3: "",
				b4: "",
				b5: "",
				c1: "",
				c2: "",
				c3: "",
				c4: "",
				d1: "",
				d2: "",
				d3: "",
				d4: ""
			}
		});
	};
	// open modal
	const handleModal = training => {
		console.log(training);
		if (!training.evaluation) {
			// not evaluate yet
			if (training.mytype === "seller") {
				setScheduleId(training._id);
				setDate(moment(new Date(training.trainingDate)).format("YYYY-MM-DD"));
				setTime(training.trainingTime);
				setSeller(training.sellerId);
				setSupervisor(training.supervisorId);
				setBelt(training.belt);
				setTopic(training.topicId);
				setBsObjective(training.eform.bsObjective);
				setBsSummary(training.eform.bsSummary);
				setTsObjective(training.eform.tsObjective);
				setTsSummary(training.eform.tsSummary);
				setHighlight(training.eform.highlight);
				setNextStep(training.eform.nextStep);
				setBsScore(training.eform.bsScore);
				setBsRemark(training.eform.bsRemark);
				setTsScore(training.eform.tsScore);
				setTsRemark(training.eform.tsRemark);
				setMytype(training.mytype);
			} else {
				setScheduleId(training._id);
				setDate(moment(new Date(training.trainingDate)).format("YYYY-MM-DD"));
				setTime(training.trainingTime);
				setSupervisor(training.supervisorId);
				setMytype(training.mytype);
				setEdacForm({
					...training.edacForm,
					manager: training.manager
				});
			}
		} else {
			// already evaluate
			if (training.mytype === "seller") {
				setIsComplete(true);
				setScheduleId(training._id);
				setDate(moment(new Date(training.trainingDate)).format("YYYY-MM-DD"));
				setTime(training.trainingTime);
				setSeller(training.sellerId);
				setSupervisor(training.supervisorId);
				setBelt(training.belt);
				setTopic(training.topicId);
				setBsObjective(training.eform.bsObjective);
				setBsSummary(training.eform.bsSummary);
				setTsObjective(training.eform.tsObjective);
				setTsSummary(training.eform.tsSummary);
				setHighlight(training.eform.highlight);
				setNextStep(training.eform.nextStep);
				setBsScore(training.eform.bsScore);
				setBsRemark(training.eform.bsRemark);
				setTsScore(training.eform.tsScore);
				setTsRemark(training.eform.tsRemark);
				setTrainingResult(training.evaluation);
				setMytype(training.mytype);
			} else {
				setIsComplete(true);
				setScheduleId(training._id);
				setDate(moment(new Date(training.trainingDate)).format("YYYY-MM-DD"));
				setTime(training.trainingTime);
				setSeller(training.sellerId);
				setSupervisor(training.supervisorId);
				setBelt(training.belt);
				setTopic(training.topicId);
				setEdacForm({ ...training.edacForm, manager: training.manager });
				setTrainingResult(training.evaluation);
				setMytype(training.mytype);
			}
		}
		setModal(true);
	};

	// edit training
	const handleEdit = e => {
		e.preventDefault();
		mySwal
			.fire({
				title: t("Confirmation"),
				text: t("Are you sure to edit this scheduled training?"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: t("OK"),
				cancelButtonText: t("Cancel")
			})
			.then(result => {
				if (result.isConfirmed && result.value) {
					if (mytype === "seller") {
						if (!scheduleId || !date || !time || !topic.length) {
							mySwal.fire({
								title: t("Required Input Error"),
								text: t("Please key in all the required input field (date, time, topic, business objective, training objective)"),
								icon: "info",
								confirmButtonText: t("Understand")
							});
						} else {
							const sendThis = {
								trainingId: scheduleId,
								trainingDate: date,
								trainingTime: time,
								belt,
								topicId: topic,
								trainingTopic: user.alltasks.filter(task => task._id === topic[0])[0].title,
								sellerId: seller,
								supervisorId: supervisor,
								bsObjective,
								bsSummary,
								tsObjective,
								tsSummary,
								highlight,
								nextStep,
								bsScore,
								bsRemark,
								tsScore,
								tsRemark,
								mytype
							};
							// console.log(sendThis);
							editSchedule(sendThis, () => closeModal());
						}
					} else {
						let sendThis = {
							trainingId: scheduleId,
							trainingDate: date,
							trainingTime: time,
							edacForm,
							mytype,
							supervisorId: "",
							distributorId: ""
						};

						if (type === "supervisor") {
							sendThis.supervisorId = user.supervisorId;
							sendThis.distributorId = user.distributorId;
						} else if (type === "admin") {
							sendThis.supervisorId = supervisor;
							sendThis.distributorId = supervisors.filter(x => x.supervisorId === supervisor)[0].distributorId;
						}
						// console.log(sendThis);
						editSchedule(sendThis, () => closeModal());
					}
				}
			});
	};
	// delete training
	const handleDelete = e => {
		mySwal
			.fire({
				title: t("Confirmation"),
				text: t("Are you sure you want to delete this training schedule?"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: t("OK"),
				cancelButtonText: t("Cancel")
			})
			.then(result => {
				if (result.isConfirmed && result.value) {
					// console.log({ id: scheduleId });
					deleteSchedule({ id: scheduleId }, () => closeModal());
				}
			});
	};
	// evaluate seller training
	const handleEvaluate = evaluation => {
		mySwal
			.fire({
				title: t("Confirmation"),
				text: t(`Evaluate this training as ${evaluation}?`),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: t("Confirm"),
				cancelButtonText: t("Cancel")
			})
			.then(click => {
				if (click.isConfirmed && click.value) {
					if (mytype === "seller") {
						const sendThis = {
							trainingId: scheduleId,
							bsObjective,
							bsSummary,
							tsObjective,
							tsSummary,
							highlight,
							nextStep,
							bsScore,
							bsRemark,
							tsScore,
							tsRemark,
							evaluation,
							mytype
						};
						// console.log(sendThis);
						evalSchedule(sendThis, () => closeModal());
					} else {
						const sendThis = {
							trainingId: scheduleId,
							edacForm,
							mytype,
							evaluation
						};
						// console.log(sendThis);
						evalSchedule(sendThis, () => closeModal());
					}
				}
			});
	};

	const downloadPdf = () => {
		setLoading(true);
		const margin = 10;

		html2canvas(downloadTarget.current).then(canvas1 => {
			let doc = new jsPDF();
			const image1 = canvas1.toDataURL("image/png");
			const image1Props = doc.getImageProperties(image1);
			const pageWidth = doc.internal.pageSize.getWidth();
			// const pageHeight = doc.internal.pageSize.getHeight();
			const page1Height = (image1Props.height * pageWidth) / image1Props.width;
			doc.addImage(image1, "PNG", margin, margin, pageWidth - margin * 2, page1Height - margin * 2);
			html2canvas(downloadP2.current).then(canvas2 => {
				const image2 = canvas2.toDataURL("image/png");
				const image2Props = doc.getImageProperties(image2);
				const page2Height = (image2Props.height * pageWidth) / image2Props.width;
				doc.addPage();
				doc.addImage(image2, "PNG", margin, margin, pageWidth - margin * 2, page2Height - margin * 2);

				if (downloadP3.current) {
					html2canvas(downloadP3.current).then(canvas3 => {
						const image3 = canvas3.toDataURL("image/png");
						const image3Props = doc.getImageProperties(image3);
						const page3Height = (image3Props.height * pageWidth) / image3Props.width;
						doc.addPage();
						doc.addImage(image3, "PNG", margin, margin, pageWidth - margin * 2, page3Height - margin * 2);

						doc.save("evaluationForm.pdf");
						setLoading(false);
					});
				} else {
					doc.save("evaluationForm.pdf");
					setLoading(false);
				}
			});
		});
	};

	return (
		<div id="big-calendar">
			<div id="calendar-options">
				<div id="btn-container">
					<button onClick={() => handleMonth(-1)} className="navigate-btn">
						&lt;
					</button>
					<button onClick={() => handleMonth(1)} className="navigate-btn">
						&gt;
					</button>
					<button id="today-btn" className={`${currentMonth !== current.month || currentYear !== current.year ? "highlight" : ""}`} onClick={jumpToday}>
						{t("Today")}
					</button>
				</div>

				<div id="display">
					<p>{t(currentMonth.toUpperCase()) + " " + currentYear}</p>
				</div>
			</div>

			<div id="days-container">
				{days.map(x => (
					<div className={`day ${x === "Sunday" || x === "Saturday" ? "weekend" : ""}`} key={x}>
						<p>{t(x)}</p>
					</div>
				))}
			</div>

			<div id="dates-container">
				{thisMonthDates.map((x, ind) => (
					<div className={`date ${x.day === "Sunday" || x.day === "Saturday" ? "weekend" : ""} ${x.month !== currentMonth ? "other-month" : ""} ${x.today ? "today" : ""}`} key={ind}>
						{type === "seller" ? (
							<div className="events-container">
								{trainings
									.filter(
										training =>
											new Date(training.trainingDate).toLocaleDateString() ===
											new Date(
												x.year,
												months.findIndex(m => m === x.month),
												x.date
											).toLocaleDateString()
									)
									.map(training => (
										<div onClick={() => handleModal(training)} key={training._id} className={`event-schedule ${handleClassName(training)}`}>
											<p>
												{training.trainingTopic.length > 10 ? training.trainingTopic.slice(0, 10) + "..." : training.trainingTopic}, {training.trainingTime}
											</p>
										</div>
									))}
							</div>
						) : type === "supervisor" ? (
							<div className="events-container">
								{trainings
									.filter(
										training =>
											new Date(training.trainingDate).toLocaleDateString() ===
											new Date(
												x.year,
												months.findIndex(m => m === x.month),
												x.date
											).toLocaleDateString()
									)
									.map(training => (
										<div onClick={() => handleModal(training)} key={training._id} className={`event-schedule ${handleClassName(training)}`}>
											<p>
												{training.trainingTopic.length > 10 ? training.trainingTopic.slice(0, 10) + "..." : training.trainingTopic}, {training.trainingTime}
											</p>
										</div>
									))}
							</div>
						) : type === "admin" ? (
							<div className="events-container">
								{trainings
									.filter(
										training =>
											new Date(training.trainingDate).toLocaleDateString() ===
											new Date(
												x.year,
												months.findIndex(m => m === x.month),
												x.date
											).toLocaleDateString()
									)
									.map(training => (
										<div onClick={() => handleModal(training)} key={training._id} className={`event-schedule ${handleClassName(training)}`}>
											<p>
												{training.trainingTopic.length > 10 ? training.trainingTopic.slice(0, 10) + "..." : training.trainingTopic}, {training.trainingTime}
											</p>
										</div>
									))}
							</div>
						) : null}
						<p className="date-number">{x.date}</p>
					</div>
				))}
			</div>

			<BigCalendarFooter />

			<Modal show={openModal} onHide={() => closeModal()} centered size="xl" scrollable={true}>
				<Modal.Header closeButton closeLabel={t("Close")}>
					<Modal.Title>{modalMode === "view" ? t("View Schedule") : modalMode === "edit" ? t("Edit Schedule") : t("Evaluate")}</Modal.Title>
					{isComplete ? <img src={trainingResult === "Passed" ? passedStamp : failedStamp} alt="stamp" className="img-fluid m-0 p-0" style={{ height: "40px" }} /> : null}
				</Modal.Header>

				<Modal.Body>
					<ErrorAlert />

					<div>
						<div className="row">
							<div className="col form-group">
								<label htmlFor="date">{t("Date")}</label>
								<input
									type="date"
									id="date"
									name="date"
									className="form-control"
									value={date}
									onChange={e => (modalMode === "edit" ? setDate(e.target.value) : () => console.log(e.target.value))}
									required
									disabled={modalMode !== "edit"}
								/>
							</div>
							<div className="col form-group">
								<label htmlFor="time">{t("Time")}</label>
								<input
									type="time"
									id="time"
									name="time"
									className="form-control"
									value={time}
									onChange={e => (modalMode === "edit" ? setTime(e.target.value) : () => console.log(e.target.value))}
									required
									disabled={modalMode !== "edit"}
								/>
							</div>
						</div>

						<ScheduleContent
							type={type}
							topic={topic}
							setTopic={setTopic}
							bsObjective={bsObjective}
							setBsObjective={setBsObjective}
							bsSummary={bsSummary}
							setBsSummary={setBsSummary}
							tsObjective={tsObjective}
							setTsObjective={setTsObjective}
							tsSummary={tsSummary}
							setTsSummary={setTsSummary}
							highlight={highlight}
							setHighlight={setHighlight}
							nextStep={nextStep}
							setNextStep={setNextStep}
							bsScore={bsScore}
							setBsScore={setBsScore}
							bsRemark={bsRemark}
							setBsRemark={setBsRemark}
							tsScore={tsScore}
							setTsScore={setTsScore}
							tsRemark={tsRemark}
							setTsRemark={setTsRemark}
							supervisorName={user.supervisorName || ""}
							belt={belt}
							tasks={tasks}
							modalMode={modalMode}
							isComplete={isComplete}
							sellers={sellers}
							setSeller={setSeller}
							mytype={mytype}
							edacForm={edacForm}
							setEdacForm={setEdacForm}
							downloadTarget={downloadTarget}
							downloadP2={downloadP2}
							downloadP3={downloadP3}
							supervisors={supervisors}
							supervisor={supervisor}
							setSupervisor={setSupervisor}
							seller={seller}
						/>
					</div>
				</Modal.Body>

				{!isComplete ? (
					<ShowLoader
						component={
							<Modal.Footer>
								{modalMode === "view" ? (
									<>
										{type === "supervisor" && mytype === "seller" ? (
											<button onClick={() => setMode("evaluate")} className="btn btn-primary" style={{ width: "120px" }}>
												{t("Evaluate")}
											</button>
										) : type === "admin" && mytype === "edac" ? (
											<button onClick={() => setMode("evaluate")} className="btn btn-primary" style={{ width: "120px" }}>
												{t("Evaluate")}
											</button>
										) : null}

										<button onClick={() => setMode("edit")} className="btn btn-warning" style={{ width: "120px" }}>
											{t("Edit")}
										</button>
									</>
								) : modalMode === "edit" ? (
									<>
										{type !== "seller" ? (
											<button onClick={handleDelete} className="btn btn-danger" style={{ width: "120px" }}>
												{t("Delete")}
											</button>
										) : null}

										<button onClick={handleEdit} className="btn btn-primary" style={{ width: "120px" }}>
											{t("Edit")}
										</button>
										<button onClick={() => setMode("view")} className="btn btn-info" style={{ width: "120px" }}>
											{t("Back")}
										</button>
									</>
								) : (
									<>
										<button className="btn btn-success" style={{ width: "120px" }} onClick={() => handleEvaluate("Passed")}>
											{t("Pass")}
										</button>

										<button className="btn btn-danger" style={{ width: "120px" }} onClick={() => handleEvaluate("Failed")}>
											{t("Fail")}
										</button>

										<button onClick={() => setMode("view")} className="btn btn-info" style={{ width: "120px" }}>
											{t("Back")}
										</button>
									</>
								)}
							</Modal.Footer>
						}
						loadingColor="primary"
					/>
				) : (
					<Modal.Footer>
						{loading ? (
							<Loading color="primary" />
						) : (
							<button onClick={downloadPdf} className="btn btn-primary fw-bold" style={{ width: "120px" }}>
								{t("Download")}
							</button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		type: state.user.type,
		trainings: state.user.user.trainings,
		user: state.user.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		editSchedule: (data, callback) => dispatch(editSchedule(data, callback)),
		evalSchedule: (data, callback) => dispatch(evaluateSchedule(data, callback)),
		deleteSchedule: (data, callback) => dispatch(deleteSchedule(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BigCalendar);
