const processTopic = (sendObject, edit, callback) => {
	const { title, belt, mandatory, engPdf, mlyPdf, chiPdf, quiz, engQuiz, mlyQuiz, chiQuiz, topicNo, topicId } = sendObject;

	if (!edit) {
		if (!title || !belt || !mandatory.length) {
			console.log("No title, belt or mandatory")
			callback("Please enter the required field, (title, belt type, mandatory checkboxes)", null);
		} else if (!engPdf && !mlyPdf && !chiPdf) {
			console.log("No PDF");
			callback("Please upload atleast one PDF file for user to study", null);
		} else {
			if (!quiz) {
				const formData = new FormData();
				formData.append("title", title);
				formData.append("belt", belt);
				formData.append("mandatory", mandatory);
				formData.append("engPdf", engPdf);
				formData.append("mlyPdf", mlyPdf);
				formData.append("chiPdf", chiPdf);
				formData.append("quiz", quiz);
				callback(null, formData);
			} else {
				const englishQuiz = engQuiz.map(x => x);
				const malayQuiz = mlyQuiz.map(x => x);
				const chineseQuiz = chiQuiz.map(x => x);
				const quizzes = [...englishQuiz, ...malayQuiz, ...chineseQuiz];

				if (!quizzes.length) {
					console.log("No quiz added");
					callback("Please add quizzes before proceed", null);
				} else {
					let pass = true;
					for (let i = 0; i < quizzes.length; i++) {
						if (quizzes[i].questionType === "sequence") {
							console.log("Checking sequence question");
							if (!quizzes[i].sequence.length || !quizzes[i].options.length) {
								console.log(quizzes[i]);
								console.log("No answer");
								pass = false;
								break;
							}
							console.log("Pass")
						} else if (quizzes[i].questionType === "multipleChoice") {
							console.log("Checking mcq");
							if (!quizzes[i].correctAnswer.length || !quizzes[i].answerChoices.length) {
								console.log(quizzes[i]);
								console.log("No answer");
								pass = false;
								break;
							}
							console.log("Pass");
						}
					}

					if (!pass) {
						callback("Some questions have missing answer or correct answer, please make sure every question have answer and correct answer", null);
					} else {
						const formData = new FormData();
						formData.append("title", title);
						formData.append("belt", belt);
						formData.append("mandatory", mandatory);
						formData.append("engPdf", engPdf);
						formData.append("mlyPdf", mlyPdf);
						formData.append("chiPdf", chiPdf);
						formData.append("quiz", quiz);
						formData.append("quizzes", JSON.stringify(quizzes));
						callback(null, formData);
					}
				}
			}
		}
	} else {
		// edit
		if (!title || !belt || !mandatory.length || topicNo === "" || topicNo === null || topicNo === undefined) {
			console.log("Credential not complete");
			callback("Please enter the required field, (title, belt type, mandatory checkboxes, topic number)", null);
		} else {
			console.log({ title, belt, mandatory, quiz, topicNo });
			if (quiz) {
				const englishQuiz = engQuiz.map(x => x);
				const malayQuiz = mlyQuiz.map(x => x);
				const chineseQuiz = chiQuiz.map(x => x);
				const quizzes = [...englishQuiz, ...malayQuiz, ...chineseQuiz];
				console.log(quizzes);

				if (!quizzes.length) {
					console.log("no quiz added");
					callback("Please add quizzes before proceed", null);
				} else {
					let pass = true;
					for (let i = 0; i < quizzes.length; i++) {
						if (quizzes[i].questionType === "sequence") {
							console.log("Checking sequence question");
							if (!quizzes[i].sequence.length || !quizzes[i].options.length) {
								console.log(quizzes[i]);
								console.log("No answer");
								pass = false;
								break;
							}
							console.log("pass");
						} else if (quizzes[i].questionType === "multipleChoice") {
							console.log("checking mcq");
							if (!quizzes[i].correctAnswer.length || !quizzes[i].answerChoices.length) {
								console.log(quizzes[i]);
								console.log("No answer");
								pass = false;
								break;
							}
							console.log("pass");
						}
					}

					if (!pass) {
						callback("Some questions have missing answer or correct answer, please make sure every question have answer and correct answer", null);
					} else {
						if (engPdf || mlyPdf || chiPdf) {
							// got new pdf
							console.log("PDF");
							const formData = new FormData();
							formData.append("engPdf", engPdf);
							formData.append("mlyPdf", mlyPdf);
							formData.append("chiPdf", chiPdf);
							formData.append("title", title);
							formData.append("belt", belt);
							formData.append("mandatory", mandatory);
							formData.append("quiz", quiz);
							formData.append("quizzes", JSON.stringify(quizzes));
							formData.append("topicNo", topicNo);
							formData.append("topicId", topicId);
							callback(null, formData);
						} else {
							// no new pdf
							console.log("No new pdf");
							const sendThis = {
								title,
								belt,
								mandatory,
								quiz,
								quizzes: JSON.stringify(quizzes),
								topicNo,
								topicId
							};

							callback(null, sendThis);
						}
					}
				}
			} else {
				// no quiz
				console.log("No Quiz");
				if (engPdf || mlyPdf || chiPdf) {
					// got new pdf
					console.log("PDF");
					const formData = new FormData();
					formData.append("engPdf", engPdf);
					formData.append("mlyPdf", mlyPdf);
					formData.append("chiPdf", chiPdf);
					formData.append("title", title);
					formData.append("belt", belt);
					formData.append("mandatory", mandatory);
					formData.append("quiz", quiz);
					formData.append("topicNo", topicNo);
					formData.append("topicId", topicId);
					callback(null, formData);
				} else {
					// no new pdf
					console.log("No new pdf");
					const sendThis = {
						title,
						belt,
						mandatory,
						quiz,
						topicNo,
						topicId
					};
					callback(null, sendThis);
				}
			}
		}
	}
};

export default processTopic;
